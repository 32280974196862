import { combineReducers } from 'redux';
import authReducer from './authReducer';
import formReducer from './formReducer';
import appReducer from './appReducer';

export default combineReducers({
  auth: authReducer,
  form: formReducer,
  app: appReducer,
})
