import {
  STORE_FORM,
  // STORE_FORM_LATER,
} from '../actions/types'

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case STORE_FORM:
      // console.log("payload", action.payload)
      return {
        ...state,
        corp: {
          ...state.corp,
          ...action.payload.corp
        },
        dba: {
          ...state.dba,
          ...action.payload.dba
        }
      }
    default:
      return state
  }
}