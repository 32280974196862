// this is completely relying on session storage that is saved when user hits the first page or when user have successfully login
export const displayPriceAmount = (pid) => {
  // grab price form local storage or call api
  let displayPrice = "";
  if (sessionStorage.getItem("productsData")) {
    const productsData = JSON.parse(sessionStorage.getItem("productsData"));
    const product = productsData.find(item => item.id === pid);
    displayPrice = product.price_per_unit.amount; // display full price as number
  }
  else {
    console.error("Error, product data is not stored in sessions storage.");
  }
  return displayPrice;
}

export const displayPrice = (pid) => {
  // grab price form local storage or call api
  let displayPrice = "";
  if (sessionStorage.getItem("productsData")) {
    const productsData = JSON.parse(sessionStorage.getItem("productsData"));
    const product = productsData.find(item => item.id === pid);
    displayPrice = product.price_per_unit.display_amount; // display full price with dollar sign
  }
  else {
    console.error("Error, product data is not stored in sessions storage.");
  }
  return displayPrice;
}

export const displayPriceDollar = (pid) => {
  // grab price form local storage or call api
  let displayPrice = "";
  if (sessionStorage.getItem("productsData")) {
    const productsData = JSON.parse(sessionStorage.getItem("productsData"));
    const product = productsData.find(item => item.id === pid);
    displayPrice = product.price_per_unit.display_dollars; // display dollars price without cents
  }
  else {
    console.error("Error, product data is not stored in sessions storage.");
  }
  return displayPrice;
}
