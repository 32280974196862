import React, { useEffect, Fragment } from 'react'
import { Container, Row, Col } from 'reactstrap';
import { Helmet } from "react-helmet";
import { signoutRedirect } from '../services/authService'
import { useHistory } from 'react-router-dom'

function Logout() {
  const history = useHistory()
  useEffect(() => {
    sessionStorage.removeItem('fisFormState');
    sessionStorage.removeItem('orderDetails');
    sessionStorage.removeItem('productsData');
    async function signoutAsync() {
      await signoutRedirect()
      // history.push('/')
    }
    signoutAsync()
  }, [history])

  return (
    <Fragment>
      <Helmet>
        <title>Saving your application...</title>
        <link rel="canonical" href="https://www.floridaincorporationservice.com" />
      </Helmet>
      <Container>
        <Row className="justify-content-center text-center">
          <Col lg="8" md="10" sm="12">
            <h1 className="mt-4">Saving your application...</h1>
            <p>If you don't get redirected automatically, please refresh the page. If you with to continue your application please login again.</p>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default Logout;