import store from '../store';

import initialCorpState from '../store/reducers/initialCorpState'
import initialDbaState from '../store/reducers/initialDbaState'

// creating object that will match the redux state to prevent removing any key/values that's not updated
export const accountInfoState = (accountType, currentStoreState, formState) => {
  const formType = (accountType === "corp" || accountType === "llc") ? "corp" : "dba";
  const storeFormState = currentStoreState || store.getState().form; // grab store state if the component using this can't provide the current store

  const otherFormType = formType === "corp" ? "dba" : "corp";
  const intialFormTypeState = otherFormType === "corp" ? initialCorpState : initialDbaState;
  let newStore = {};
  
  if (accountType) {
    newStore = {
      [formType]: {
        ...storeFormState[formType],
        ...formState,
        account: {
          ...storeFormState[formType].account,
          ...formState.account,
          person: {
            ...storeFormState[formType].account.person,
            ...formState.account.person,
          }
        },
        pointOfContact: {
          ...storeFormState[formType].pointOfContact,
          ...formState.pointOfContact,
        },
        registrationVars: {
          ...storeFormState[formType].registrationVars,
          ...formState.registrationVars,
        },
      },
      [otherFormType]: {
        ...intialFormTypeState
      }
    }
  }
  else {
    newStore = {
      corp: {
        ...initialCorpState
      },
      dba: {
        ...initialDbaState
      }
    }
  }
  return newStore;
}
