import React, { Fragment } from "react";
import { Container, Row, Col } from 'reactstrap';
import {Helmet} from "react-helmet";
// import { signinRedirect } from '../services/authService';


const ErrorState = (props) => {
  const { error: { details } } = props;

  const startChat = (e) => {
    e.preventDefault();
    // console.log("chat clicked");
    const olark = window.olark;
    olark('api.box.expand');
  }

  const login = (e) => {
    e.preventDefault();
    window.location.href = "/login"
    // signinRedirect();
  }

  return (
    <Fragment>
      <Helmet>
        <title>Page Not Found...</title>
        <link rel="canonical" href="https://www.floridaincorporationservice.com" />
      </Helmet>
      <Container>
        <Row className="justify-content-center">
          <Col lg="8" md="10" sm="12">
            <h1 className="mt-4">Oopsies, something went wrong...</h1>
            <p>We're sorry, looks like we're experiencing some technical difficulties. Here are some tips to help you get back to your application.</p>
            <h3 className="h4">Try doing the following:</h3>
            <ul className="star">
              <li>Please try to <a href="//:0" onClick={() => window.location.reload()}>refresh the page</a>.</li>
              <li>If you already have an account, try to <a href="//:0" onClick={(e) => login(e)}>login</a> again and continue your application</li>
              <li>Check if you have typed the wrong URL address</li>
              <li>If you continue to experience the same issue, please contact customer support via <a className="livechat" href="//:0" onClick={startChat}>Live Chat</a>.</li>
            </ul>
            <h3 className="h5">Error Log</h3>
            <p className="text-danger">{details}</p>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default ErrorState;