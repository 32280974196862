import React, { useState, useEffect } from "react";

import "./style.scss";

const ProcessingStatus = (props) => {
  const [state, setState] = useState({
    showProcessing: false,
  });

  useEffect(() => {
    let showProcessingTimeout = setTimeout(() => {
      setState(state => ({
        ...state,
        showProcessing: props.showProcessing,
      }));
    }, 300);
    return () => clearTimeout(showProcessingTimeout); // needed to clear timeout if the component unmounts before 300ms
  }, [props.showProcessing]);

  return ( 
    <>
      {state.showProcessing === true ? 
        <div className="d-flex justify-content-center align-items-center processing-loader">
          <div className="processing-info-container">
            <div className="center-animated">
              <div className="cell">
                <div className="loader">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
            </div>
            <h3 className="h4 mt-0 processing-info">Securely Processing Your Application</h3>
            <p className="mt-3 processing-info">If you continue to see this for more than 2 minutes, please refresh the page.</p>
          </div>
        </div>
      : null}
    </>
  )
}

export default ProcessingStatus;