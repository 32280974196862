import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  // CustomInput
} from 'reactstrap';
import queryString from "query-string";
import {Helmet} from "react-helmet";
import { Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import API from "../services/apiService";
import { generatePids, generateKeys } from "../utils/productMapping";
import Sidebar from "../components/Sidebar";
import ProgressStatus from "../components/ProgressStatus";
import StateDropdown from "../components/StateDropdown";
import UserAgreementCheckbox from "../components/UserAgreementCheckbox";
import FormNav from "../components/FormNav";
import FormValidationAlert from "../components/FormValidationAlert";
import ProcessingStatus from "../components/ProcessingStatus";
import FormErrorModal from "../components/FormErrorModal";
import ApplicationCompleteAlert from "../components/ApplicationCompleteAlert";
import { formErrorHandler, appErrorHandler } from "../utils/errorHandlerUtil";
import InputMask from 'react-input-mask';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { storeApplication } from '../store/actions/formActions'	
import { accountInfoState } from "../utils/storeState";

function LastSteps(props) {
  const form = useSelector(state => state.form);
  const dispatch = useDispatch();  

  const pageTitle = "Florida Incorporation Service Registration | Last Steps",
        registrationStep = 2,
        accountType = props.match.params.accountType?.toLowerCase(),
        orderDetails = JSON.parse(sessionStorage.getItem("orderDetails")) || [],
        host = queryString.parse(props.location.search)?.host || "",
        formType = accountType === "dba" ? "dba" : "corp";

  // checks if current url is valid for the stored order data
  const { pid, lgr, pkg } = queryString.parse(props.location.search);
  if (!pid || !lgr || !host || (accountType !== "dba" ? !pkg : false)) {
    appErrorHandler("Custom Error", `Missing query string ${props.history.location.pathname}${props.history.location.search}`, dispatch)
    // props.history.replace(`/error/${accountType}/${props.location.search}`);
  }
  if (orderDetails.length && (Number(orderDetails[0].id) !== Number(pid) || orderDetails[0].lgr !== lgr?.toLowerCase())) {
    sessionStorage.removeItem('orderDetails');
    sessionStorage.removeItem('productsData');
    sessionStorage.removeItem('fisFormState');
    props.history.replace(`/account-info/${accountType}/${props.location.search}`);
  }

  const initState = {
    formState: {
      street1: form[formType].incorporatorAddress?.street1 ? form[formType].incorporatorAddress?.street1 : "",
      street2: form[formType].incorporatorAddress?.street2 ? form[formType].incorporatorAddress?.street2 : "",
      city: form[formType].incorporatorAddress?.city ? form[formType].incorporatorAddress?.city : "",
      postalCode: form[formType].incorporatorAddress?.postalCode ? form[formType].incorporatorAddress?.postalCode : "",
      state: form[formType].incorporatorAddress?.stateProvince ? form[formType].incorporatorAddress?.stateProvince : "FL",
      country: form[formType].incorporatorAddress?.country ? form[formType].incorporatorAddress?.country : "US",
      phone: form[formType].incorporatorAddress?.phone ? form[formType].incorporatorAddress?.phone : "",
      effectiveDateToggle: form[formType].effectiveDate ? true : false,
      effectiveDate: form[formType].effectiveDate ? formatDate(form[formType].effectiveDate) : "",
      userAgreement: form[formType].electronicSig ? true : false,
      electronicSignature: form[formType].incorporatorSig ? form[formType].incorporatorSig : "",
      fisPackage: form[formType].registrationVars?.fisPackage || "",
      lgr: form[formType].registrationVars?.lgr || "",
      optionalProducts: form[formType].optionalProducts || [], // array can contain one or more of the following: SubSElection, BylawsAndMinutes, CertificateOfStatus, CorporateEmbosser, CorpSealsAndCerts, Form8832Filing, Form8832Filing, LLCSealsAndCerts, OperationalAgreement 
      optionalServices: form[formType].optionalServices || [],
    },
  }
  
  const checkedProducts = Object.keys(initState.formState).filter(key => initState.formState[key]); // generate product keys for generatePids()
  const generatedPids = generatePids([...checkedProducts, ...initState.formState.optionalProducts, ...initState.formState.optionalServices])
  const uniquePids = new Set([Number(pid), ...generatedPids]); // removing duplicate PIDs
  const productKeys = generateKeys([...uniquePids]); // object of product keys with true value

  const [state, setState] = useState({
    ...initState,
    formState: {
      ...initState.formState,
      ...productKeys,
    },
    orderDetailPids: [...uniquePids],
  });

  const handleDayChange = (selectedDay, modifiers, dayPickerInput, setFieldValue) => {
    const { name, value } = dayPickerInput.getInput();
    if (selectedDay !== undefined) {
      setFieldValue(name, formatDate(selectedDay))
    }
    else {
      setFieldValue(name, value)
    }
  }

  const handleFormSubmit = (values, actions, nextUrl) => {
    // console.log(values);
    setState(state => ({
      ...state,
      formState: {
        ...state.formState,
        ...values,
      },
      isProcessing: true,
    }));

    const goToNextPage = (apiData) => {
      sessionStorage.removeItem('fisFormState'); // deleting current formState from sessionStorage
      setState(state => ({
        ...state,
        isProcessing: false,
      }));
      // creating object that matched the redux state object to prevent removing any key/values that's not updated
      const applicationState = accountInfoState(accountType, form, apiData);
      dispatch(storeApplication(applicationState)) // sets redux store with form info
      actions.resetForm(true);
      const nextUrl = `/company-summary/${accountType}/${props.location.search}`;
      props.history.push(nextUrl); // push user to next page after form submission or api call
      window.scrollTo(0, 0) // making sure that user view is top of the page
    }

    const accountId = form.corp.account.id; // grabbing from redux
    const companyId = form.corp.id; // grabbing from redux
    const companyInfo = {
      effectiveDate: values.effectiveDate || null,
      registrationVars: {
        useElectronicSig: true,
      },
    }
    const fillingAuthorization = {
      incorporatorAddress: {
        street1: values.street1,
        street2: values.street2,
        city: values.city,
        postalCode: values.postalCode,
        stateProvince: values.state,
        country: values.country,
        phone: values.phone,
      },
      acceptsAgreement: values.userAgreement,
      incorporatorSignature: values.electronicSignature
    }
    API.patchCompany(accountId, companyId, companyInfo).then((companyData) => {
      // console.log("Updated Company:", companyData.data);
      return API.setCompanyFillingAuthorization(accountId, companyId, fillingAuthorization)
    })
    .then((companyData) => {
      // console.log("Updated Company:", companyData.data);
      goToNextPage(companyData.data);
    })
    .catch(err => {
      formErrorHandler(err, setApiErrorState);
    });
  }
  
  const setApiErrorState = (err) => {
    setState(state => ({
      ...state,
      apiErrors: err,
    }));
  }

  const validationSchema = Yup.object().shape({
    street1: Yup.string().min(2, 'Address is  too Short!').max(255, 'Address is too Long!').required('Address is required'),
    street2: Yup.string().min(2, 'Suite/Apt # is oo Short!').max(255, 'Suite/Apt # is too Long!'),
    city: Yup.string().min(2, 'City is too Short!').max(255, 'City is oo Long!').required('City is required'),
    state: Yup.string().min(2, 'State is too Short!').max(255, 'State is oo Long!').required('Please select a state'),
    postalCode: Yup.string().matches(/^\d{5}(?:[-\s]\d{4})?$/, { message: 'Zip Code must match this format: XXXXX or XXXXX-XXXX', excludeEmptyString: true }).required('Zip Code is required'),
    phone: Yup.string().matches(/^[1-9]\d{2}-\d{3}-\d{4}$/, { message: 'Phone must match this format: XXX-XXX-XXXX', excludeEmptyString: true }).required('Phone number is required'),
    // effectiveDateToggle: Yup.boolean(),
    // effectiveDate: Yup.string().when('effectiveDateToggle', {
    //   is: true,
    //   then: Yup.string().matches(/^(0[1-9]|1[0-2])[/](0[1-9]|[12]\d|3[01])[/](19|20)\d{2}$/, { message: 'Effective date must match this format: MM/DD/YYYY', excludeEmptyString: true }).required('Effective date is required'),
    // }),
    effectiveDate: Yup.string().matches(/^(0[1-9]|1[0-2])[/](0[1-9]|[12]\d|3[01])[/](19|20)\d{2}$/, { message: 'Effective date must match this format: MM/DD/YYYY', excludeEmptyString: true }),
    userAgreement: Yup.boolean().oneOf([true], 'The user agreement must be accepted'),
    electronicSignature: Yup.string().min(2, 'Electronic signature is  too Short!').max(255, 'Electronic signature is too Long!').required('Electronic signature is required'),
  })

  function addDays(date, days) {
    const newDate = new Date(Number(date))
    newDate.setDate(date.getDate() + days)
    return newDate
  }
  const todaysDate = new Date(),
        daysAfterDate = addDays(todaysDate, 90); // 90 days from today
  const disabledDays = {
    before: todaysDate,
    after: daysAfterDate,
  };

  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href="https://www.floridaincorporationservice.com" />
      </Helmet>

      <ProgressStatus registrationStep={registrationStep} />

      <Container>
        <Row className="justify-content-center">
          {form[formType].isComplete || form[formType].orderInfoId ? 
            <ApplicationCompleteAlert />
          : null }          
          <Col lg="8" md="10" sm="12">
            <Formik
              enableReinitialize={true}
              initialValues={state.formState}
              validationSchema={validationSchema}
              // onChange={values => handleChange(values)}
              onSubmit={(values, actions) => handleFormSubmit(values, actions)}
            >
              {({ isValid, errors, touched, values, handleChange, setFieldValue, submitCount, isSubmitting }) => (
                <Form>

                  <h2>Last Steps</h2>
                  <p>You're almost done! Upon completion of the registration, it will be posted with the Division of Corporations in 1-2 business days. You will receive an email confirmation of your filing (at <strong>{form[formType].pointOfContact.emailAddress}</strong>).</p>
                  <h3>Notice of Annual Report</h3>
                  <p>This corporation must file an Annual Report with the Division of Corporations between January 1st and May 1st of every year to maintain "active" status. The corporation's first annual report will be due between January 1st and May 1st of the calendar year following the year the corporation is formed and must be filed online. <strong>The fee to file a Corporation Annual Report is $150 And the fee to file an LLC Annual Report is $138. A $400 late fee is applied if the report is filed after May 1st.</strong> Reminder notices to file the Annual Report will be sent to the e-mail address you provide in these articles. File early to avoid the late fee.</p>
                  <p>The final step is to authorize Florida Incorporation Service to file these documents with the Division of Corporations on your behalf. Because our service is entirely online, this is done by providing your "electronic signature," meaning simply that you must provide your contact information below, accept the user agreement, and type your name into the electronic signature box at the bottom.</p>

                  <FormGroup className="form-row">
                    <Label sm={3} className="text-sm-right" for="street1">Address</Label>
                    <Col sm={8}>
                      <Input tag={Field} invalid={errors.street1 && touched.street1} onChange={handleChange} value={values.street1} type="text" name="street1" id="street1" data-hj-masked />
                    </Col>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label sm={3} className="text-sm-right" for="street2">Suite. Apt. #, etc.</Label>
                    <Col sm={8}>
                      <Input tag={Field} invalid={errors.street2 && touched.street2} onChange={handleChange} value={values.street2} type="text" name="street2" id="street2" data-hj-masked />
                    </Col>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label sm={3} className="text-sm-right" for="city">City</Label>
                    <Col sm={8}>
                      <Input tag={Field} invalid={errors.city && touched.city} onChange={handleChange} value={values.city} type="text" name="city" id="city" data-hj-masked />
                    </Col>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label sm={3} className="text-sm-right" for="state">State</Label>
                    <Col sm={8}>
                      <StateDropdown invalid={errors.state && touched.state} onChange={handleChange} value={values.state} name="state" id="state" data-hj-masked />
                    </Col>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label sm={3} className="text-sm-right" for="postalCode">Zip Code</Label>
                    <Col sm={8}>
                      <Input tag={Field} invalid={errors.postalCode && touched.postalCode} onChange={handleChange} value={values.postalCode} type="text" name="postalCode" id="postalCode" data-hj-masked />
                    </Col>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label sm={3} className="text-sm-right" for="phone">Phone</Label>
                    <Col sm={8}>
                      <InputMask className={errors.phone && touched.phone ? "form-control is-invalid" : "form-control"} onChange={handleChange} value={values.phone} type="tel" name="phone" id="phone" data-hj-masked mask="999-999-9999" maskChar={"_"} placeholder="___-___-____" />
                    </Col>
                  </FormGroup>

                  <h3>Delayed Effective Date</h3>
                  <p>Companies registered in Florida can opt to specify a future effective date, meaning that the company will be recognized as a valid corporation or LLC on that date rather than on the date of filing. For instance, a company filing in November 2020 can set an effective date of January 2021 to delay official recognition until the start of the new year. This can be a maximum of 90 days in the future.</p>
                  <div className="indent-wrap">
                    <FormGroup className="form-row">
                      <Col sm={12} className="delayed-date-field">
                        {/* <CustomInput onChange={handleChange} checked={values.effectiveDateToggle} type="checkbox" name="effectiveDateToggle" id="effectiveDateToggle" label={<strong>File my effective date as: </strong>} inline data-hj-masked /> */}
                        <Label className="mr-2"><strong>File my effective date as: </strong></Label> 
                        <DayPickerInput
                          formatDate={formatDate}
                          parseDate={parseDate}
                          placeholder={'MM/DD/YYYY'}
                          value={values.effectiveDate}
                          inputProps={errors.effectiveDate ? {name:'effectiveDate', className:'form-control is-invalid'} : {name:'effectiveDate', className:'form-control'}}
                          dayPickerProps={{
                            fromMonth: todaysDate,
                            toMonth: daysAfterDate,
                            disabledDays: disabledDays,
                          }}
                          onDayChange={(selectedDay, modifiers, dayPickerInput) => handleDayChange(selectedDay, modifiers, dayPickerInput, setFieldValue)}
                          />
                      </Col>
                    </FormGroup>
                    <UserAgreementCheckbox className={errors.userAgreement && touched.userAgreement ? "is-invalid" : ""} onChange={handleChange} checked={values.userAgreement} name="userAgreement" id="userAgreement"  userAgreementFor={accountType} required />
                    <p className="mb-1"><strong>Electronic Signature</strong></p>
                    <FormGroup className="form-row">
                      <Col sm={8}>
                        <Input tag={Field} invalid={errors.electronicSignature && touched.electronicSignature} onChange={handleChange} value={values.electronicSignature} type="text" name="electronicSignature" id="electronicSignature" data-hj-masked />
                      </Col>
                    </FormGroup>
                  </div>

                  <FormValidationAlert
                    submitCount={submitCount}
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                    errors={errors}
                  />

                  <ProcessingStatus showProcessing={state.isProcessing && !state.apiErrors} />

                  <FormErrorModal errors={state.apiErrors} />

                  {(!form[formType].isComplete || !form[formType].orderInfoId) ?
                    <FormNav action={{prev: form[formType].registrationVars?.addEIN ? `/entity-classification/${accountType}/${props.location.search}` : `/business-options/${accountType}/${props.location.search}`}} />
                  : null }
                  
                </Form>
              )}
            </Formik>
          </Col>

          <Sidebar 
            orderDetailPids={state.orderDetailPids} 
            host={host} 
            lgr={state.formState.lgr} 
          />

        </Row>
      </Container>
    </Fragment>
  );
}

export default LastSteps;
