import React, { useState } from "react";
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { 
  Button, 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Alert,
} from 'reactstrap';
import API from "../../services/apiService";
import * as Yup from 'yup';

const CreateAccountModal = (props) => {
  const form = useSelector(state => state.form);

  const accountType = props.match.params.accountType?.toLowerCase() || "",
        formType = accountType === "dba" ? "dba" : "corp" || "",
        formData = form[formType];

  const {
    isOpen,
    toggle,
  } = props;

  const [state, setState] = useState({
    username: "",
    password: "",
    formErrors: {},
    touched: {
      username: false,
      password: false,
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = () => {
    setShowPassword(!showPassword);
  }

  const handleChange = event => {
    const { name, value, type, checked } = event.target;
    const theValue = type === 'checkbox' ? checked : value;
    setState(state => ({
      ...state,
      [name]: theValue
    }))
  }

  const validateField = event => {
    const { name } = event.target;
    setState(state => ({
      ...state,
      touched: {
        ...state.touched,
        [name]: true,
      },
    }))
    validationSchema.validate(state, { abortEarly: false }).then(() => {
      setState(state => ({
        ...state,
        formErrors: {},
      }))
    })
    .catch((error) => {
      if (error.inner) {
        // console.log(error); // Validation Error Object
        // console.log(error.errors);
        const errorObj = error.inner.reduce((obj, key) => ({ ...obj, [key.path.split('.').pop()]: key.message }), {})
        // console.log(errorObj)
        setState(state => ({
          ...state,
          formErrors: errorObj,
        }))
      }
    })
  }

  const checkUsername = event => {
    const { value } = event.target;
    validateField(event);
    const userName = {
      username: value
    }
    if (value) {
      API.checkUsername(userName).then(userNameData => {
        setState(state => ({
          ...state,
          usernameUnavailable: userNameData.data?.isUser, // value is always true/false
        }));
      })
      .catch(err => {
        console.log("Couldn't verify if username is available!",err)
      });
    }
  }

  const validationSchema = Yup.object().shape({
    // usernames is only allowed to use the _ and - special characters or an actual email
    username: Yup.string().matches(/^([A-Za-z0-9_]|([\w|.|-]*@\w*\.[\w|.]))+$/, { message: 'Username cannot contain spaces or special characters other than what\'s allowed on emails addresses.', excludeEmptyString: true }).min(6, 'Username must be at least 6 characters.').max(255, 'Username is too Long!').required('Username is required'),
    password: Yup.string().matches(/^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/, { message: 'Password must be at least 6 characters long, contain at least 1 letter and 1 number, and cannot contain spaces.', excludeEmptyString: true }).required('Password is required'),
  })

  const saveApplication = event => {
    const accountInfo = {
      userName: state.username,
      password: state.password,
      firstName: formData.account?.person?.firstName,
      lastName: formData.account?.person?.lastName,
      emailAddress: formData.account?.person?.emailAddress,
      phoneNumber: formData.account?.person?.businessPhone,
    }
    if (errorList.length <= 0 && touchedAll) {
      API.createExternalAccount(formData.account.id, accountInfo).then(account => {
        // console.log(account)
        props.history.push("/logout");
      })
      .catch(err => {
        console.log("Save Application Error:",err)
        setState(state => ({
          ...state,
          apiErrors: err,
        }));
      });
    }
  }

  const errors = state.formErrors;
  const touched = state.touched;
  const touchedAll = Object.values(touched).filter(value => value === false).length === 0;
  const errorList = Object.values(errors);

  return (
    <Modal centered scrollable backdrop="static" fade={false} isOpen={isOpen} toggle={toggle} className="create-account-modal">
      <ModalHeader tag="h4" toggle={toggle}><span className="text-dark">Save Application and Continue Later?</span></ModalHeader>
      <ModalBody>
        <Alert className="mb-3 p-2 px-3" fade={false} color="warning">To save your application please provide a username and password, you can return to this website and login at any time to complete your application. An email confirmation will be sent to <strong>{formData.account?.person?.emailAddress}</strong>.</Alert>
        <FormGroup>
          <Label for="username">Username</Label>
          <Input invalid={(errors.username && touched.username) || state.usernameUnavailable} onChange={handleChange} value={state.username} type="text" name="username" id="username" data-hj-masked data-private onBlur={checkUsername} />
          { errors.username && touched.username ?
            <small className="form-text text-danger">{errors.username}</small> 
          : null }
          { state.usernameUnavailable ?
            <small className="form-text text-danger">The username you entered is already taken, please use a different one.</small> 
          : null }
        </FormGroup>
        <FormGroup>
          <Label for="password">Password</Label>
          <div className="password-field">
            <Input invalid={errors.password && touched.password} onChange={handleChange} value={state.password} type={showPassword ? "text" : "password"} name="password" id="password" onBlur={validateField}  data-hj-masked data-private />
            <span className={showPassword ? "show-hide-icon show" : "show-hide-icon"} onClick={togglePassword}></span>
          </div>
          <small className="form-text text-muted">Password must be at least 6 characters long, contain at least 1 letter and 1 number, and cannot contain spaces.</small> 
        </FormGroup>
        { errorList.length > 0 && touchedAll ? <p className="text-danger w-100 text-right mb-0">*Please complete the required fields</p> : null }
      </ModalBody>
      <ModalFooter>
        <Button className="close-modal mr-auto w-auto" color="secondary" outline onClick={toggle}>Cancel</Button>
        <Button className="close-modal w-auto" color={errorList.length <= 0 && touchedAll ? "success" : "secondary"} disabled={errorList.length <= 0 && touchedAll ? false : true} onClick={saveApplication}>Save and continue later</Button>
      </ModalFooter>
    </Modal>  
  );

}

export default withRouter(CreateAccountModal);