const initialDbaFormData = {
  id: 0,
  account: {
    id: 0,
    userName: "",
    person: {
      id: 0,
      title: "",
      firstName: "",
      middleName: "",
      lastName: "",
      emailAddress: "",
      mobilePhone: "",
      businessPhone: "",
      ssn: "",
      sex: "",
      race: "",
      dateOfBirth: "",
      driverLicenseNumber: "",
      addresses: [
        {
          id: 0,
          street1: "",
          street2: "",
          city: "",
          postalCode: "",
          county: "",
          stateProvince: "FL",
          country: "US",
          phone: "",
          phoneExt: "",
          fax: "",
        }
      ]
    }
  },
  person: {
    id: 0,
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    emailAddress: "",
    mobilePhone: "",
    businessPhone: "",
    ssn: "",
    sex: "",
    race: "",
    dateOfBirth: "",
    driverLicenseNumber: "",
    addresses: [
      {
        id: 0,
        street1: "",
        street2: "",
        city: "",
        postalCode: "",
        county: "",
        stateProvince: "",
        country: "",
        phone: "",
        phoneExt: "",
        fax: "",
      }
    ]
  },
  pointOfContact: {
    id: 0,
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    emailAddress: "",
    mobilePhone: "",
    businessPhone: "",
    ssn: "",
    sex: "",
    race: "",
    dateOfBirth: "",
    driverLicenseNumber: "",
    addresses: [
      {
        id: 0,
        street1: "",
        street2: "",
        city: "",
        postalCode: "",
        county: "",
        stateProvince: "",
        country: "",
        phone: "",
        phoneExt: "",
        fax: "",
      }
    ]
  },
  companyName: "",
  counties: "",
  companyAddress: {
    id: 0,
    street1: "",
    street2: "",
    city: "",
    postalCode: "",
    county: "",
    stateProvince: "",
    country: "",
    phone: "",
    phoneExt: "",
    fax: "",
  },
  ownedByOtherEntity: false,
  owningEntity: {
    owningEntityName: "",
    owningEntityAddress: {
      street1: "",
      street2: "",
      city: "",
      postalCode: "",
      county: "",
      stateProvince: "",
      country: "",
      phone: "",
      phoneExt: "",
      fax: "",
    },
    owningEntityFRN: "",
    owningEntityFEI: "",
    feiAppliedFor: false,
    feiNotApplicable: false
  },
  applicantSig: "",
  status: "",
  isComplete: false,
  serviceType: 3,
  registrationVars: {
    fisPackage: "", // Silver, LLC or DBA?
    isAdvanced: false,
    isCorporation: false,
    lgr: "",
    addEIN: false,
    addDBA: true,
    willFileEIN: false,
    willFile8832: false,
    unlinkMenuSteps: true,
    useElectronicSig: true,
    returnToSummary: true,
    llcFilingAsCorp: false,
    contactHasPOBox: false,
    doFileSubS: false,
    takeSurvey: false,
    isDirector: false,
    addAnotherDirector: false,
    addAnotherMember: false,
    addAnotherOwner: false,
    isAllOfficers: false,
    addAnotherOfficer: false,
    officerConfirmed: false,
    willManage: false,
    ownedByOtherEntity: false
  },
  wizardBranchPoints: [],
  orderInfoId: 0,
  acceptsAgreement: false,
  signatories: [
    {
      id: 0,
      dbaApplicantId: 0,
      name: "",
      phone: ""
    }
  ],
  individualOwners: [
    {
      id: 0,
      person: {
        id: 0,
        title: "",
        firstName: "",
        middleName: "",
        lastName: "",
        emailAddress: "",
        mobilePhone: "",
        businessPhone: "",
        ssn: "",
        dateOfBirth: "",
        driverLicenseNumber: "",
        addresses: [
          {
            street1: "",
            street2: "",
            city: "",
            postalCode: "",
            county: "",
            stateProvince: "",
            country: "",
            phone: "",
            phoneExt: "",
            fax: ""
          }
        ]
      }
    }
  ],
}

export default initialDbaFormData;