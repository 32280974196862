import React, { useState, useEffect } from "react";
import {
  CustomInput
} from 'reactstrap';
import API from "../../services/apiService";
import { useDispatch } from 'react-redux'
import { appErrorHandler } from '../../utils/errorHandlerUtil'	

const StateDropdown = ({accountType, ...props}) => {
  const [suffixes, setSuffixes] = useState(JSON.parse(sessionStorage.getItem(`fisSuffixes`)) || [])
  // const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!sessionStorage.getItem(`fisSuffixes`)) {
      API.getCorporateSuffixes().then(apiData => {
        setSuffixes(apiData.data)
        // setIsLoading(false)
        sessionStorage.setItem(`fisSuffixes`, JSON.stringify(apiData.data))
      })
      .catch(err => {
        // this sets a redux global app error
        appErrorHandler("SuffixDropdown", err, dispatch)
      });
    }
  }, [accountType, dispatch])

  // for some reason the DB in DL only has serviceType LLC and Gold
  // const serviceType = accountType === "llc" ? "LLC" : "Gold";
  const serviceType = accountType === "llc" ? "LLC" : "Silver";
  let suffixesOptions = suffixes.filter(item => item.serviceType === serviceType).map((item, index) => (
    <option key={index} value={item.useComma ? `, ${item.suffix}` : item.suffix}>{item.useComma ? `, ${item.suffix}` : item.suffix}</option>
  ));
  if (suffixesOptions.length === 0 && accountType === "corp") {
    const serviceType = accountType === "llc" ? "LLC" : "Gold";
    suffixesOptions = suffixes.filter(item => item.serviceType === serviceType).map((item, index) => (
      <option key={index} value={item.useComma ? `, ${item.suffix}` : item.suffix}>{item.useComma ? `, ${item.suffix}` : item.suffix}</option>
    ));
  }
  // const suffixesOptions = suffixes.map((item, index) => (
  //   <option key={index} value={item.useComma ? `, ${item.suffix}` : item.suffix}>{item.useComma ? `, ${item.suffix}` : item.suffix}</option>
  // ));

  return (
    <CustomInput type="select" {...props}  data-hj-masked>
      {suffixesOptions}
      {/* <option value="Co.">Co.</option>
      <option value="Company">Company</option>
      <option value="Corp.">Corp.</option>
      <option value="Corporation">Corporation</option>
      <option value="Inc.">, Inc.</option>
      <option value="Incorporated">Incorporated</option>
      <option value="P.A.">, P.A.</option> */}
      {/* <option value="LLC">, LLC</option>
      <option value="Limited Liability Company">Limited Liability Company</option> */}
    </CustomInput>
  );
}

export default StateDropdown;