import React, { useState } from 'react';
import {
  Col,
  FormGroup,
  CustomInput,
  Modal, 
  ModalHeader, 
  ModalBody
} from 'reactstrap';
import { displayPriceDollar } from "../../../utils/displayPrice";

const CompanyKit = (props) => {
  const pid = 704;
  const [modal, setModal] = useState(false);
  const toggle = (e) => {
    e.preventDefault();
    setModal(!modal);
  }
  const price = displayPriceDollar(pid);

  return (
    <FormGroup className="form-row action-area">
      <Col sm={12} className="ml-md-auto">
        <CustomInput onChange={props.onChange} checked={props.checked} type="checkbox" value={pid} name={props.name} id={props.id} label={<span>Corporate kit with bylaws, minutes, a Florida Business Guide, and a personalized binder for all corporate documents - ${price} <a href="//:0" onClick={toggle} className="upsell_desc_trigger">?</a></span>} inline data-hj-masked />
        <Modal centered isOpen={modal} toggle={toggle} size="lg">
          <ModalHeader toggle={toggle}>Corporate Kit</ModalHeader>
          <ModalBody>
            <p>Bylaws are the initial papers you need to complete and file in your corporate book. These are the papers that lay out how your new corporation will be run, its financial obligations and legal obligations to its shareholders. It also includes the responsibilities and duties of each office and directors as well as the responsibility of the stock holders of the company.</p>
            <p>Minutes, both initial and yearly, need to be written and filed in the corporate book along with the bylaws and stock certificates. The initial minutes are for the board to confirm the elected officers, notify who has been assigned how many shares. It can also include initial salaries of officers, when the board will meet and financial discussion on leasing or ownership the corporations have.</p>
            <p className="mb-0">Yearly minutes need to be filed in the corporate book after a meeting with the officers and board of directors to update what new has happened with the company in terms of officers, leasing, loans, and stock shares.</p>
          </ModalBody>
        </Modal>
      </Col>
    </FormGroup>
  );
}


export default CompanyKit;