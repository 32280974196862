import React, { useState } from 'react';
import {
  Col,
  FormGroup,
  CustomInput,
  Modal, 
  ModalHeader, 
  ModalBody
} from 'reactstrap';
import { displayPriceDollar } from "../../../utils/displayPrice";

const CertificateOfStocksAndCorporateSeals = (props) => {
  const pid = 705;
  const [modal, setModal] = useState(false);
  const toggle = (e) => {
    e.preventDefault();
    setModal(!modal);
  }
  const price = displayPriceDollar(pid);

return (
    <FormGroup className="form-row">
      <Col sm={12} className="ml-md-auto">
        <CustomInput onChange={props.onChange} checked={props.checked} type="checkbox" value={pid} name={props.name} id={props.id} label={<span>Custom stock certificates and corporate seals - ${price} <a href="//:0" onClick={toggle} className="upsell_desc_trigger">?</a></span>} inline data-hj-masked />
        <Modal centered isOpen={modal} toggle={toggle} size="lg">
          <ModalHeader toggle={toggle}>Stock Certificates and Corporate Seals</ModalHeader>
          <ModalBody>
            <p>Ownership in a corporation is conveyed by stock shares. By issuing stock in a company you are issuing ownership in that company. Certificates are the legal document that shows this ownership. Some government agencies may require seeing the issued stock to see proof of ownership. These certificates are personalized and come with personalized seals showing the year of incorporation.</p>
            <p><strong>If you are creating a nonprofit corporation, there are no shares, so there is no need for stock certificates and seals.</strong></p>
            <p className="mb-0"><strong>Please note</strong>: If you are applying for <strong>Workman's Compensation Exemption</strong>, you will need to supply a stock certificate to them with your application of exemption. </p>
          </ModalBody>
        </Modal>
      </Col>
    </FormGroup>
  );
}

export default CertificateOfStocksAndCorporateSeals;