import React, { useState } from "react";
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux'

import {
  FormGroup,
  Button,
} from 'reactstrap';
import LogoutModal from '../LogoutModal';
import CreateAccountModal from '../CreateAccountModal';

const FormNav = (props) => {
  const form = useSelector(state => state.form);
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const accountType = props.match.params.accountType?.toLowerCase() || "",
        formType = accountType === "dba" ? "dba" : "corp" || "",
        formData = form[formType];

  const handlePrev = event => {
    event.preventDefault();
    // console.log(event.currentTarget.dataset.action);
    // console.log(props.validForm);
    let page = event.currentTarget.dataset.action;
    if (page) { 
      props.history.replace(page);
      // props.history.goBack();
      window.scrollTo(0, 0) // making sure that user view is top of the page
    };
  }

  const handleNext = event => {
    if (!props.continueApplication) {
      let page = event.currentTarget.dataset.action;
      if (page && props.validForm) { 
        props.history.push(page);
      };
    }
    else {
      const queryParams = props.location.search;    
      let continueUrl = "";
      const accountInfo = formData.pointOfContact;
      const businessInfo = accountInfo && ((formData.nameCandidates && formData.nameCandidates[0]?.name) || formData.companyName);
      const businessManagement = businessInfo && (formData.directors && formData.directors.length > 0);
      const registeredAgent = businessManagement && ((formData.optionalServices && formData.optionalServices.includes("RegisteredAgentFirstYear")) || formData.registeredAgent?.firstName);
      const businessOwnership = businessInfo && ((formData.individualOwners && formData.individualOwners.length > 0) || formData.owningEntity?.owningEntityName);
      const businessOptions = registeredAgent && (formData.numStockShares > 0 || formData.optionalProducts.length > 0 || formData.registrationVars.addEIN);
      const einApplication = businessOptions && formData.registrationVars.addEIN && formData.firstDayOfBusiness?.toString();
      const einContact = einApplication && formData.registrationVars.addEIN && formData.einDirectorId;
      const entityClassification = (einContact || einApplication) && (formData.subSElection || formData.llcEntityCode || formData.form8832Member?.firstName);
      const lastSteps = (entityClassification || businessOptions) && formData.effectiveDate && formData.incorporatorSig;
      const dbaLastSteps = businessOwnership && formData.signatories[0]?.name && formData.signatories[0]?.phone && formData.applicantSig;
      
      if (lastSteps || dbaLastSteps) {
        continueUrl = `/last-steps/${accountType}/${queryParams}`
      }
      else if (entityClassification) {
        continueUrl = `/entity-classification/${accountType}/${queryParams}`
      }
      else if (einContact) {
        continueUrl = `/ein-contact/${accountType}/${queryParams}`
      }
      else if (einApplication) {
        continueUrl = `/ein-application/${accountType}/${queryParams}`
      }
      else if (businessOptions) {
        continueUrl = `/business-options/${accountType}/${queryParams}`
      }
      else if (businessOwnership) {
        continueUrl = `/business-ownership/${accountType}/${queryParams}`
      }
      else if (registeredAgent) {
        continueUrl = `/registered-agent/${accountType}/${queryParams}`
      }
      else if (businessManagement) {
        continueUrl = `/business-management/${accountType}/${queryParams}`
      }
      else if (accountInfo) {
        continueUrl = `/contact-info/${accountType}/${queryParams}`;
      }
      else if (businessInfo) {
        continueUrl = `/business-info/${accountType}/${queryParams}`;
      }
      props.history.push(continueUrl);
      window.scrollTo(0, 0) // making sure that user view is top of the page
    }
  }

  return (
    <>
      <FormGroup className="form-row form-nav justify-content-between">
        <div className="d-flex w-100 justify-content-between">
          { !props.hidePrevButton ?
            <Button className="app-btn prev-btn text-muted" color="link" size="lg" type="button" data-action={props.action.prev} onClick={handlePrev}><i className="material-icons">arrow_back</i> Previous</Button>
          : null }
          <Button className="app-btn next-btn ml-auto" color="success" size="lg" type="submit" data-action={props.action.next} onClick={handleNext}>{props.nextButtonText ? props.nextButtonText : "Next"} <i className="material-icons">arrow_forward</i></Button>
        </div>
        { !props.hideSaveButton ?
          <Button className="app-btn save-btn text-muted ml-auto" color="link" size="lg" type="button" onClick={toggleModal}><i className="material-icons-outlined">folder_shared</i> Save and Continue Later</Button>
          : null }
      </FormGroup>
      { formData.account.externalUserId ? 
        <LogoutModal isOpen={modal} toggle={toggleModal} />
      :
        <CreateAccountModal isOpen={modal} toggle={toggleModal} />
      }
    </>
  );
}

export default withRouter(FormNav);
