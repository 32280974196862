import React, { Fragment } from "react";
import { Container, Row, Col } from 'reactstrap';
import {Helmet} from "react-helmet";
// import { signinRedirect } from '../services/authService';

function NoMatch(props) {
  const login = (e) => {
    e.preventDefault();
    props.history.push("/login")
    // signinRedirect();
  }

  return (
    <Fragment>
      <Helmet>
        <title>Page Not Found...</title>
        <link rel="canonical" href="https://www.floridaincorporationservice.com" />
      </Helmet>
      <Container>
        <Row className="justify-content-center">
          <Col lg="8" md="10" sm="12">
            <h1 className="mt-4">Oopsies, something went wrong...</h1>
            <p>We're sorry, the page you requested cannot be found or your session has expired. Here are some tips to help you get back to your application.</p>
            <h3 className="h4">Try and check the following:</h3>
            <ul className="star">
              <li>If you already have an account, try to <a href="//:0" onClick={(e) => login(e)}>login</a> again and continue your application</li>
              <li>Check if you have typed the wrong URL address</li>
              <li>You may have clicked an out-of-date link</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default NoMatch;