import React from "react";
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem
} from 'reactstrap';

const PointOfContact = (props) => {
  const firstName = props.data.pointOfContact?.firstName || "";
  const lastName = props.data.pointOfContact?.lastName || "";
  const street1 = props.data.pointOfContact?.addresses[0]?.street1 || "";
  const street2 = props.data.pointOfContact?.addresses[0]?.street2 || "";
  const city = props.data.pointOfContact?.addresses[0]?.city || "";
  const phone = props.data.pointOfContact?.addresses[0]?.phone || "";
  const email = props.data.pointOfContact?.emailAddress || "";
  const postalCode = props.data.pointOfContact?.addresses[0]?.postalCode || "";
  const stateProvince = props.data.pointOfContact?.addresses[0]?.stateProvince || "";

  const editLink = `/contact-info/${props.accountType}/${props.queryString}`;

  const handleEdit = event => {
    event.preventDefault();
    props.history.push(editLink);
    window.scrollTo(0, 0) // making sure that user view is top of the page
  }

  const showEdit = !(props.data.orderInfoId || props.data.isComplete);

  if (firstName || lastName || street1 || street2 || city || phone || postalCode || stateProvince) {
    return (
      <Card className="card-summary">
        <CardHeader tag="h5" className="mt-0">Point of Contact {showEdit && <a href="//:0" onClick={handleEdit} className="edit ml-auto">Edit</a>}</CardHeader>
        <ListGroup flush>
          <ListGroupItem>
            <span className="info-title">Name:</span>
            <span className="info-data">{firstName} {lastName}</span>
          </ListGroupItem>
          <ListGroupItem>
            <span className="info-title">Address:</span>
            <span className="info-data">
              {street1}, {street2} <br/>
              {city}, {stateProvince} {postalCode} <br/>
            </span>
          </ListGroupItem>
          <ListGroupItem>
            <span className="info-title">Email:</span>
            <span className="info-data">{email}</span>
          </ListGroupItem>
          <ListGroupItem>
            <span className="info-title">Phone:</span>
            <span className="info-data">{phone}</span>
          </ListGroupItem>
        </ListGroup>
      </Card>
    );
  }
  else {
    return null;
  }
}

export default withRouter(PointOfContact);

