import React from "react";
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem
} from 'reactstrap';

const Officers = (props) => {
  const titles = ["President", "Vice President", "Secretary", "Treasurer"];
  const officers = props.data.officers || [];
  
  const officersDetails = titles.map((item, index) => {
    const officer = officers.find(officer => officer.title === item);
    if (officer) {
      return (
        <ListGroupItem key={index}>
          <span className="info-title">{officer?.title}:</span>
          <span className="info-data">
            {officer?.person.firstName} {officer?.person.lastName} <br/>
            {officer?.person.addresses[0].street1}, {officer?.person.addresses[0].street2} <br/>
            {officer?.person.addresses[0].city}, {officer?.person.addresses[0].stateProvince} {officer?.person.addresses[0].postalCode} <br/>
            {officer?.person.phone}
          </span>
        </ListGroupItem>
      )
    }
    else {
      return null;
    }
  })
  
  const editLink = `/business-management/${props.accountType}/${props.queryString}`;

  const handleEdit = event => {
    event.preventDefault();
    props.history.push(editLink);
    window.scrollTo(0, 0) // making sure that user view is top of the page
  }

  const showEdit = !(props.data.orderInfoId || props.data.isComplete);

  if (officers[0]?.person?.firstName) {
    return (
      <Card className="card-summary">
        <CardHeader tag="h5" className="mt-0">Officers {showEdit && <a href="//:0" onClick={handleEdit} className="edit ml-auto">Edit</a>}</CardHeader>
        <ListGroup flush>
          {officersDetails}
        </ListGroup>
      </Card>
    );
    }
  else {
    return null;
  }
}

export default withRouter(Officers);
