import React, { useState } from 'react';
import {
  Col,
  FormGroup,
  CustomInput,
  Modal, 
  ModalHeader, 
  ModalBody
} from 'reactstrap';
import { displayPriceDollar } from "../../../utils/displayPrice";

const CompanyKit = (props) => {
  const pid = 749;
  const [modal, setModal] = useState(false);
  const toggle = (e) => {
    e.preventDefault();
    setModal(!modal);
  }
  const price = displayPriceDollar(pid);

  return (
    <FormGroup className="form-row action-area">
      <Col sm={12} className="ml-md-auto">
        <CustomInput onChange={props.onChange} checked={props.checked} type="checkbox" value={pid} name={props.name} id={props.id} label={<span>Company Kit (includes operating agreement, Florida Business Guide, and binder to hold company documents) - ${price} <a href="//:0" onClick={toggle} className="upsell_desc_trigger">?</a></span>} inline data-hj-masked />
        <Modal centered isOpen={modal} toggle={toggle} size="lg">
          <ModalHeader toggle={toggle}>Company Kit</ModalHeader>
          <ModalBody>
            <p className="mb-0">An Operating Agreement is a document that sets down the responsibilities of each of the members and managers. It also sets down who owns how much of the company as well as lists what each member has invested in the company. It is the document that says who will be doing what in the running of the company. This is an important document that needs to be created at the beginning of a new LLC and updated as the company grows and changes. </p>
          </ModalBody>
        </Modal>
      </Col>
    </FormGroup>
  );
}

export default CompanyKit;