// this page is used as the "callback" page for /services/authServices.js set in the "redirect_uri" config
import React, { useEffect, Fragment } from 'react'
import { Container, Row, Col } from 'reactstrap';
import { Helmet } from "react-helmet";
import { signinRedirectCallback } from '../services/authService'
import { useHistory } from 'react-router-dom'

function SignIn() {
  const history = useHistory()
  useEffect(() => {
    async function signinAsync() {
      await signinRedirectCallback()
      history.replace('/company-summary')
    }
    signinAsync()
  }, [history])

  return (
    <Fragment>
      <Helmet>
        <title>Logging you in...</title>
        <link rel="canonical" href="https://www.floridaincorporationservice.com" />
      </Helmet>
      <Container>
        <Row className="justify-content-center text-center">
          <Col lg="8" md="10" sm="12">
            <h1 className="mt-4">We're securely logging you in...</h1>
            <p>If you don't get redirected automatically, please refresh the page.</p>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default SignIn;
