import React, { Fragment } from 'react';
import { Card, Table,  Button } from 'reactstrap';

const PersonList = (props) => {
  const {
    values,
    removePerson,
    personsData,
    personType,
    personListFor,
    showPersonForm,
  } = props;

  const personDetails = personsData.map((item, index) => (
    <tr key={index}>
      <td><strong>{item.person?.firstName} {item.person?.lastName}</strong> {item.title ? `(${item.title})` : null}</td>
      <td>{item.person?.addresses[0]?.street1}, {item.person?.addresses[0]?.street2 ? `${item.person?.addresses[0]?.street2},` : null} {item.person?.addresses[0]?.city}, {item.person?.addresses[0]?.stateProvince} {item.person?.addresses[0]?.postalCode}</td>
      <td>{item.person?.emailAddress ? item.person?.emailAddress : null}</td>
      <td><button type="button" className="close text-danger" onClick={() => removePerson(index, personListFor, values)}><span aria-hidden="true">×</span></button></td>
    </tr>
  ))

  return (
    <Fragment>
      {
        personsData.length > 0 ? (
          <div className="added-persons mb-4">
            <h5>List of {personType}s</h5>
            <Card className="person-list mb-3">
              <Table className="mb-0" size="sm" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Name</th>
                    <th>Address</th>
                    <th>Email</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {personDetails}
                </tbody>
              </Table>
            </Card>
            <Button className="add-person" color="success" outline type="button" onClick={showPersonForm}><i className="material-icons">group_add</i> Add Another {personType}</Button>
          </div>
        ) : (
          null
        )
      }
    </Fragment>
  );

}

export default PersonList;