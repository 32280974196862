// this page is used as the "callback" page for /services/authServices.js set in the "redirect_uri" config
import React, { useEffect, Fragment } from 'react'
import { Container, Row, Col } from 'reactstrap';
import queryString from "query-string";
import { Helmet } from "react-helmet";
import API from "../services/apiService";
import { signinSilentCallback } from '../services/authService'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { appErrorHandler } from "../utils/errorHandlerUtil";

function CompleteRedirector(props) {
  const user = useSelector(state => state.auth.user) ? true : false;
  const form = useSelector(state => state.form);
  const history = useHistory()
  const dispatch = useDispatch();  

  const startChat = (e) => {
    e.preventDefault();
    // console.log("chat clicked");
    const olark = window.olark;
    olark('api.box.expand');
  }

  const { Identity } = JSON.parse(sessionStorage.getItem("appsettings"));
  const fisAuth = Identity.Authority;
  let oldAppUrlPrefix = "application";
  if (fisAuth.includes("dlauth")) {
    oldAppUrlPrefix = "dlapplication";
  }
  if (fisAuth.includes("mlauth")) {
    oldAppUrlPrefix = "mlapplication";
  }
  
  useEffect(() => {
    // current redirect urls from receipt page.
    // corp: https://application.floridaincorporationservice.com/Registration_Payment.aspx?c=0CGzAN2yPEfSI%3deK6oIxqy3aI%3d&oid=0Cs309YKWzvTs%3drCdB7TThmrFD1ohTBOAYbw%3d%3d
    // dba: https://application.floridaincorporationservice.com/DBA/Payment.aspx?d=0CJTkxfQLC6HA%3dSVxp4dxqvm8%3d&oid=0CMUhEdLKUTaY%3d%2bZy3xevtq7pD93pH%2bNnJiw%3d%3d
    const queryObject = queryString.parse(props.location.search)
    const corpId = queryObject.c
    const dbaId = queryObject.d
    const oid = queryObject.oid
    
    if (user) {
      const setOrdeInfoId = () => {
        const formType = corpId ?  "corp" : "dba";
        const accountId = form[formType].account.id;
        const id = form[formType].id;
        const orderInfoId = {
          EncryptedOrderInfoId: decodeURIComponent(oid),
        };
        if (corpId) {
          return API.setCompanyOrderInfoId(accountId, id, orderInfoId)
        }
        else {
          return API.setDbaOrderInfoId(accountId, id, orderInfoId)
        }
      }
      async function signinAsync() {
        await signinSilentCallback()
        try {
          const completedApplicationData = await setOrdeInfoId();
          sessionStorage.setItem('completedApplicationData', JSON.stringify(completedApplicationData.data));

          history.replace('/completed-summary')
        }
        catch (err) {
          // console.log(err)
          appErrorHandler("SetOrderInfoId", err, dispatch)
        }
        // setOrdeInfoId().then(() => {
        //   history.replace('/company-summary')
        // })
        // .catch(err => {
        //   console.log(err)
        //   appErrorHandler("Custom Error", err, dispatch)
        //   // formErrorHandler(err, setApiErrorState);
        // });
      }
      signinAsync()
    }
    else {
      const redirectUrl = `https://${oldAppUrlPrefix}.floridaincorporationservice.com/${corpId ? `Registration_Payment.aspx${props.location.search}` : dbaId ? `/DBA/Payment.aspx${props.location.search}` : null}`;
      window.location.replace(redirectUrl)
    }
  }, [history, user, oldAppUrlPrefix, props.location.search, form, dispatch])

  return (
    <Fragment>
      <Helmet>
        <title>Application Complete</title>
        <link rel="canonical" href="https://www.floridaincorporationservice.com" />
      </Helmet>
      <Container>
        <Row className="justify-content-center text-center">
          <Col lg="8" md="10" sm="12">
            <h1 className="mt-4">Application Complete...</h1>
            <p>If you don't get redirected automatically, please refresh the page.</p>
            <p>If you continue to experience the same issue, please contact customer support via <a className="livechat" href="//:0" onClick={startChat}>Live Chat</a>.</p>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default CompleteRedirector;
