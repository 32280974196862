import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {
  Container,
  Row,
  Col,
  FormGroup,
  CustomInput,
  Alert
} from 'reactstrap';
import queryString from "query-string";
import {Helmet} from "react-helmet";
import { Form, Formik } from "formik";
// import * as Yup from 'yup';
import API from "../services/apiService";
import { generateKeys, generatePids } from "../utils/productMapping";
import Sidebar from "../components/Sidebar";
import ProgressStatus from "../components/ProgressStatus";
import FormNav from "../components/FormNav";
import FormValidationAlert from "../components/FormValidationAlert";
import ProcessingStatus from "../components/ProcessingStatus";
import FormErrorModal from "../components/FormErrorModal";
import ApplicationCompleteAlert from "../components/ApplicationCompleteAlert";
import { formErrorHandler, appErrorHandler } from "../utils/errorHandlerUtil";
import { displayPriceDollar } from "../utils/displayPrice";
import { storeApplication } from '../store/actions/formActions'
import { accountInfoState } from "../utils/storeState";

function RegisteredAgent(props) {
  const form = useSelector(state => state.form);
  const dispatch = useDispatch();  

  const pageTitle = "Florida Incorporation Service Registration | Registered Agent",
        registrationStep = 2,
        accountType = props.match.params.accountType?.toLowerCase(),
        orderDetails = JSON.parse(sessionStorage.getItem("orderDetails")) || [],
        host = queryString.parse(props.location.search)?.host || "",
        formType = accountType === "dba" ? "dba" : "corp";

  // checks if current url is valid for the stored order data
  const { pid, lgr, pkg } = queryString.parse(props.location.search);
  if (!pid || !lgr || !host || (accountType !== "dba" ? !pkg : false)) {
    appErrorHandler("Custom Error", `Missing query string ${props.history.location.pathname}${props.history.location.search}`, dispatch)
    // props.history.replace(`/error/${accountType}/${props.location.search}`);
  }
  if (orderDetails.length && (Number(orderDetails[0].id) !== Number(pid) || orderDetails[0].lgr !== lgr?.toLowerCase())) {
    sessionStorage.removeItem('orderDetails');
    sessionStorage.removeItem('productsData');
    sessionStorage.removeItem('fisFormState');
    props.history.replace(`/account-info/${accountType}/${props.location.search}`);
  }

  const initState = {
    formState: {
      pointOfContact: form[formType].pointOfContact || {},
      // will need to check if "optionalServices" have the "RegisteredAgentFirstYear" service
      registeredAgent: form[formType].optionalServices?.includes("RegisteredAgentFirstYear") || (!form[formType].registeredAgent ? true : false),
      fisPackage: form[formType].registrationVars?.fisPackage || "",
      lgr: form[formType].registrationVars?.lgr || "",
      optionalProducts: form[formType].optionalProducts || [], // array can contain one or more of the following: SubSElection, BylawsAndMinutes, CertificateOfStatus, CorporateEmbosser, CorpSealsAndCerts, Form8832Filing, Form8832Filing, LLCSealsAndCerts, OperationalAgreement
      optionalServices: form[formType].optionalServices || [],
    },
    // isProcessing: true,
  }

  const checkedProducts = Object.keys(initState.formState).filter(key => initState.formState[key]); // generate product keys for generatePids()
  const generatedPids = generatePids([...checkedProducts, ...initState.formState.optionalProducts, ...initState.formState.optionalServices])
  const uniquePids = new Set([Number(pid), ...generatedPids]); // removing duplicate PIDs
  const productKeys = generateKeys([...uniquePids]); // object of product keys with true value

  const [state, setState] = useState({
    ...initState,
    formState: {
      ...initState.formState,
      ...productKeys,
    },
    orderDetailPids: [...uniquePids]
  });

  // useEffect(() => {
  //   setState(state => ({
  //     ...state,
  //     isProcessing: false,
  //   }));
  // }, []);

  const handleRegisteredAgentChange = (event, setFieldValue) => {
    const { name, value, type, checked } = event.target;
    const theValue = type === 'checkbox' ? checked : value;
    setFieldValue([name], theValue);
    let orderDetailPids = state.orderDetailPids;
    if (theValue) {
      orderDetailPids.push(Number(value));
    }
    else {
      orderDetailPids = orderDetailPids.filter(item => item !== Number(value))
    }
    orderDetailPids = new Set([...orderDetailPids]); // removing duplicate PIDs
    setState(state => ({
      ...state,
      orderDetailPids: [...orderDetailPids]
    }));
  }

  const removeUpsell = (event, name, pid, setFieldValue) => {
    event.preventDefault();
    setFieldValue([name], false);
    let orderDetailPids = state.orderDetailPids;
    orderDetailPids = orderDetailPids.filter(item => item !== Number(pid))
    orderDetailPids = new Set([...orderDetailPids]); // removing duplicate PIDs
    setState(state => ({
      ...state,
      orderDetailPids: [...orderDetailPids]
    }));
  }

  const handleFormSubmit = (values, actions) => {
    // console.log(values);
    setState(state => ({
      ...state,
      formState: {
        ...state.formState,
        ...values,
      },
      isProcessing: true,
    }));

    const goToNextPage = (apiData) => {
      sessionStorage.removeItem('fisFormState'); // deleting current formState from sessionStorage
      setState(state => ({
        ...state,
        isProcessing: false,
      }));
      // creating object that matched the redux state object to prevent removing any key/values that's not updated
      const applicationState = accountInfoState(accountType, form, apiData);
      dispatch(storeApplication(applicationState)) // sets redux store with form info
      actions.resetForm(true);
      const nextUrl = `/business-options/${accountType}/${props.location.search}`;
      props.history.push(nextUrl); // push user to next page after form submission or api call
      window.scrollTo(0, 0) // making sure that user view is top of the page
    }
    
    const accountId = form.corp.account.id; // grabbing from redux
    const companyId = form.corp.id; // grabbing from redux
    let registeredAgentInfo = {
      useFisAsRegisteredAgent: values.registeredAgent,
    }
    API.setRegisteredAgent(accountId, companyId, registeredAgentInfo).then((companyData) => {
      // console.log("Added Registered Agent:", companyData.data);
      goToNextPage(companyData.data);
    })
    .catch(err => {
      formErrorHandler(err, setApiErrorState);
    });
  }

  const setApiErrorState = (err) => {
    setState(state => ({
      ...state,
      apiErrors: err,
    }));
  }

  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href="https://www.floridaincorporationservice.com" />
      </Helmet>

      <ProgressStatus registrationStep={registrationStep} />

      <Container>
        <Row className="justify-content-center">
          {form[formType].isComplete || form[formType].orderInfoId ? 
            <ApplicationCompleteAlert />
          : null }          
          <Col lg="8" md="10" sm="12">
            <Formik
              enableReinitialize={true}
              initialValues={state.formState}
              // validationSchema={validationSchema}
              // onChange={values => handleChange(values)}
              onSubmit={(values, actions) => handleFormSubmit(values, actions)}
            >
              {({ isValid, errors, touched, values, handleChange, setFieldValue, submitCount, isSubmitting }) => (
                <Form>

                  <h2>Who will be the Registered Agent?</h2>
                  <p>Florida's Division of Corporations requires that a company maintain a Registered Agent. Florida Incorporation Service (FIS) can act as your registered agent. This will relieve you from the administrative and organizational costs of doing this yourself.</p>
                  <p>As your registered agent, Florida Incorporation Service (FIS) will receive official company documents on your behalf.</p>
                  <p>This additional service can be added for only ${displayPriceDollar(701)} for the first year of service.</p>
                  <FormGroup className="form-row action-area">
                    <Col sm={12} className="ml-md-auto">
                      <CustomInput className="boxed-radio" onChange={event => handleRegisteredAgentChange(event, setFieldValue)} checked={values.registeredAgent} type="checkbox" value={701} name="registeredAgent" id="registeredAgent" label={<strong>I want FIS to be my Registered Agent</strong>} inline data-hj-masked />
                      <a href="/" className="small text-muted font-weight-light idontwant" onClick={event => {removeUpsell(event, 'registeredAgent', 701, setFieldValue)}}>I don't want FIS to be my Registered Agent</a>
                    </Col>
                  </FormGroup>

                  {
                    !values.registeredAgent ? (
                      <Fragment>
                        <p>As a secondary option, you or someone at your company may prefer to act as the registered agent for the company being formed.</p>
                        <p className="mb-2">We show your information as:</p>
                        <Alert color="light" className="added-person-info">
                          <strong>{values.pointOfContact.firstName} {values.pointOfContact.lastName}</strong><br />
                          {values.pointOfContact.addresses[0].street1}, {values.pointOfContact.addresses[0].street2 ? `${values.pointOfContact.addresses[0].street2},` : null} {values.pointOfContact.addresses[0].city}, {values.pointOfContact.addresses[0].stateProvince} {values.pointOfContact.addresses[0].postalCode}
                        </Alert >
                        <p>Will you be your own registered agent, using the information as above?</p>
                        <div className="agent-agreement">
                          <h3>Registered Agent Agreement</h3>
                          <p><em>Having been named as registered agent and to accept service of process for the above stated company at the place designated above, I hereby accept the appointment as registered agent and agree to act in this capacity. I further agree to comply with the provisions of all statutes relating to the proper and complete performance of my duties, and I am familiar with and accept the obligations of my position as registered agent as provided for in Chapter 608, Florida Statutes.</em></p>
                          <p><em>By clicking the "<strong>Next</strong>" button below, you will signify your consent to this agreement and your desire to act as registered agent for the company being formed.</em></p>
                        </div>
                      </Fragment>
                    ) : null
                  }

                  <FormValidationAlert
                    submitCount={submitCount}
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                    errors={errors}
                  />

                  <ProcessingStatus showProcessing={state.isProcessing && !state.apiErrors} />

                  <FormErrorModal errors={state.apiErrors} />

                  {(!form[formType].isComplete || !form[formType].orderInfoId) ?
                    <FormNav action={{prev: `/business-management/${accountType}/${props.location.search}`}} />
                  : null }
                  
                </Form>
              )}
            </Formik>
          </Col>

          <Sidebar
            orderDetailPids={state.orderDetailPids} 
            host={host}
            lgr={state.formState.lgr}
          />

        </Row>
      </Container>
    </Fragment>
  );
}

export default RegisteredAgent;
