import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CustomInput
} from 'reactstrap';
import queryString from "query-string";
import {Helmet} from "react-helmet";
import { Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import API from "../services/apiService";
import { generatePids, generateKeys } from "../utils/productMapping";
import Sidebar from "../components/Sidebar";
import ProgressStatus from "../components/ProgressStatus";
import FormNav from "../components/FormNav";
import FormValidationAlert from "../components/FormValidationAlert";
import ProcessingStatus from "../components/ProcessingStatus";
import FormErrorModal from "../components/FormErrorModal";
import ApplicationCompleteAlert from "../components/ApplicationCompleteAlert";
import InputMask from 'react-input-mask';
import { formErrorHandler, appErrorHandler } from "../utils/errorHandlerUtil";
import { storeApplication } from '../store/actions/formActions'	
import { accountInfoState } from "../utils/storeState";

function EINContact(props) {
  const form = useSelector(state => state.form);
  const dispatch = useDispatch();  

  const pageTitle = "Florida Incorporation Service Registration | EIN Application",
        registrationStep = 2,
        accountType = props.match.params.accountType?.toLowerCase(),
        orderDetails = JSON.parse(sessionStorage.getItem("orderDetails")) || [],
        host = queryString.parse(props.location.search)?.host || "",
        formType = accountType === "dba" ? "dba" : "corp";

  // checks if current url is valid for the stored order data
  const { pid, lgr, pkg } = queryString.parse(props.location.search);
  if (!pid || !lgr || !host || (accountType !== "dba" ? !pkg : false)) {
    appErrorHandler("Custom Error", `Missing query string ${props.history.location.pathname}${props.history.location.search}`, dispatch)
    // props.history.replace(`/error/${accountType}/${props.location.search}`);
  }
  if (orderDetails.length && (Number(orderDetails[0].id) !== Number(pid) || orderDetails[0].lgr !== lgr?.toLowerCase())) {
    sessionStorage.removeItem('orderDetails');
    sessionStorage.removeItem('productsData');
    sessionStorage.removeItem('fisFormState');
    props.history.replace(`/account-info/${accountType}/${props.location.search}`);
  }

  const initState = {
    formState: {
      companyManageBy: form[formType].llcManagementCode === 1 ? "members" : "managers",
      einRegistration: true,
      memberId: form[formType].einDirectorId ? form[formType].einDirectorId : form[formType].llcManagementCode === 1 ? form[formType].directors[0]?.id : null,
      get memberFirstName() { return form[formType].directors.filter(director => director.id === this.memberId)[0]?.person.firstName || form[formType].managerManagedEinPerson?.firstName || "" },
      get memberLastName() { return form[formType].directors.filter(director => director.id === this.memberId)[0]?.person.lastName || form[formType].managerManagedEinPerson?.lastName || "" },
      // get memberPhone() { return form[formType].directors[this.memberId]?.person.businessPhone || "" },
      get memberPhone() { return form[formType].directors.filter(director => director.id === this.memberId)[0]?.person.businessPhone || form[formType].managerManagedEinPerson?.businessPhone || ""},
      get memberSsn() { return form[formType].directors.filter(director => director.id === this.memberId)[0]?.person.ssn || form[formType].managerManagedEinPerson?.ssn || "" },
      irsFirstName: form[formType].irsContactPerson?.firstName || "",
      irsLastName: form[formType].irsContactPerson?.lastName || "",
      irsPhone: form[formType].irsContactPerson?.businessPhone || "",
      fisPackage: form[formType].registrationVars?.fisPackage || "",
      lgr: form[formType].registrationVars?.lgr || "",
      optionalProducts: form[formType].optionalProducts || [], // array can contain one or more of the following: SubSElection, BylawsAndMinutes, CertificateOfStatus, CorporateEmbosser, CorpSealsAndCerts, Form8832Filing, Form8832Filing, LLCSealsAndCerts, OperationalAgreement 
      optionalServices: form[formType].optionalServices || [],
    },
    membersData: form[formType].llcManagementCode === 1 ? form[formType].directors : [],
  };

  const checkedProducts = Object.keys(initState.formState).filter(key => initState.formState[key]); // generate product keys for generatePids()
  const generatedPids = generatePids([...checkedProducts, ...initState.formState.optionalProducts, ...initState.formState.optionalServices])
  const uniquePids = new Set([Number(pid), ...generatedPids]); // removing duplicate PIDs
  const productKeys = generateKeys([...uniquePids]); // object of product keys with true value

  const [state, setState] = useState({
    ...initState,
    formState: {
      ...initState.formState,
      ...productKeys,
    },
    orderDetailPids: [...uniquePids]
  });


  const handleFormSubmit = (values, actions) => {
    // console.log(values);
    setState(state => ({
      ...state,
      formState: {
        ...state.formState,
        ...values,
      },
      isProcessing: true,
    }));

    const goToNextPage = (apiData) => {
      sessionStorage.removeItem('fisFormState'); // deleting current formState from sessionStorage
      setState(state => ({
        ...state,
        isProcessing: false,
      }));
      // creating object that matched the redux state object to prevent removing any key/values that's not updated
      const applicationState = accountInfoState(accountType, form, apiData);
      dispatch(storeApplication(applicationState)) // sets redux store with form info
      // console.log("Submitted, no errors in form!")
      actions.resetForm(true);
      // const nextUrl = `/ein-application/${accountType}/${props.location.search}`;  
      const nextUrl = `/entity-classification/${accountType}/${props.location.search}`;  
      props.history.push(nextUrl); // push user to next page after form submission or api call
      window.scrollTo(0, 0) // making sure that user view is top of the page
    }

    const accountId = form.corp.account.id; // grabbing from redux
    const companyId = form.corp.id; // grabbing from redux
    const einPersonInfo = {
      memberManagedEinDirectorId: values.memberId,
      einPersonSsn: values.memberSsn.replace("-", ""),
      einPersonBusinessPhone: values.memberPhone,
    }
    if (state.formState.companyManageBy === "managers" ) {
      delete einPersonInfo.memberManagedEinDirectorId;
      einPersonInfo.managerManagedEinPerson = {
        firstName: values.memberFirstName,
        lastName: values.memberLastName,
        businessPhone: values.memberPhone,
        ssn: values.memberSsn.replace("-", ""),
      }
    }
    const companyInfo = {
      irsContactPerson: {
        firstName: values.irsFirstName,
        lastName: values.irsLastName,
        businessPhone: values.irsPhone,
      },
    }

    // console.log("einPersonInfo", JSON.stringify(einPersonInfo, null, 2))
    // console.log("companyInfo", JSON.stringify(companyInfo, null, 2))
    // this is waiting on ticket FIS-92, this also becomes the president of the company, this fails on patchCompany() adding the irsContactPerson
    API.addEINPerson(accountId, companyId, einPersonInfo).then((companyData) => {
      // console.log("Added EIN Application:", companyData.data);
      return API.patchCompany(accountId, companyId, companyInfo)
    })
    .then((companyData) => {
      // console.log("Update Company:", companyData.data);
      goToNextPage(companyData.data);
    })
    .catch(err => {
      formErrorHandler(err, setApiErrorState);
    });
  }
  
  const setApiErrorState = (err) => {
    setState(state => ({
      ...state,
      apiErrors: err,
    }));
  }

  const [showSSN, setShowSSN] = useState(true);

  const validationSchema = Yup.object().shape({
    memberFirstName: Yup.string().when({
      is: () => state.formState.companyManageBy !== "members",
      then: Yup.string().min(2, 'Owner First Name is too Short!').max(255, 'Member (owner) First Name is too Long!').required('Member (owner) First Name is required'),
    }),
    memberLastName: Yup.string().when({
      is: () => state.formState.companyManageBy !== "members",
      then: Yup.string().min(2, 'Owner Last Name is too Short!').max(255, 'Member (owner) Last Name is too Long!').required('Member (owner) Last Name is required'),
    }),
    memberPhone: Yup.string().matches(/^[1-9]\d{2}-\d{3}-\d{4}$/, { message: 'Member (owner) phone must match this format: XXX-XXX-XXXX', excludeEmptyString: true }).required('Member (owner) Phone number is required'),
    memberSsn: Yup.string().matches(/^\d{3}-?\d{2}-?\d{4}$/, { message: 'Member (owner) SSN match this format: XXX-XX-XXXX', excludeEmptyString: true }).required('Member (owner) SSN is required'),
    irsFirstName: Yup.string().min(2, 'IRS Contact First Name is too Short!').max(255, 'IRS Contact First Name is too Long!').required('Contact First Name is required'),
    irsLastName: Yup.string().min(2, 'IRS Contact Last Name is too Short!').max(255, 'IRS Contact Last Name is too Long!').required('Contact Last Name is required'),
    irsPhone: Yup.string().matches(/^[1-9]\d{2}-\d{3}-\d{4}$/, { message: 'IRS Contact Phone must match this format: XXX-XXX-XXXX', excludeEmptyString: true }).required('IRS Contact Phone number is required'),
  })

  const membersData = state.membersData;
  const personDetails = membersData.map((item, index) => (
    <option key={index} value={item.id}>{item.person.firstName} {item.person.lastName}</option>
  ));

  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href="https://www.floridaincorporationservice.com" />
      </Helmet>

      <ProgressStatus registrationStep={registrationStep} />
      <Container>
        <Row className="justify-content-center">
          {form[formType].isComplete || form[formType].orderInfoId ? 
            <ApplicationCompleteAlert />
          : null }          
          <Col lg="8" md="10" sm="12">
            <Formik
              enableReinitialize={true}
              initialValues={state.formState}
              validationSchema={validationSchema}
              // onChange={values => handleChange(values)}
              onSubmit={(values, actions) => handleFormSubmit(values, actions)}
            >
              {({ isValid, errors, touched, values, handleChange, submitCount, isSubmitting }) => (
                <Form>

                  <h2>Tax Identification Number (EIN) Contact</h2>
                  <p>Please supply the Social Security Number and telephone number of one of the Members (owners) of your new company. This is required by the IRS to file your EIN Application (Form SS-4).</p>
                  <p><em className="text-danger">Please note: if you are not a US resident, please call us for special instructions at <strong>800-370-2942</strong> to complete this page.</em></p>
                  { values.companyManageBy === "members" ? 
                    <FormGroup className="form-row">
                      <Label sm={3} className="text-sm-right" for="memberId">Member (owner):</Label>
                      <Col sm={8}>
                        <CustomInput invalid={errors.memberId && touched.memberId} onChange={handleChange} value={values.memberId} type="select" name="memberId" id="memberId" data-hj-masked>
                          {personDetails}
                        </CustomInput>
                      </Col>
                    </FormGroup>
                  : 
                    <Fragment>
                      <FormGroup className="form-row">
                        <Label sm={3} className="text-sm-right" for="memberFirstName">Owner First Name</Label>
                        <Col sm={8}>
                          <Input tag={Field} invalid={errors.memberFirstName && touched.memberFirstName} onChange={handleChange} value={values.memberFirstName} type="text" name="memberFirstName" id="memberFirstName" data-hj-masked />
                        </Col>
                      </FormGroup>
                      <FormGroup className="form-row">
                        <Label sm={3} className="text-sm-right" for="memberLastName">Owner Last Name</Label>
                        <Col sm={8}>
                          <Input tag={Field} invalid={errors.memberLastName && touched.memberLastName} onChange={handleChange} value={values.memberLastName} type="text" name="memberLastName" id="memberLastName" data-hj-masked />
                        </Col>
                      </FormGroup>
                    </Fragment>
                  }
                  <FormGroup className="form-row">
                    <Label sm={3} className="text-sm-right" for="memberPhone">Phone</Label>
                    <Col sm={8}>
                      <InputMask className={errors.memberPhone && touched.memberPhone ? "form-control is-invalid" : "form-control"} onChange={handleChange} value={values.memberPhone} type="tel" name="memberPhone" id="memberPhone" data-hj-masked mask="999-999-9999" maskChar={"_"} placeholder="___-___-____" />
                    </Col>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label sm={3} className="text-sm-right" for="memberSsn">Social Security Number</Label>
                    <Col sm={8}>
                      <InputMask className={errors.memberSsn && touched.memberSsn ? "form-control is-invalid" : "form-control"} onChange={handleChange} value={values.memberSsn} type={showSSN ? "tel" : "password"} name="memberSsn" id="memberSsn" data-hj-masked data-private mask="999-99-9999" maskChar={"_"} placeholder="___-__-____" onFocus={()=>setShowSSN(true)} onBlur={()=>setShowSSN(false)} />
                    </Col>
                  </FormGroup>
                  <br/>
                  <p><strong>Please provide the name and telephone number of the person whom the IRS may call for more information.</strong></p>
                  <FormGroup className="form-row">
                    <Label sm={3} className="text-sm-right" for="irsFirstName">First Name</Label>
                    <Col sm={8}>
                      <Input tag={Field} invalid={errors.irsFirstName && touched.irsFirstName} onChange={handleChange} value={values.irsFirstName} type="text" name="irsFirstName" id="irsFirstName" data-hj-masked />
                    </Col>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label sm={3} className="text-sm-right" for="irsLastName">Last Name</Label>
                    <Col sm={8}>
                      <Input tag={Field} invalid={errors.irsLastName && touched.irsLastName} onChange={handleChange} value={values.irsLastName} type="text" name="irsLastName" id="irsLastName" data-hj-masked />
                    </Col>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label sm={3} className="text-sm-right" for="irsPhone">Phone</Label>
                    <Col sm={8}>
                      {/* <Input tag={Field} invalid={errors.irsPhone && touched.irsPhone} onChange={handleChange} value={values.irsPhone} type="tel" name="irsPhone" id="irsPhone" data-hj-masked /> */}
                      <InputMask className={errors.irsPhone && touched.irsPhone ? "form-control is-invalid" : "form-control"} onChange={handleChange} value={values.irsPhone} type="tel" name="irsPhone" id="irsPhone" data-hj-masked mask="999-999-9999" maskChar={"_"} placeholder="___-___-____" />
                    </Col>
                  </FormGroup>

                  <FormValidationAlert
                    submitCount={submitCount}
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                    errors={errors}
                  />

                  <ProcessingStatus showProcessing={state.isProcessing && !state.apiErrors} />

                  <FormErrorModal errors={state.apiErrors} />

                  {(!form[formType].isComplete || !form[formType].orderInfoId) ?
                    <FormNav action={{prev: `/ein-application/${accountType}/${props.location.search}`}} />
                  : null }
                  
                </Form>
              )}
            </Formik>
          </Col>

          <Sidebar 
            orderDetailPids={state.orderDetailPids} 
            host={host} 
            lgr={state.formState.lgr} 
          />

        </Row>
      </Container>
    </Fragment>
  );
}

export default EINContact;
