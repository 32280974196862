import React, { Fragment, useEffect } from "react";
import { Container, Row, Col, Button } from 'reactstrap';
import {Helmet} from "react-helmet";
// import { signinRedirect } from '../services/authService';
import { useHistory } from 'react-router-dom'

function SessionExpired(props) {
  const history = useHistory()
  const { Identity } = JSON.parse(sessionStorage.getItem("appsettings"));

  const login = (e) => {
    e.preventDefault();
    props.history.push("/login")
    // signinRedirect();
  }
  
  useEffect(() => {
    sessionStorage.removeItem('fisFormState');
    sessionStorage.removeItem('orderDetails');
    sessionStorage.removeItem('productsData');
    sessionStorage.removeItem(`oidc.user:${Identity.Authority}:${Identity.ClientId}`);
  }, [Identity.Authority, Identity.ClientId, history])

  return (
    <Fragment>
      <Helmet>
        <title>Session Expired...</title>
        <link rel="canonical" href="https://www.floridaincorporationservice.com" />
      </Helmet>
      <Container>
        <Row className="justify-content-center">
          <Col lg="8" md="10" sm="12">
            <h1 className="mt-4">Sorry, your session has expired</h1>
            <p>To continue with your application please login again by clicking the button below. Or you can visit our homepage to <a href="https://www.floridaincorporationservice.com/">start a new application</a>.</p>
            <Button className="app-btn" color="success" size="lg" type="button" onClick={(e) => login(e)}>Login</Button>
            <p className="mt-4">If this doesn't work, please contact customer support at <a href="mailto:support@uceusa.com">support@uceusa.com</a>.</p>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default SessionExpired;