import React from "react";
import { withRouter } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const LogoutModal = (props) => {
  const {
    isOpen,
    toggle,
  } = props;

  const saveApplication = event => {
    // console.log("Application saved and logged out!")
    props.history.push("/logout");
  }

  return (
    <Modal centered scrollable backdrop="static" fade={false} isOpen={isOpen} toggle={toggle}>
      <ModalHeader tag="h4" toggle={toggle}><span className="text-danger">Save Application and Continue Later?</span></ModalHeader>
      <ModalBody>
        <p className="mb-1">You will need your username and password to login and continue your application later.</p>
      </ModalBody>
      <ModalFooter>
        <Button className="close-modal mr-auto" color="primary" outline onClick={toggle}>Cancel</Button>
        <Button className="close-modal" color="danger" outline onClick={saveApplication}>Yes, save and continue later</Button>
      </ModalFooter>
    </Modal>  
  );

}

export default withRouter(LogoutModal);