import React from "react";
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem
} from 'reactstrap';

const Directors = (props) => {
  const directors = props.data.directors || [];
 
  const directorsDetails = directors.map((item, index) => (
    <ListGroupItem key={index}>
      <span className="info-title">Director {index + 1}:</span>
      <span className="info-data">
        {item.person.firstName} {item.person.lastName} <br/>
        {item.person.addresses[0].street1}, {item.person.addresses[0].street2} <br/>
        {item.person.addresses[0].city}, {item.person.addresses[0].stateProvince} {item.person.addresses[0].postalCode} <br/>
        {item.person.phone}
      </span>
    </ListGroupItem>
  ))

  const editLink = `/business-management/${props.accountType}/${props.queryString}`;

  const handleEdit = event => {
    event.preventDefault();
    props.history.push(editLink);
    window.scrollTo(0, 0) // making sure that user view is top of the page
  }

  const showEdit = !(props.data.orderInfoId || props.data.isComplete);

  if (directors[0]?.person?.firstName) {
    return (
      <Card className="card-summary">
        <CardHeader tag="h5" className="mt-0">Directors {showEdit && <a href="//:0" onClick={handleEdit} className="edit ml-auto">Edit</a>}</CardHeader>
        <ListGroup flush>
          {directorsDetails}
        </ListGroup>
      </Card>
    );
  }
  else {
    return null;
  }

}

export default withRouter(Directors);

