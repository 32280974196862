import React, { useState, useEffect } from "react";
import {
  CustomInput
} from 'reactstrap';
import API from "../../services/apiService";
import { useDispatch } from 'react-redux'
import { appErrorHandler } from '../../utils/errorHandlerUtil'	

const StateDropdown = props => {
  const [businessActivity, setBusinessActivity] = useState(JSON.parse(sessionStorage.getItem("fisBusinessActivity")) || [])
  // const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!sessionStorage.getItem("fisBusinessActivity")) {
      API.getBusinessActivity().then(apiData => {
        // console.log("BusinessActivity:", apiData.data);
        setBusinessActivity(apiData.data)
        sessionStorage.setItem("fisBusinessActivity", JSON.stringify(apiData.data))
      })
      .catch(err => {
        // this sets a redux global app error
        appErrorHandler("BusinessActivityDropdown", err, dispatch)
      });
    }
  }, [dispatch])

  const orderedActivity = businessActivity.sort(function(a, b) { 
    return a.listOrder - b.listOrder;
  });
  const businessActivityOptions = orderedActivity.map((item, index) => (
    <option key={index} value={item.activity}>{item.activity}</option>
  ));

  return (
    <CustomInput {...props} data-hj-masked>
      <option value="">Select One</option>
      {businessActivityOptions}
      {/* <option value="Accommodation & Food Service">Accommodation & Food Service</option>
      <option value="Construction">Construction</option>
      <option value="Finance & Insurance">Finance & Insurance</option>
      <option value="Health Care & Social Assistance">Health Care & Social Assistance</option>
      <option value="Manufacturing">Manufacturing</option>
      <option value="Non-Profit">Non-Profit</option>
      <option value="Professional Association">Professional Association</option>
      <option value="Real Estate">Real Estate</option>
      <option value="Rental & Leasing">Rental & Leasing</option>
      <option value="Retail">Retail</option>
      <option value="Transportation & Warehousing">Transportation & Warehousing</option>
      <option value="Wholesale - Agent/Broker">Wholesale - Agent/Broker</option>
      <option value="Wholesale - Other">Wholesale - Other</option>
      <option value="Any and all lawful business">Any and all lawful business</option>
      <option value="Other">Other</option> */}
    </CustomInput>
  );
}

export default StateDropdown;