import { storeError } from '../store/actions/errorActions'	

export const formErrorHandler = (err, setState) => {
  if (err.response?.status >= 400 && err.response?.status !== 409 && !err.response?.data?.includes("https://auth.amersc.com/api/v2/users returned status 500")) {
    // if 401, use "Looks like you're session timed out or you're unauthorized to update the account. If you continue to see this please logout and login again."
    const errorState = `${err.response?.status} ${err.response?.statusText}`
    setState([errorState])
    console.error("Error:", err.response?.status, err.response?.statusText) 
  }
  // else if (err.response?.status === 409 && err.response?.statusText === "Conflict") {
  //   const errorState = `Username is already taken`
  //   setState([errorState])
  //   console.error("Error:", err.response?.status, errorState) 
  // }
  else if (err.response?.status === 409 || (err.response?.status === 500 && err.response?.data?.includes("https://auth.amersc.com/api/v2/users returned status 500"))) {
    const errorState = `Username is already taken`
    setState([errorState])
    console.error("Error:", err.response?.status, errorState) 
  }
  else { 
    if (err.response?.data?.errors) {
      setState([...err.response?.data?.errors])
      console.error("Error:", err.response?.data?.errors) 
    }
    else {
      console.error("Error:", err.toString()) 
      setState([err.toString()])
      // setState([err, "Non-API error, unclear..."])
    }
  }
}

export const appErrorHandler = (componentName, err, dispatch) => {
  if (err.response?.status > 400) { 
    dispatch(storeError({
      error: {
        state: true,
        details: `${componentName}: ${err.response?.status} ${err.response?.statusText}`,
      }        
    }))
    console.error(err.response?.status, err.response?.statusText) 
  }
  else if (componentName === "Custom Error") {
    dispatch(storeError({
      error: {
        state: true,
        details: `${componentName}: ${err.toString()}`,
      }        
    }))
  }
  else { 
    dispatch(storeError({
      error: {
        state: true,
        details: `${componentName}: ${err.response?.status} ${err.response?.data?.errors.toString()}`,
      }        
    }))
    console.error(err.response?.data?.errors) 
  }
}
