import {
  STORE_FORM,
} from './types'

export function storeApplication(formData) {
  // console.log("formData", formData)
  return {
    type: STORE_FORM,
    payload: formData
  }
}

