import React, { useEffect, Fragment } from 'react'
import { Container, Row, Col } from 'reactstrap';
import { Helmet } from "react-helmet";
import { signinSilentCallback } from '../services/authService'
import { useHistory } from 'react-router-dom'

function LoginSilent() {
  const history = useHistory()
  useEffect(() => {
    signinSilentCallback()
  }, [history])

  return (
    <Fragment>
      <Helmet>
        <title>Silently logging you in...</title>
        <link rel="canonical" href="https://www.floridaincorporationservice.com" />
      </Helmet>
      <Container>
        <Row className="justify-content-center text-center">
          <Col lg="8" md="10" sm="12">
            <h1 className="mt-4">We're silently logging you in...</h1>
            <p>You shouldn't see this page unless you directly access it.</p>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default LoginSilent;
