import React from "react";
import {
  Container,
  Row,
  Col
} from 'reactstrap';
import "./style.scss";

const ProgressStatus = ({registrationStep, ...props}) => {
  const steps = [
    // "Account",
    "Business Info",
    "Compliance",
    "Billing"
  ]

  // const [state, setState] = useState({
  //   testingState: false
  // });

  // // for checking if a test is running
  // useEffect(() => {
  //   const checkOptimize = setInterval(() => {
  //     if (window.google_optimize !== undefined && sessionStorage.getItem("runningTest")) {
  //       setState(state => ({
  //         ...state,
  //         testingState: (sessionStorage.getItem("runningTest") === "true")
  //       }));  
  //       clearInterval(checkOptimize);
  //     }
  //   }, 100);
  //   return () => clearInterval(checkOptimize);
  // }, []);
  
  // if (state.testingState === true) {
  //   // removing the first step marker
  //   steps.shift();
  //   // steps.splice(0, 1, "Business Info");
  //   // steps.splice(1, 1, "Contact Info");
  // }
  
  const theSteps = steps.map((step, index) => {
    // const registrationStep = this.state.registrationStep;
    return (
      <div key={index}
        className={(index + 1) < registrationStep ? "step completed" : (index + 1) === registrationStep ? "step current" : "step"}
      >
        <span>{step}</span>
      </div>
    )
  });

  return (
    <Container className="progress-status">
      <Row>
        <Col sm="12" className="d-flex justify-content-center text-center">
            {theSteps}
        </Col>
      </Row>
    </Container>
  );
}

export default ProgressStatus;
