import React, { Fragment, useState, useEffect } from "react";
import {
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import API from "../../services/apiService";
import { useDispatch } from 'react-redux'
import { appErrorHandler } from '../../utils/errorHandlerUtil'	

import "./style.scss";

const OrderSummary = props => {
  const initialState = {
    productsData: JSON.parse(sessionStorage.getItem("productsData")) || [],
    pids: "531,580,581,582,583,701,702,703,704,705,706,707,749",
    defaultLgr: "7ea5511f-101e-493b-8338-8c805eb95941",
    defaultHost: "floridaincorporationservice.com",
    orderDetailPids: props.orderDetailPids || [],
    orderDetails: [],
    isLoading: props.isLoading || true,
  }
  const [state, setState] = useState(initialState)
  const dispatch = useDispatch()

  useEffect(() => {
    const getProductsFromAPI = () => {
      API.getProductsPrices(state.pids, props.lgr, props.host).then(products => {
        setState(state => ({
          ...state,
          productsData: products.data,
        }));
        processProducts(products.data);
      })
      .catch(err => {
        getProductsFromAPIDefault();
        // this sets a redux global app error handler
        // appErrorHandler("OrderSummary", err, dispatch)
      });
    }
    const getProductsFromAPIDefault = () => {
      API.getProductsPrices(state.pids, state.defaultLgr, state.defaultHost).then(products => {
        setState(state => ({
          ...state,
          productsData: products.data,
        }));
        processProducts(products.data);
      })
      .catch(err => {
        // this sets a redux global app error handler
        appErrorHandler("OrderSummary", err, dispatch)
      });
    }
    const processProducts = (productsData) => {
      let orderDetails = [];
      props.orderDetailPids.forEach(pid => {
        if (state.productsData.filter(item => item.id === pid).length > 0 && state.productsData.length > 0) {
          orderDetails.push(...state.productsData.filter(item => item.id === pid));
        }
        else if (state.productsData.filter(item => item.id === pid).length === 0 && state.productsData.length > 0) {
          appErrorHandler("Custom Error", "Query PID is not recognized as valid FIS PID.", dispatch)
        }
      });
      if (productsData) {
        sessionStorage.setItem("productsData", JSON.stringify(productsData));
      }
      sessionStorage.setItem("orderDetails", JSON.stringify(orderDetails));
      setState(state => ({
        ...state,
        orderDetails: orderDetails,
        isLoading: props.isLoading || (state.productsData.length > 0 ? false : true),
      }));
    }
    if (!sessionStorage.getItem("productsData") && props.lgr !== "" && props.host !== "") {
      getProductsFromAPI();
    }
    else {
      processProducts();
    }
  }, [dispatch, props.lgr, props.host, props.orderDetailPids, state.pids, state.productsData, state.defaultLgr, state.defaultHost, props.isLoading])

  const orderItem = state.orderDetails.map((item, index) => (
    <ListGroupItem key={index}>
      <span className="item">{item.marketing_name}</span>
      <span className="price">{item.price_per_unit.display_amount}</span>
    </ListGroupItem>
  ))
  const sumAmount = items => items.reduce((a, b) => a + b.price_per_unit.amount, 0);
  const subTotal = Number(Math.round(sumAmount(state.orderDetails) * 100) / 100).toFixed(2);

  return (
    <Fragment>
      <h4 className="mt-5 ml-2 order-summary-title">Order Summary</h4>
      { state.isLoading ? 
        <ListGroup className="order-summary loading" flush>
          <ListGroupItem>
            <span className="item"><span className="animated-placeholder"></span></span>
            <span className="price"><span className="animated-placeholder"></span></span>
          </ListGroupItem>
          <ListGroupItem>
            <span className="item sub-total"><span className="animated-placeholder"></span></span>
            <span className="price"><span className="animated-placeholder"></span></span>
          </ListGroupItem>
        </ListGroup>
      : 
        <ListGroup className="order-summary" flush>
          {orderItem}
          <ListGroupItem>
            <span className="item sub-total">Sub Total</span>
            <span className="price">${subTotal}</span>
          </ListGroupItem>
        </ListGroup>
      }
      <p className="mt-4 disclaimer"><em>*State filing fees, shipping & handling and sales tax will be computed on checkout. You will have a chance to review your order before checking out.</em></p>
    </Fragment>
  );
}

export default OrderSummary;
