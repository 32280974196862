import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import {Helmet} from "react-helmet";
import {
  CompanyNames,
  BusinessActivity,
  OfficeLocation,
  PointOfContact,
  RegisteredAgent,
  Managers,
  EinApplication,
  EntityClassification,
  SubSElection,
  Signatures,
  Directors,
  Officers,
  StockShares,
  DbaOwners,
  SkeletonCards,
} from "../components/CompanySummary";

function CompletedSummary(props) {

  const { Identity } = JSON.parse(sessionStorage.getItem("appsettings"));

  const initState = {
    completedApplicationData: {}, // this will need to come from the API
    isLoading: true,
  }

  const [state, setState] = useState({
    ...initState,
  });

  useEffect(() => {
    const completedApplicationData = JSON.parse(sessionStorage.getItem("completedApplicationData"))
    const serviceType = completedApplicationData?.serviceType; // serviceType is based on: Corp = 0, LLC = 2, DBA = 3
    const accountType = serviceType === 0 ? "corp" : serviceType === 2 ? "llc" : "dba";
    const pid = serviceType === 0 ? 531 : serviceType === 2 ? 580 : 583; 
    const pkg = serviceType === 0 ? "silver" : serviceType === 2 ? "llc" : "dba";
    const lgr = completedApplicationData?.registrationVars?.lgr;
    
    setState(state => ({
      ...state,
      pid,
      pkg,
      accountType,
      lgr,
      companyData: {
        ...completedApplicationData,
      },
      isLoading: false,
    }));

    // cleaning up sessionStorage
    sessionStorage.removeItem('fisFormState');
    sessionStorage.removeItem('orderDetails');
    sessionStorage.removeItem(`oidc.user:${Identity.Authority}:${Identity.ClientId}`);

    if (!props.location.search) {
      const queryParams = `?pkg=${pkg}&scc=amsc-floridais&pid=${pid}&host=floridaincorporationservice.com&lgr=${lgr}&ref=self`;
      props.history.replace(`/completed-summary/${accountType}/${queryParams}`);
    }

  }, [Identity.Authority, Identity.ClientId, props.history, props.location.search]);
  
  const queryParams = props.location.search || `?pkg=${state.pkg}&scc=amsc-floridais&pid=${state.pid}&host=floridaincorporationservice.com&lgr=${state.lgr}&ref=self`;

  return (
    <Fragment>
      <Helmet>
      <title>Application Complete</title>
        <link rel="canonical" href="https://www.floridaincorporationservice.com" />
      </Helmet>

      {/* <ProgressStatus registrationStep={registrationStep} /> */}
      <Container>
        <Row className="justify-content-center">
          <Col lg="8" md="10" sm="12" data-hj-masked>
            <Fragment>
              <h2>Your application is complete</h2>
              { state.accountType !== "dba" ?
                <Fragment>
                  <p>You should receive your incorporation documents by email in <strong>approximately 10 business days</strong>.</p>
                  <p>If you purchased additional items, such as the corporate kit, stock certificates, or tax forms, you will receive these items in <strong>approximately 10-12 business days</strong>.</p>
                  <p>If you purchased an embosser, you will receive this by mail in <strong>approximately 2 weeks</strong>.</p>
                </Fragment>
              : 
                <Fragment>
                  <p>Thank you. Your registration is complete and has been received by Florida Incorporation Service. We will notify you within two business days regarding the status of your Fictitious Name company.</p>
                </Fragment>
              }
              <p>Below is a summary of the information you have provided us.</p>
              {!state.isLoading ? 
                <Fragment>
                  <PointOfContact data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                  <CompanyNames data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                  <BusinessActivity data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                  <OfficeLocation data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                  { state.accountType === "dba" ?
                    <DbaOwners data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                  : null }
                  { state.accountType === "llc" ?
                    <Managers data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                  :
                    <Fragment>
                      <Officers data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                      <Directors data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                      <StockShares data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                    </Fragment>
                  }
                  <RegisteredAgent data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                  { state.accountType !== "dba" ?
                    <EinApplication data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                  : null }
                  { state.accountType === "llc" ?
                    <EntityClassification data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                  : null }
                  { state.companyData.registrationVars?.addEIN && (state.companyData.subSElection || !state.companyData.subSElection) ?
                    <SubSElection data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                  : null }
                  <Signatures data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                </Fragment>
              :
                <SkeletonCards />
              }
            </Fragment>
            
          </Col>

        </Row>
      </Container>
    </Fragment>
  );
}

export default CompletedSummary;
