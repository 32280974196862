// import axios from 'axios'
import { fis } from './axiosInstance'

export function setAuthHeader(token) {
  // console.log("Added authorization header token", token)
  fis.defaults.headers.common['Authorization'] = token ? 'Bearer ' + token : ''
}

// export function setMainContentTypeHeader(contentType) {
//   // console.log("Content-Type header set")
//   axios.defaults.headers['Content-Type'] = contentType;
// }