import React, { useEffect, useRef } from 'react';
import { storeUser } from '../store/actions/authActions'
import { setAuthHeader } from './axiosHeaders';

export default function AuthProvider({ userManager: manager, store, children }) {

  let userManager = useRef();
  
  const { Identity } = JSON.parse(sessionStorage.getItem("appsettings"));

  useEffect(() => {
    userManager.current = manager

    const onUserLoaded = (user) => {
      // console.log(`user loaded: ${user}`)
      store.dispatch(storeUser(user))
    }

    const onUserUnloaded = () => {
      setAuthHeader(null)
      console.log(`user unloaded`)
    }

    const onSilentRenewError = (error) => {
      console.log(`silent renew error ${error}`)
    }

    const onAccessTokenExpiring = () => {
      console.log(`user token expiring`)
    }

    const onAccessTokenExpired = () => {
      // might need to redirect user to logout or session expired page
      console.log(`user token expired`)
      sessionStorage.removeItem(`oidc.user:${Identity.Authority}:${Identity.ClientId}`);
      // window.location.replace("/session-expired");
    }

    const onUserSignedOut = () => {
      console.log(`user signed out`)
    }

    // events for user
    userManager.current.events.addUserLoaded(onUserLoaded)
    userManager.current.events.addUserUnloaded(onUserUnloaded)
    userManager.current.events.addSilentRenewError(onSilentRenewError)
    userManager.current.events.addAccessTokenExpiring(onAccessTokenExpiring)
    userManager.current.events.addAccessTokenExpired(onAccessTokenExpired)
    userManager.current.events.addUserSignedOut(onUserSignedOut)

    // Specify how to clean up after this effect:
    return function cleanup() {
      userManager.current.events.removeUserLoaded(onUserLoaded);
      userManager.current.events.removeUserUnloaded(onUserUnloaded);
      userManager.current.events.removeSilentRenewError(onSilentRenewError)
      userManager.current.events.removeAccessTokenExpiring(onAccessTokenExpiring)
      userManager.current.events.removeAccessTokenExpired(onAccessTokenExpired)
      userManager.current.events.removeUserSignedOut(onUserSignedOut)
    };
  }, [Identity.Authority, Identity.ClientId, manager, store]);

  return (
    React.Children.only(children)
  )
}