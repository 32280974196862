import React, { useState } from "react";
import { withRouter } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
// import { signinRedirect } from '../../services/authService';
import { useSelector } from 'react-redux'
import LogoutModal from '../LogoutModal'

import "./style.scss";

import logo from "../../assets/images/florida-incorporation-service-logo.png";

const Header = (props) => {
  const [modal, setModal] = useState(false);
  const user = useSelector(state => state.auth.user)
  const toggleLogoutModal = (e) => {
    e.preventDefault();
    setModal(!modal);
  }
  const startChat = (e) => {
    e.preventDefault();
    // console.log("chat clicked");
    const olark = window.olark;
    olark('api.box.expand');
  }
  const login = (e) => {
    e.preventDefault();
    props.history.push("/login")
    // signinRedirect();
  }
  
  return (
    <header>
      <section id="header_wrap" className="wrap clearfix">
        <Container className="fluid">
          <Row>
            <Col sm="12" id="header">
              <a className="logo" href="https://www.floridaincorporationservice.com/"><img src={logo} alt="Florida Incorporation Service" /></a>
              <div id="support">
                { user && user?.profile['AspNet.Identity.SecurityStamp'] ? 
                  <a className="login logout" href="//:0" onClick={toggleLogoutModal}>Logout</a>
                : 
                  <a className="login" href="//:0" onClick={login}>Login</a>
                }
                <p>
                  <span className="phone">Questions? <strong><a href="tel:18003702942">1-800-370-2942</a></strong></span>
                  <a className="livechat" href="//:0" onClick={startChat}>Live Chat</a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <LogoutModal isOpen={modal} toggle={toggleLogoutModal} />
    </header>
  );
}

export default withRouter(Header);