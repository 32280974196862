import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import queryString from "query-string";
import {Helmet} from "react-helmet";
import { Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import API from "../services/apiService";
import { generateKeys, generatePids } from "../utils/productMapping";
import Sidebar from "../components/Sidebar";
import ProgressStatus from "../components/ProgressStatus";
import FormNav from "../components/FormNav";
import FormValidationAlert from "../components/FormValidationAlert";
import ProcessingStatus from "../components/ProcessingStatus";
import FormErrorModal from "../components/FormErrorModal";
import ApplicationCompleteAlert from "../components/ApplicationCompleteAlert";
import { formErrorHandler, appErrorHandler } from "../utils/errorHandlerUtil";
import InputMask from 'react-input-mask';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { storeApplication } from '../store/actions/formActions'	
import { accountInfoState } from "../utils/storeState";

function EINApplication(props) {
  const form = useSelector(state => state.form);
  const dispatch = useDispatch();  

  const pageTitle = "Florida Incorporation Service Registration | EIN Application",
        registrationStep = 2,
        accountType = props.match.params.accountType?.toLowerCase(),
        orderDetails = JSON.parse(sessionStorage.getItem("orderDetails")) || [],
        host = queryString.parse(props.location.search)?.host || "",
        formType = accountType === "dba" ? "dba" : "corp";

  // checks if current url is valid for the stored order data
  const { pid, lgr, pkg } = queryString.parse(props.location.search);
  if (!pid || !lgr || !host || (accountType !== "dba" ? !pkg : false)) {
    appErrorHandler("Custom Error", `Missing query string ${props.history.location.pathname}${props.history.location.search}`, dispatch)
    // props.history.replace(`/error/${accountType}/${props.location.search}`);
  }
  if (orderDetails.length && (Number(orderDetails[0].id) !== Number(pid) || orderDetails[0].lgr !== lgr?.toLowerCase())) {
    sessionStorage.removeItem('orderDetails');
    sessionStorage.removeItem('productsData');
    sessionStorage.removeItem('fisFormState');
    props.history.replace(`/account-info/${accountType}/${props.location.search}`);
  }

  const initState = {
    formState: {
      einRegistration: true,
      household: form[formType].numHouseholdEmployees || 0, // needs to be a number
      agricultural: form[formType].numAgriculturalEmployees || 0,// needs to be a number
      other: form[formType].numOtherEmployees || 0, // needs to be a number
      startOfBusinessDate: form[formType].firstDayOfBusiness ? formatDate(form[formType].firstDayOfBusiness) : formatDate(new Date()),
      startOfWagesDate: form[formType].firstWagesDate ? formatDate(form[formType].firstWagesDate) : null || "",
      presidentsFirstName: form[formType].officers?.find(person => person.title === "President")?.person.firstName || "",
      presidentsLastName: form[formType].officers?.find(person => person.title === "President")?.person.lastName || "",
      presidentsSSN: form[formType].officers?.find(person => person.title === "President")?.person.ssn || form[formType].irsContactPerson?.ssn || "",
      fisPackage: form[formType].registrationVars?.fisPackage || "",
      lgr: form[formType].registrationVars?.lgr || "",
      optionalProducts: form[formType].optionalProducts || [], // array can contain one or more of the following: SubSElection, BylawsAndMinutes, CertificateOfStatus, CorporateEmbosser, CorpSealsAndCerts, Form8832Filing, Form8832Filing, LLCSealsAndCerts, OperationalAgreement 
      optionalServices: form[formType].optionalServices || [],
    },
  }
  
  const checkedProducts = Object.keys(initState.formState).filter(key => initState.formState[key]); // generate product keys for generatePids()
  const generatedPids = generatePids([...checkedProducts, ...initState.formState.optionalProducts, ...initState.formState.optionalServices])
  const uniquePids = new Set([Number(pid), ...generatedPids]); // removing duplicate PIDs
  const productKeys = generateKeys([...uniquePids]); // object of product keys with true value

  const [state, setState] = useState({
    ...initState,
    formState: {
      ...initState.formState,
      ...productKeys,
    },
    orderDetailPids: [...uniquePids]
  });

  // const handleChange = event => {
  //   const { name, value, type, checked } = event.target;
  //   const theValue = type === 'checkbox' ? checked : value;
  //   setState({
  //     [name]: theValue
  //   });
  // }

  const handleDayChange = (selectedDay, modifiers, dayPickerInput, setFieldValue) => {
    const { name, value } = dayPickerInput.getInput();
    if (selectedDay !== undefined) {
      setFieldValue(name, formatDate(selectedDay))
    }
    else {
      setFieldValue(name, value)
    }
  }

  const handleFormSubmit = (values, actions, nextUrl) => {
    // console.log(values);
    setState(state => ({
      ...state,
      formState: {
        ...state.formState,
        ...values,
      },
      isProcessing: true,
    }));

    const goToNextPage = (apiData) => {
      sessionStorage.removeItem('fisFormState'); // deleting current formState from sessionStorage
      setState(state => ({
        ...state,
        isProcessing: false,
      }));
      // creating object that matched the redux state object to prevent removing any key/values that's not updated
      const applicationState = accountInfoState(accountType, form, apiData);
      dispatch(storeApplication(applicationState)) // sets redux store with form info
      actions.resetForm(true);
      // const nextUrl = `/entity-classification/${accountType}/${props.location.search}`;  
      const nextUrl = accountType === "llc" ? `/ein-contact/${accountType}/${props.location.search}` : `/entity-classification/${accountType}/${props.location.search}`;  
      props.history.push(nextUrl); // push user to next page after form submission or api call
      window.scrollTo(0, 0) // making sure that user view is top of the page
    }

    const cleanPerson = personObj => {
      delete personObj.id;
      delete personObj.person.id;
      delete personObj.person.sex;
      delete personObj.person.race;
      delete personObj.person.created;
      delete personObj.person.lastModified;
      delete personObj.person.addresses[0].id;
      delete personObj.person.addresses[0].created;
      delete personObj.person.addresses[0].lastModified;
      return personObj;
    }
    const accountId = form.corp.account.id; // grabbing from redux
    const companyId = form.corp.id; // grabbing from redux
    const einInfo = {
      numHouseholdEmployees: values.household,
      numAgriculturalEmployees: values.agricultural,
      numOtherEmployees: values.other,
      firstDayOfBusiness: values.startOfBusinessDate,
      firstWagesDate: values.startOfWagesDate,
      presidentSsn: values.presidentsSSN.replace("-", ""), // we need to pull this from the /ein-contact/ person if accountType = llc
    }
    let companyInfo = {};
    if (accountType !== "llc") {
      companyInfo = {
        irsContactPerson: {
          ...cleanPerson(form[formType].officers?.find(person => person.title === "President")).person,
          ssn: values.presidentsSSN.replace("-", ""),
        },
      }
    }
    API.addEINApplication(accountId, companyId, einInfo).then((companyData) => {
      // console.log("Added EIN Application:", companyData.data);
      // no need to patch the company because we have already collected the irsContact person on the /ein-contact/ page
      if (accountType !== "llc") {
        return API.patchCompany(accountId, companyId, companyInfo)
      }
      else {
        return companyData
      }
    })
    .then((companyData) => {
      // console.log("Update Company:", companyData.data);
      goToNextPage(companyData.data);
    })
    .catch(err => {
      formErrorHandler(err, setApiErrorState);
    });
  }
  
  const setApiErrorState = (err) => {
    setState(state => ({
      ...state,
      apiErrors: err,
    }));
  }

  const [showSSN, setShowSSN] = useState(true);

  const validationSchema = Yup.object().shape({
    household: Yup.number().min(0, 'Household can\'t be less than 0').required('Household is required'),
    agricultural: Yup.number().min(0, 'Agricultural can\'t be less than 0').required('Agricultural is required, put in 0 if you dont intend to have employees'),
    other: Yup.number().min(0, 'Other can\'t be less than 0').required('Other is required, put in 0 if you dont intend to have employees'),
    startOfBusinessDate: Yup.string().matches(/^(0[1-9]|1[0-2])[/](0[1-9]|[12]\d|3[01])[/](19|20)\d{2}$/, { message: 'First day in business date must match this format: MM/DD/YYYY', excludeEmptyString: true }).required('First day in business date is required'),
    // startOfWagesDate: Yup.string().matches(/^(0?[1-9]|1[0-2])[\/](0?[1-9]|[12]\d|3[01])[\/](19|20)\d{2}$/, { message: 'Date must match this format: MM/DD/YYYY', excludeEmptyString: true }).required('Date is required'),
    startOfWagesDate: Yup.string().when(['household', 'agricultural', 'other'], {
      is: (household, agricultural, other) => household > 0 || agricultural > 0 || other > 0,
      then: Yup.string().matches(/^(0[1-9]|1[0-2])[/](0[1-9]|[12]\d|3[01])[/](19|20)\d{2}$/, { message: 'First date of wages must match this format: MM/DD/YYYY', excludeEmptyString: true }).required('First date of wages is required since you will have employees within the next 12 months'),
    }),
    // startOfWagesDate: Yup.lazy(value => {
    //   if (value !== "") {
    //     return Yup.string().matches(/^(0[1-9]|1[0-2])[/](0[1-9]|[12]\d|3[01])[/](19|20)\d{2}$/, { message: 'First date of wages must match this format: MM/DD/YYYY', excludeEmptyString: true }).noRequired();
    //   }
    //   return Yup.string().notRequired();
    // }),
    presidentsSSN: Yup.string().when({
      is: () => accountType !== "llc" ? true : false,
      then: Yup.string().matches(/^\d{3}-?\d{2}-?\d{4}$/, { message: 'SSN must match this format: XXX-XX-XXXX', excludeEmptyString: true }).required('President\'s SSN is required'),
    }),
  })


  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href="https://www.floridaincorporationservice.com" />
      </Helmet>

      <ProgressStatus registrationStep={registrationStep} />
      <Container>
        <Row className="justify-content-center">
          {form[formType].isComplete || form[formType].orderInfoId ? 
            <ApplicationCompleteAlert />
          : null }          
          <Col lg="8" md="10" sm="12">
            <Formik
              enableReinitialize={true}
              initialValues={state.formState}
              validationSchema={validationSchema}
              // onChange={values => handleChange(values)}
              onSubmit={(values, actions) => handleFormSubmit(values, actions)}
            >
              {({ isValid, errors, touched, values, handleChange, setFieldValue, submitCount, isSubmitting }) => (
                <Form>
                  <h2>EIN Application</h2>
                  <p>What will be your best guess for the highest number of employees you will employ within the next 12 months (yourself being one)? If you are not sure, you may wish to use the default values indicated below.</p>
                  { accountType === "llc" ? 
                    <Fragment>
                      <FormGroup className="form-row">
                        <Label sm={3} className="text-sm-right" for="household">Household</Label>
                        <Col className="col-4 col-sm-3">
                          <Input tag={Field} invalid={errors.household && touched.household} onChange={handleChange} value={values.household} type="number" name="household" id="household" data-hj-masked />
                        </Col>
                        <small className="col-sm-6 inline-helper form-text text-muted">(e.g. childcare, gardener)</small>
                      </FormGroup>
                    </Fragment>
                  : 
                    null
                  }
                  <FormGroup className="form-row">
                    <Label sm={3} className="text-sm-right" for="agricultural">Agricultural</Label>
                    <Col className="col-4 col-sm-3">
                      <Input tag={Field} invalid={errors.agricultural && touched.agricultural} onChange={handleChange} value={values.agricultural} type="number" name="agricultural" id="agricultural" data-hj-masked />
                  </Col>
                  <small className="col-sm-6 inline-helper form-text text-muted">(e.g. Farm worker)</small>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label sm={3} className="text-sm-right" for="other">Other</Label>
                    <Col className="col-4 col-sm-3">
                      <Input tag={Field} invalid={errors.other && touched.other} onChange={handleChange} value={values.other} type="number" name="other" id="other" data-hj-masked />
                    </Col>
                    <small className="col-sm-6 inline-helper form-text text-muted">(all others)</small>
                  </FormGroup>
                  <br/>
                  <p><strong>What date do you expect will be your first day in business?</strong></p>
                  <FormGroup className="form-row">
                    <Label sm={3} className="text-sm-right">Date</Label>
                    <Col xs={7} sm={4}>
                      <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        placeholder={'MM/DD/YYYY'}
                        value={values.startOfBusinessDate}
                        inputProps={errors.startOfBusinessDate && touched.startOfBusinessDate ? {name:'startOfBusinessDate', className:'form-control is-invalid'} : {name:'startOfBusinessDate', className:'form-control'}}
                        dayPickerProps={{fromMonth: new Date()}}
                        onDayChange={(selectedDay, modifiers, dayPickerInput) => handleDayChange(selectedDay, modifiers, dayPickerInput, setFieldValue)}
                      />
                      <small className="inline-helper form-text text-muted">Note: Enter the starting date of the business.</small>
                    </Col>
                  </FormGroup>
                  <br/>
                  { accountType === "llc" ? 
                    <Fragment>
                      <p><strong>If the business has, or will have employees, enter the date on which the business began or will begin to pay wages. If the business does not plan to have employees, leave blank.</strong></p>
                    </Fragment>
                  : 
                    <Fragment>
                      <p><strong>What is the expected first date wages will be paid?</strong></p>
                    </Fragment>
                  }
                  <FormGroup className="form-row">
                    <Label sm={3} className="text-sm-right">Date</Label>
                    <Col xs={7} sm={4}>
                      <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        placeholder={'MM/DD/YYYY'}
                        value={values.startOfWagesDate}
                        inputProps={errors.startOfWagesDate && touched.startOfWagesDate ? {name:'startOfWagesDate', className:'form-control is-invalid'} : {name:'startOfWagesDate', className:'form-control'}}
                        dayPickerProps={{fromMonth: new Date()}}
                        onDayChange={(selectedDay, modifiers, dayPickerInput) => handleDayChange(selectedDay, modifiers, dayPickerInput, setFieldValue)}
                      />
                    </Col>
                  </FormGroup>

                  { accountType !== "llc" ? 
                    <Fragment>
                      <p>To file an EIN Application with the IRS, it is necessary to supply the Social Security number of the President of your new corporation. Please provide that below.</p>
                      <p className="text-danger">Please note: if you are not a US resident, please call us for special instructions at 800-370-2942 to complete this page.</p>
                      <FormGroup className="form-row">
                        <Col sm={3} className="text-right">President's Name:</Col>
                        <Col xs={7} sm={4}><strong>{values.presidentsFirstName} {values.presidentsLastName}</strong></Col>
                      </FormGroup>
                      <FormGroup className="form-row">
                        <Label sm={3} className="text-sm-right" for="presidentsSSN">President's SSN</Label>
                        <Col xs={7} sm={4}>
                          <InputMask className={errors.presidentsSSN && touched.presidentsSSN ? "form-control is-invalid" : "form-control"} onChange={handleChange} value={values.presidentsSSN} type={showSSN ? "tel" : "password"} name="presidentsSSN" id="presidentsSSN" data-hj-masked data-private mask="999-99-9999" maskChar={"_"} placeholder="___-__-____" onFocus={()=>setShowSSN(true)} onBlur={()=>setShowSSN(false)} />
                        </Col>
                      </FormGroup>
                    </Fragment>
                  : 
                    null
                  }

                  <FormValidationAlert
                    submitCount={submitCount}
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                    errors={errors}
                  />

                  <ProcessingStatus showProcessing={state.isProcessing && !state.apiErrors} />

                  <FormErrorModal errors={state.apiErrors} />

                  {(!form[formType].isComplete || !form[formType].orderInfoId) ?
                    <FormNav action={{prev: `/business-options/${accountType}/${props.location.search}`}} />
                  : null }
                  
                </Form>
              )}
            </Formik>
          </Col>

          <Sidebar 
            orderDetailPids={state.orderDetailPids} 
            host={host} 
            lgr={state.formState.lgr} 
          />

        </Row>
      </Container>
    </Fragment>
  );
}

export default EINApplication;
