import React, { useState, Fragment } from "react";

import {
  Col,
  FormGroup,
  CustomInput,
  Modal, 
  ModalHeader, 
  ModalBody,
  Label,
  Input
} from 'reactstrap';
import { displayPriceDollar } from "../../../utils/displayPrice";

const FictitiousName = (props) => {
  const pid = 583;
  const [modal, setModal] = useState(false);
  const toggle = (e) => {
    e.preventDefault();
    setModal(!modal);
  }
  const price = displayPriceDollar(pid);

  return (
    <Fragment>
      <FormGroup className="form-row action-area">
        <Col sm={12} className="ml-md-auto">
          <CustomInput onChange={props.onChange} checked={props.checked} type="checkbox" value={pid} name={props.name} id={props.id} label={<span>Fictitious Name (DBA) - ${price} <a href="//:0" onClick={toggle} className="upsell_desc_trigger">?</a></span>} inline data-hj-masked />
          <Modal centered isOpen={modal} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>Fictitious Name (DBA)</ModalHeader>
            <ModalBody>
              <p>A Fictitious name or DBA (Doing Business As) or Trade Name is a name you can register with the Division of Corporations so that you may transact business as another name besides your company name.</p>
              <p>For example, you can have a company name ABC, Inc then register a fictitious name Ray's Pizza and then do business as Ray's Pizza in advertising, letterhead, internet names and the like. So the company name remains the same and you will be able to transact business with this fictitious name.</p>
              <p className="mb-0">There are no holds on a fictitious name as it is a "nickname" or trade name of the company. So other companies can have the same fictitious name as other companies. </p>
            </ModalBody>
          </Modal>
        </Col>
      </FormGroup>
      { props.checked ? (
        <FormGroup className="form-row">
          <Col md={8}>
            <Label className="text-sm-right" for="street1">Enter Fictitious Name</Label>
            <Input tag={props.tag} invalid={props.invalid} onChange={props.onChange} value={props.value} type="text" name="fictitiousName" id="fictitiousName" data-hj-masked  />
          </Col>
          {/* <Col sm={8}>
            <Input onChange={handleInputChange} value={state.fictitiousName} type="text" name="fictitiousName" id="fictitiousName" data-hj-masked />
          </Col> */}
        </FormGroup>
      ) : null }
    </Fragment>
    
  );
}

export default FictitiousName;