import {
  STORE_ERROR,
} from './types'

export function storeError(errorData) {
  // console.log("formData", formData)
  return {
    type: STORE_ERROR,
    payload: errorData
  }
}

