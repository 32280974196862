import React from "react";
import { withRouter } from 'react-router-dom';

import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem
} from 'reactstrap';

const DbaOwners = (props) => {
  const ownedByOtherEntity = props.data.ownedByOtherEntity;
  const signatories = props.data.signatories;
  const owningEntityName = props.data.owningEntity?.owningEntityName;
  const owningEntityAddress = props.data.owningEntity?.owningEntityAddress;
  const owningEntityFRN = props.data.owningEntity?.owningEntityFRN;
  const owningEntityFEI = props.data.owningEntity?.owningEntityFEI;
  // const feiAppliedFor = props.data.owningEntity?.feiAppliedFor;
  const feiNotApplicable = props.data.owningEntity?.feiNotApplicable;
  const individualOwners = props.data.individualOwners || [];
 
  const directorsDetails = individualOwners.map((item, index) => (
    <ListGroupItem key={index}>
      <span className="info-title">Owner {index + 1}:</span>
      <span className="info-data">
        {item.person.firstName} {item.person.lastName} <br/>
        {item.person.addresses[0].street1}, {item.person.addresses[0].street2} <br/>
        {item.person.addresses[0].city}, {item.person.addresses[0].stateProvince} {item.person.addresses[0].postalCode} <br/>
        {item.person.phone}
      </span>
    </ListGroupItem>
  ))

  const editLink = `/business-ownership/${props.accountType}/${props.queryString}`;

  const handleEdit = event => {
    event.preventDefault();
    props.history.push(editLink);
    window.scrollTo(0, 0) // making sure that user view is top of the page
  }

  const showEdit = !(props.data.orderInfoId || props.data.isComplete);

  if (!ownedByOtherEntity && individualOwners[0]?.person?.firstName) {
    return (
      <Card className="card-summary">
        <CardHeader tag="h5" className="mt-0">Business Ownership {showEdit && <a href="//:0" onClick={handleEdit} className="edit ml-auto">Edit</a>}</CardHeader>
        <ListGroup flush>
          {directorsDetails}
        </ListGroup>
      </Card>
    );
  }
  else if (ownedByOtherEntity) {
    return (
      <Card className="card-summary">
        <CardHeader tag="h5" className="mt-0">Business Ownership {showEdit && <a href="//:0" onClick={handleEdit} className="edit ml-auto">Edit</a>}</CardHeader>
        <ListGroup flush>
        <ListGroupItem>
            <span className="info-title">Entity Name:</span>
            <span className="info-data">{owningEntityName}</span>
          </ListGroupItem>
          <ListGroupItem>
            <span className="info-title">Signatory:</span>
            <span className="info-data">{signatories[0]?.name}</span>
          </ListGroupItem>
          <ListGroupItem>
            <span className="info-title">Contact Info:</span>
            <span className="info-data">
              {owningEntityAddress?.street1}, {owningEntityAddress?.street2} <br/>
              {owningEntityAddress?.city}, {owningEntityAddress?.stateProvince} {owningEntityAddress?.postalCode} <br/>
              {owningEntityAddress?.phone}             
            </span>
          </ListGroupItem>
          <ListGroupItem>
            <span className="info-title">FL Document #:</span>
            <span className="info-data">{owningEntityFRN}</span>
          </ListGroupItem>
          <ListGroupItem>
            <span className="info-title">EIN:</span>
            <span className="info-data">
              {!feiNotApplicable ? owningEntityFEI : "Not Applicable" }
            </span>
          </ListGroupItem>
        </ListGroup>
      </Card>
    );
  }
  else {
    return null;
  }

}

export default withRouter(DbaOwners);

