import axios from "axios";
import { fis } from '../utils/axiosInstance'

// console.log("apiService called")
const { Services, PublicUri } = JSON.parse(sessionStorage.getItem("appsettings"));
// const sessionUser = JSON.parse(sessionStorage.getItem(`oidc.user:${Identity.Authority}:${Identity.ClientId}`));

let ASC_API_BASE_URL = `${Services.ProductMS.Uri}/${Services.ProductMS.Version}`;
let FIS_API_BASE_URL = `${Services.FloridaIncorporationServiceMS.Uri}/${Services.FloridaIncorporationServiceMS.Version}`;
let AUTH_API_BASE_URL = `${Services.IdentityServerMS.Uri}/${Services.IdentityServerMS.Version}`;

// if in local development
const REACT_APP_ENV = process.env.REACT_APP_ENV;
const NODE_ENV = process.env.NODE_ENV;
// console.log("REACT_APP_ENV:", REACT_APP_ENV)
// console.log("FIS_API_BASE_URL:", FIS_API_BASE_URL)
// console.log("NODE_ENV", NODE_ENV)
if (!REACT_APP_ENV && NODE_ENV === 'development') {
  FIS_API_BASE_URL = PublicUri + "/api/v2"; // proxied routing to the local API, see package.json for real local API route
}
else if (REACT_APP_ENV === 'staging') {
  ASC_API_BASE_URL = ASC_API_BASE_URL.replace('dlapi', 'mlapi');
  FIS_API_BASE_URL = FIS_API_BASE_URL.replace('dlapi', 'mlapi');
  AUTH_API_BASE_URL = AUTH_API_BASE_URL.replace('dlauth', 'mlauth');
}
else if (REACT_APP_ENV === 'production') {
  ASC_API_BASE_URL = ASC_API_BASE_URL.replace('dlapi', 'api');
  FIS_API_BASE_URL = FIS_API_BASE_URL.replace('dlapi', 'api');
  AUTH_API_BASE_URL = AUTH_API_BASE_URL.replace('dlauth', 'auth');
}
// console.log("FIS_API_BASE_URL:", FIS_API_BASE_URL)

export default {
  // Product API
  getProductPrice: (pid, lgr, host) => {
    return axios.get(`${ASC_API_BASE_URL}/product/${pid}/${lgr}/${host}/?includeRelated=false`)
  },
  getProductsPrices: (pids, lgr, host) => {
    return axios.get(`${ASC_API_BASE_URL}/products/${pids}/${lgr}/${host}/?includeRelated=false`)
  },

  // username availability checker
  checkUsername: (dataObj) => {
    return axios.post(`${AUTH_API_BASE_URL}/users/exists`, dataObj);
  },
  
  // Accounts
  createAccount: (accountData) => {
    return fis.post(`${FIS_API_BASE_URL}/Accounts`, accountData);
  },
  createAnonymousAccount: () => {
    return fis.post(`${FIS_API_BASE_URL}/accounts/anonymous`);
  },
  getAllAccounts: () => {
    return fis.get(`${FIS_API_BASE_URL}/Accounts`);
  },
  getAccount: (accountId) => {
    return fis.get(`${FIS_API_BASE_URL}/Accounts/${accountId}`);
  },
  updateAccount: (accountId, dataObj) => {
    return fis.put(`${FIS_API_BASE_URL}/Accounts/${accountId}`, dataObj);
  },
  patchAccount: (accountId, dataObj) => {
    const options = {
      headers: {'Content-Type': 'application/merge-patch+json'}
    };
    return fis.patch(`${FIS_API_BASE_URL}/Accounts/${accountId}`, dataObj, options);
  },

  // External Users
  createExternalAccount: (accountId, dataObj) => {
    return fis.post(`${FIS_API_BASE_URL}/accounts/${accountId}/external-user`, dataObj);
  },
  getExternalAccount: (sub) => {
    return fis.get(`${FIS_API_BASE_URL}/external-users/${sub}/account`);
  },
  
  // Companies
  createCompany: (accountId, dataObj) => {
    return fis.post(`${FIS_API_BASE_URL}/accounts/${accountId}/Companies`, dataObj);
  },
  getCompany: (accountId, companyId) => {
    return fis.get(`${FIS_API_BASE_URL}/accounts/${accountId}/Companies/${companyId}`);
  },
  updateCompany: (accountId, companyId, dataObj) => {
    return fis.put(`${FIS_API_BASE_URL}/accounts/${accountId}/Companies/${companyId}`, dataObj);
  },
  patchCompany: (accountId, companyId, dataObj) => {
    const options = {
      headers: {'Content-Type': 'application/merge-patch+json'}
    };
    return fis.patch(`${FIS_API_BASE_URL}/accounts/${accountId}/Companies/${companyId}`, dataObj, options)
  },
  setCompanyPrincipalOffice: (accountId, companyId, dataObj) => {
    return fis.post(`${FIS_API_BASE_URL}/accounts/${accountId}/Companies/${companyId}/principal-office`, dataObj);
  },
  getCompanyOptionalItems: (accountId, companyId) => {
    return fis.get(`${FIS_API_BASE_URL}/accounts/${accountId}/Companies/${companyId}/optional-items`);
  },
  setCompanyOrderInfoId: (accountId, companyId, dataObj) => {
    return fis.post(`${FIS_API_BASE_URL}/accounts/${accountId}/Companies/${companyId}/order`, dataObj);
  },
  setCompanyFillingAuthorization: (accountId, companyId, dataObj) => {
    return fis.post(`${FIS_API_BASE_URL}/accounts/${accountId}/Companies/${companyId}/filing-authorization`, dataObj);
  },
  removeCompanyFillingAuthorization: (accountId, companyId) => {
    return fis.delete(`${FIS_API_BASE_URL}/accounts/${accountId}/Companies/${companyId}/filing-authorization`);
  },

  // Company Services
  setRegisteredAgent: (accountId, companyId, dataObj) => {
    return fis.post(`${FIS_API_BASE_URL}/accounts/${accountId}/companies/${companyId}/services/registered-agent`, dataObj);
  },
  addFictitiousName: (accountId, companyId, dataObj) => {
    return fis.put(`${FIS_API_BASE_URL}/accounts/${accountId}/companies/${companyId}/services/fictitious-name`, dataObj);
  },
  removeFictitiousName: (accountId, companyId) => {
    return fis.delete(`${FIS_API_BASE_URL}/accounts/${accountId}/companies/${companyId}/services/fictitious-name`);
  },
  addEINApplication: (accountId, companyId, dataObj) => {
    return fis.post(`${FIS_API_BASE_URL}/accounts/${accountId}/companies/${companyId}/services/ein-application`, dataObj);
  },
  // below is waiting on ticket FIS-92
  addEINPerson: (accountId, companyId, dataObj) => {
    return fis.post(`${FIS_API_BASE_URL}/accounts/${accountId}/companies/${companyId}/services/ein-application/person`, dataObj);
  },
  addEIN8832Member: (accountId, companyId, dataObj) => {
    return fis.post(`${FIS_API_BASE_URL}/accounts/${accountId}/companies/${companyId}/services/ein-application/form-8832-member`, dataObj);
  },
  removeEINApplication: (accountId, companyId) => {
    return fis.delete(`${FIS_API_BASE_URL}/accounts/${accountId}/companies/${companyId}/services/ein-application`);
  },

  // DBA Applicants
  createDbaApplicant: (accountId, dataObj) => {
    return fis.post(`${FIS_API_BASE_URL}/accounts/${accountId}/dba-applicants`, dataObj);
  },
  getDbaApplicant: (accountId, dbaApplicantId) => {
    return fis.get(`${FIS_API_BASE_URL}/accounts/${accountId}/dba-applicants/${dbaApplicantId}`);
  },
  updateDbaApplicant: (accountId, dbaApplicantId, dataObj) => {
    return fis.put(`${FIS_API_BASE_URL}/accounts/${accountId}/dba-applicants/${dbaApplicantId}`, dataObj);
  },
  patchDbaApplicant: (accountId, dbaApplicantId, dataObj) => {
    const options = {
      headers: {'Content-Type': 'application/merge-patch+json'}
    };
    return fis.patch(`${FIS_API_BASE_URL}/accounts/${accountId}/dba-applicants/${dbaApplicantId}`, dataObj, options)
  },
  setDbaOrderInfoId: (accountId, dbaApplicantId, dataObj) => {
    return fis.post(`${FIS_API_BASE_URL}/accounts/${accountId}/dba-applicants/${dbaApplicantId}/order`, dataObj);
  },
  setDbaFillingAuthorization: (accountId, dbaApplicantId, dataObj) => {
    return fis.post(`${FIS_API_BASE_URL}/accounts/${accountId}/dba-applicants/${dbaApplicantId}/filing-authorization`, dataObj);
  },
  removeDbaFillingAuthorization: (accountId, dbaApplicantId) => {
    return fis.delete(`${FIS_API_BASE_URL}/accounts/${accountId}/dba-applicants/${dbaApplicantId}/filing-authorization`);
  },

  // DBA Owners
  addDBAOwningEntity: (accountId, dbaApplicantId, dataObj) => {
    return fis.post(`${FIS_API_BASE_URL}/accounts/${accountId}/dba-applicants/${dbaApplicantId}/owning-entity`, dataObj);
  },
  addDBAIndividualOwners: (accountId, dbaApplicantId, dataObj) => {
    return fis.post(`${FIS_API_BASE_URL}/accounts/${accountId}/dba-applicants/${dbaApplicantId}/individual-owners`, dataObj);
  },
  getDBAIndividualOwners: (accountId, dbaApplicantId) => {
    return fis.get(`${FIS_API_BASE_URL}/accounts/${accountId}/dba-applicants/${dbaApplicantId}/individual-owners`);
  },
  updateDBAIndividualOwners: (accountId, dbaApplicantId, dataObj) => {
    return fis.put(`${FIS_API_BASE_URL}/accounts/${accountId}/dba-applicants/${dbaApplicantId}/individual-owners`, dataObj);
  },
  removeDBAIndividualOwners: (accountId, dbaApplicantId) => {
    return fis.delete(`${FIS_API_BASE_URL}/accounts/${accountId}/dba-applicants/${dbaApplicantId}/individual-owners`);
  },
  removeDBAIndividualOwnerByID: (accountId, dbaApplicantId, ownerId) => {
    return fis.delete(`${FIS_API_BASE_URL}/accounts/${accountId}/dba-applicants/${dbaApplicantId}/individual-owners/${ownerId}`);
  },
 
  // Directors
  addDirectors: (accountId, companyId, dataObj) => {
    return fis.post(`${FIS_API_BASE_URL}/accounts/${accountId}/companies/${companyId}/directors`, dataObj);
  },
  getDirectors: (accountId, companyId) => {
    return fis.get(`${FIS_API_BASE_URL}/accounts/${accountId}/companies/${companyId}/directors`);
  },
  updateDirectors: (accountId, companyId, dataObj) => {
    return fis.put(`${FIS_API_BASE_URL}/accounts/${accountId}/companies/${companyId}/directors`, dataObj);
  },
  removeDirectors: (accountId, companyId) => {
    return fis.delete(`${FIS_API_BASE_URL}/accounts/${accountId}/companies/${companyId}/directors`);
  },
  removeDirectorById: (accountId, companyId, directorId) => {
    return fis.delete(`${FIS_API_BASE_URL}/accounts/${accountId}/companies/${companyId}/directors/${directorId}`);
  },

  // Officers
  addOfficers: (accountId, companyId, dataObj) => {
    return fis.post(`${FIS_API_BASE_URL}/accounts/${accountId}/companies/${companyId}/officers`, dataObj);
  },
  getOfficers: (accountId, companyId) => {
    return fis.get(`${FIS_API_BASE_URL}/accounts/${accountId}/companies/${companyId}/officers`);
  },
  updateOfficers: (accountId, companyId, dataObj) => {
    return fis.put(`${FIS_API_BASE_URL}/accounts/${accountId}/companies/${companyId}/officers`, dataObj);
  },
  removeOfficers: (accountId, companyId) => {
    return fis.delete(`${FIS_API_BASE_URL}/accounts/${accountId}/companies/${companyId}/officers`);
  },
  removeOfficerById: (accountId, companyId, officeerId) => {
    return fis.delete(`${FIS_API_BASE_URL}/accounts/${accountId}/companies/${companyId}/officers/${officeerId}`);
  },

  // General
  getStates: () => {
    return fis.get(`${FIS_API_BASE_URL}/states`);
  },
  getStateCounties: (stateAbbreviation) => {
    return fis.get(`${FIS_API_BASE_URL}/states/${stateAbbreviation}/counties`);
  },
  getCorporateSuffixes: (serviceType) => {
    if (serviceType) {
      return fis.get(`${FIS_API_BASE_URL}/corporate-suffixes?serviceType=${serviceType}`);
    }
    else {
      return fis.get(`${FIS_API_BASE_URL}/corporate-suffixes`);
    }
  },
  getBusinessActivity: () => {
    return fis.get(`${FIS_API_BASE_URL}/business-activities`);
  },

  // Wizard Steps
  addWizardStep: (dataObj) => {
    return fis.post(`${FIS_API_BASE_URL}/registration-steps`, dataObj);
  },
  getWizardStep: (companyId, wizardId) => {
    return fis.get(`${FIS_API_BASE_URL}/registration-steps?entityId=${companyId}&wizardId=${wizardId}`);
  },

};
