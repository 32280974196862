import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  Card,
  Table,
} from 'reactstrap';
import queryString from "query-string";
import {Helmet} from "react-helmet";
import { Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import API from "../services/apiService";
import { generatePids } from "../utils/productMapping";
import Sidebar from "../components/Sidebar";
import CountyDropdown from "../components/CountyDropdown";
import ProgressStatus from "../components/ProgressStatus";
import PersonFormModal from "../components/PersonFormModal";
import PersonList from "../components/PersonList";
import FormNav from "../components/FormNav";
import FormValidationAlert from "../components/FormValidationAlert";
import ProcessingStatus from "../components/ProcessingStatus";
import FormErrorModal from "../components/FormErrorModal";
import ApplicationCompleteAlert from "../components/ApplicationCompleteAlert";
import { formErrorHandler, appErrorHandler } from "../utils/errorHandlerUtil";
import InputMask from 'react-input-mask';
import { storeApplication } from '../store/actions/formActions'	
import { accountInfoState } from "../utils/storeState";

function DBABusinessInformation(props) {
  const form = useSelector(state => state.form);
  const dispatch = useDispatch();  

  const pageTitle = "Florida Incorporation Service Registration | Business Ownership",
        registrationStep = 2,
        accountType = props.match.params.accountType?.toLowerCase(),
        orderDetails = JSON.parse(sessionStorage.getItem("orderDetails")) || [],
        host = queryString.parse(props.location.search)?.host || "",
        formType = accountType === "dba" ? "dba" : "corp";

  // checks if current url is valid for the stored order data
  const { pid, lgr, pkg } = queryString.parse(props.location.search);
  if (!pid || !lgr || !host || (accountType !== "dba" ? !pkg : false)) {
    appErrorHandler("Custom Error", `Missing query string ${props.history.location.pathname}${props.history.location.search}`, dispatch)
    // props.history.replace(`/error/${accountType}/${props.location.search}`);
  }
  if (orderDetails.length && (Number(orderDetails[0].id) !== Number(pid) || orderDetails[0].lgr !== lgr?.toLowerCase())) {
    sessionStorage.removeItem('orderDetails');
    sessionStorage.removeItem('productsData');
    sessionStorage.removeItem('fisFormState');
    props.history.replace(`/account-info/${accountType}/${props.location.search}`);
  }

  const initState = {
    formState: {
      companyOwnedBy: form[formType].ownedByOtherEntity ? "entity" : "individuals",
      pointOfContact: form[formType].pointOfContact || {},
      individuals: form[formType].individualOwners || [],
      entityName: form[formType].owningEntity?.owningEntityName || "",
      entityFirstName: form[formType].signatories[0]?.name.trim().split(" ")[0] || "",
      entityLastName: form[formType].signatories[0]?.name.trim().split(" ").slice(1).join(" ") || "",
      entityStreet1: form[formType].owningEntity?.owningEntityAddress?.street1 || "",
      entityStreet2: form[formType].owningEntity?.owningEntityAddress?.street2 || "",
      entityCity: form[formType].owningEntity?.owningEntityAddress?.city || "",
      entityCounty: form[formType].owningEntity?.owningEntityAddress?.county || "",
      entityPostalCode: form[formType].owningEntity?.owningEntityAddress?.postalCode || "",
      entityState: form[formType].owningEntity?.owningEntityAddress?.stateProvince || "FL",
      entityCountry: form[formType].owningEntity?.owningEntityAddress?.country || "US",
      entityPhone: form[formType].owningEntity?.owningEntityAddress?.phone || "",
      entityFLDocumentNumber: form[formType].owningEntity?.owningEntityFRN || "",
      entityEIN: form[formType].owningEntity?.owningEntityFEI || "",
      einAppliedFor: form[formType].owningEntity?.feiAppliedFor,  
      einNotApplicable: form[formType].owningEntity?.feiNotApplicable,  
      fisPackage: form[formType].registrationVars?.fisPackage || "",
      lgr: form[formType].registrationVars?.lgr || "",
      optionalProducts: form[formType].optionalProducts || [], // array can contain one or more of the following: SubSElection, BylawsAndMinutes, CertificateOfStatus, CorporateEmbosser, CorpSealsAndCerts, Form8832Filing, Form8832Filing, LLCSealsAndCerts, OperationalAgreement 
      optionalServices: form[formType].optionalServices || [],
    },
    showIndividualForm: false,
  }

  const checkedProducts = Object.keys(initState.formState).filter(key => initState.formState[key]); // generate product keys for generatePids()
  const generatedPids = generatePids([...checkedProducts, ...initState.formState.optionalProducts, ...initState.formState.optionalServices])
  const uniquePids = new Set([Number(pid), ...generatedPids]); // removing duplicate PIDs
  // const productKeys = generateKeys([...uniquePids]); // object of product keys with true value

  const [state, setState] = useState({
    ...initState,
    formState: {
      ...initState.formState,
    },
    orderDetailPids: [...uniquePids]
  });
  
  const showPersonForm = (showPersonForm) => {
    // console.log(showPersonForm)
    setState(state => ({
      ...state,
      [showPersonForm]: !state[showPersonForm],
    }));
  }

  const addPerson = (personType, values, addCurrentPerson) => {
    // console.log(personType)
    // console.log(values)
    const personState = values[personType];
    let tempPersonInfo = {};
    if (!addCurrentPerson) {
      tempPersonInfo = {
        ...values.tempPersonInfo[personType],
        companyId: form.corp.id || "",
        person: {
          ...values.tempPersonInfo[personType].person,
          title: values.tempPersonInfo[personType].title,
        }
      };
    }
    else {
      tempPersonInfo = {
        companyId: form.corp.id || "",
        title: form[formType].pointOfContact.title || "",
        person: {
          title: form[formType].pointOfContact.title || "",
          firstName: form[formType].pointOfContact.firstName || "",
          lastName: form[formType].pointOfContact.lastName || "",
          emailAddress: form[formType].pointOfContact.emailAddress || "",
          addresses: [
            {
              street1: form[formType].pointOfContact.addresses[0]?.street1 || "",
              street2: form[formType].pointOfContact.addresses[0]?.street2 || "",
              city: form[formType].pointOfContact.addresses[0]?.city || "",
              postalCode:form[formType].pointOfContact.addresses[0]?.postalCode || "",
              county: form[formType].pointOfContact.addresses[0]?.county || "",
              stateProvince: form[formType].pointOfContact.addresses[0]?.stateProvince || "FL",
              country: form[formType].pointOfContact.addresses[0]?.country || "US",
            }
          ]
        },
      }
    }
    personState.push(tempPersonInfo);
    setState(state => ({
      ...state,
      formState: { 
        ...values,
        [personType]: personState,
      },
      showIndividualForm: false,
    }));
  }

  const removePerson = (index, personType, values) => {
    let personState = values[personType];
    personState.splice(index, 1);
    setState(state => ({
      ...state,
      formState: { 
        ...values,
        [personType]: personState,
      }
    }));
  }
  
  const handleEINChange = (event, setFieldValue) => {
    const { name, value, type, checked } = event.target;
    const theValue = type === 'checkbox' ? checked : value;
    if (name === "einAppliedFor" || name === "einNotApplicable") {
      setFieldValue([name], theValue);
      if (theValue) {
        setFieldValue("entityEIN", "", false);
      }
    }
    if (name === "entityEIN") {
      setFieldValue([name], theValue);
      if (theValue) {
        setFieldValue("einAppliedFor", false, false);
        setFieldValue("einNotApplicable", false, false);
      }
    }
  }

  const handleFormSubmit = (values, actions, nextUrl) => {
    // console.log(values);
    setState(state => ({
      ...state,
      formState: values,
      isProcessing: true,
    }));

    const goToNextPage = (apiData) => {
      sessionStorage.removeItem('fisFormState'); // deleting current formState from sessionStorage
      setState(state => ({
        ...state,
        isProcessing: false,
      }));
      // creating object that matched the redux state object to prevent removing any key/values that's not updated
      const applicationState = accountInfoState(accountType, form, apiData);
      dispatch(storeApplication(applicationState)) // sets redux store with form info
      // console.log("Submitted, no errors in form!")
      actions.resetForm(true);
      const nextUrl = `/last-steps/dba/${props.location.search}`;
      props.history.push(nextUrl); // push user to next page after form submission or api call
      window.scrollTo(0, 0) // making sure that user view is top of the page
    }

    const cleanPerson = items => {
      return items.map(item => {
        delete item.id;
        delete item.person.id;
        delete item.person.created;
        delete item.person.lastModified;
        delete item.person.addresses[0].id;
        delete item.person.addresses[0].created;
        delete item.person.addresses[0].lastModified;
        return item;
      })
    }
    const accountId = form[formType].account.id; // grabbing from redux
    const companyId = form[formType].id; // grabbing from redux
    const individuals = cleanPerson(values.individuals);
    // console.log("companyInfo", JSON.stringify(companyInfo, null, 2))
    // console.log("individuals", JSON.stringify(individuals, null, 2))
    // check if we need to update this to only update the DBA info when owned by entity, use only API.addDBAOwners() if owned by individuals
    const updateDbaInfo = async () => {
      if (values.companyOwnedBy === "individuals") {
        const companyInfo = {
          signatories: []    
        }
        await API.patchDbaApplicant(accountId, companyId, companyInfo);
        return API.updateDBAIndividualOwners(accountId, companyId, individuals);
      }
      else {
        const ownershipInfo = {
          owningEntityName: values.entityName,
          owningEntityAddress: {
            street1: values.entityStreet1,
            street2: values.entityStreet2,
            city: values.entityCity,
            postalCode: values.entityPostalCode,
            county: values.entityCounty,
            stateProvince: values.entityState,
            country: values.entityCountry,
            phone: values.entityPhone,
          },
          owningEntityFRN: values.entityFLDocumentNumber,
          owningEntityFEI: values.entityEIN,
          feiAppliedFor: values.einAppliedFor,
          feiNotApplicable: values.einNotApplicable,
        }
        const companyInfo = {
          signatories: [
            {
              dbaApplicantId: companyId,
              name: `${values.entityFirstName} ${values.entityLastName}`,
              phone: values.entityPhone,
            }
          ]    
        }
        // await API.removeDBAIndividualOwners(accountId, companyId);    
        await API.patchDbaApplicant(accountId, companyId, companyInfo);
        return API.addDBAOwningEntity(accountId, companyId, ownershipInfo);
      }
    }

    updateDbaInfo().then((companyData) => {
      // console.log(companyData)
      // if (values.companyOwnedBy === "individuals") {
      //   console.log("Added Owners:", companyData.data);
      // }
      // else {
      //   console.log("Updated DBA:", companyData.data);
      // }
      goToNextPage(companyData.data);
    })
    .catch(err => {
      // console.log(err)
      formErrorHandler(err, setApiErrorState);
    });
  }
  
  const setApiErrorState = (err) => {
    setState(state => ({
      ...state,
      apiErrors: err,
    }));
  }

  const validationSchema = Yup.object().shape({
    companyOwnedBy: Yup.string(),
    individuals: Yup.array().when('companyOwnedBy', {
      is: (companyOwnedBy) => companyOwnedBy === 'individuals',
      then: Yup.array().min(1, 'Please add at least one individual owner or select "Another Entity" and enter the owner information'),
    }),
    entityName: Yup.string().when('companyOwnedBy', {
      is: (companyOwnedBy) => companyOwnedBy === 'entity',
      then: Yup.string().min(2, 'Entity name is too Short!').max(255, 'Entity name is too Long!').required('Entity name is required'),
    }),
    entityFirstName: Yup.string().when('companyOwnedBy', {
      is: (companyOwnedBy) => companyOwnedBy === 'entity',
      then: Yup.string().min(2, 'First name is too Short!').max(255, 'First name is too Long!').required('First name is required'),
    }),
    entityLastName: Yup.string().when('companyOwnedBy', {
      is: (companyOwnedBy) => companyOwnedBy === 'entity',
      then: Yup.string().min(2, 'Last name is too Short!').max(255, 'Last name is too Long!').required('Last name is required'),
    }),
    entityStreet1: Yup.string().when('companyOwnedBy', {
      is: (companyOwnedBy) => companyOwnedBy === 'entity',
      then: Yup.string().min(2, 'Address is  too Short!').max(255, 'Address is too Long!').required('Address is required'),
    }),
    entityStreet2: Yup.string().when('companyOwnedBy', {
      is: (companyOwnedBy) => companyOwnedBy === 'entity',
      then: Yup.string().min(2, 'Suite/Apt # is too Short!').max(255, 'Suite/Apt # is too Long!'),
    }),
    entityCity: Yup.string().when('companyOwnedBy', {
      is: (companyOwnedBy) => companyOwnedBy === 'entity',
      then: Yup.string().min(2, 'City is too Short!').max(255, 'City is too Long!').required('City is required'),
    }),
    entityCounty: Yup.string().when('companyOwnedBy', {
      is: (companyOwnedBy) => companyOwnedBy === 'entity',
      then: Yup.string().min(2, 'County is too Short!').max(255, 'Position is too Long!').required('County is required'),
    }),
    entityState: Yup.string().when('companyOwnedBy', {
      is: (companyOwnedBy) => companyOwnedBy === 'entity',
      then: Yup.string().min(2, 'State is too Short!').max(255, 'State is too Long!').required('State is required'),
    }),
    entityPostalCode: Yup.string().when('companyOwnedBy', {
      is: (companyOwnedBy) => companyOwnedBy === 'entity',
      then: Yup.string().matches(/^\d{5}(?:[-\s]\d{4})?$/, { message: 'Zip code must match this format: XXXXX or XXXXX-XXXX', excludeEmptyString: true }).required('Zip code is required'),
    }),
    entityPhone: Yup.string().when('companyOwnedBy', {
      is: (companyOwnedBy) => companyOwnedBy === 'entity',
      then: Yup.string().matches(/^[1-9]\d{2}-\d{3}-\d{4}$/, { message: 'Phone must match this format: XXX-XXX-XXXX', excludeEmptyString: true }).required('Phone number is required'),
    }),
    entityFLDocumentNumber: Yup.string().when('companyOwnedBy', {
      is: (companyOwnedBy) => companyOwnedBy === 'entity',
      then: Yup.string().matches(/^([a-zA-Z]{1}\d{5}|\d{6}|[a-zA-Z]{1}\d{11})$/, { message: 'Entity FL Document # must match one of these formats: X00000 or 000000 or X00000000000', excludeEmptyString: true }).required('FL Document # is required'),
    }),
    einAppliedFor: Yup.boolean(),  
    einNotApplicable: Yup.boolean(),  
    entityEIN: Yup.string().when(['companyOwnedBy', 'einAppliedFor', 'einNotApplicable'], {
      is: (companyOwnedBy, einAppliedFor, einNotApplicable) => companyOwnedBy === 'entity' && (!einAppliedFor && !einNotApplicable),
      then: Yup.string().matches(/^(0[1-9]|[1-9]\d)-\d{7}$/, { message: 'Entity EIN must match this format: XX-XXXXXXX', excludeEmptyString: true }).required('Please supply the EIN of the owning entity. If the entity\'s EIN has been applied for, or an EIN is not applicable, please check the appropriate box.'),
    }),

  })
    
  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href="https://www.floridaincorporationservice.com" />
      </Helmet>

      <ProgressStatus registrationStep={registrationStep} />

      <Container>
        <Row className="justify-content-md-center">
          {form[formType].isComplete || form[formType].orderInfoId ? 
            <ApplicationCompleteAlert />
          : null }          
          <Col lg="8" md="10" sm="12">
            <Formik
              enableReinitialize={true}
              initialValues={state.formState}
              validationSchema={validationSchema}
              // onChange={values => handleChange(values)}
              onSubmit={(values, actions) => handleFormSubmit(values, actions)}
            >
              {({ isValid, errors, touched, values, handleChange, submitCount, isSubmitting, validate, setFieldValue }) => (
                <Form>
                  <h2>Business Ownership</h2>
                  <p><strong>Will this Fictitious Name company be owned by an individual(s) or by a corporation, LLC, PA, or other entity?</strong></p>
                  <FormGroup className="form-row">
                    <Col sm={12} className="ml-md-auto">
                      <CustomInput onChange={handleChange} checked={values.companyOwnedBy === "individuals"} className="mb-2" type="radio" name="companyOwnedBy" value="individuals" id="companyIndividual" label="Individual(s)" inline data-hj-masked />
                      <CustomInput onChange={handleChange} checked={values.companyOwnedBy === "entity"} className="mb-2" type="radio" name="companyOwnedBy" value="entity" id="companyEntity" label="Another Entity (Corporation, LLC, etc.)" inline data-hj-masked />
                    </Col>
                  </FormGroup>

                  {
                    values.companyOwnedBy === "individuals" ? (
                      <Fragment>
                        <h4>Individual Owner Information</h4>
                        <p><strong>Please complete the form below for each individual owner.</strong> After you click the "Add Individual" button, you will be shown a summary screen of all owners currently specified.</p>
                        {values.individuals.length === 0 ? 
                          <Fragment>
                            <p className="mb-2"><strong>We show your information as:</strong></p>
                            <Card className="person-list mb-3">
                              <Table className="mb-0" size="sm" responsive>
                                <thead className="thead-light">
                                  <tr>
                                    <th>Name</th>
                                    <th>Address</th>
                                    <th>Email</th>
                                  </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <td><strong>{values.pointOfContact.firstName} {values.pointOfContact.lastName}</strong></td>
                                  <td>{values.pointOfContact.addresses[0].street1}, {values.pointOfContact.addresses[0].street2 ? `${values.pointOfContact.addresses[0].street2},` : null} {values.pointOfContact.addresses[0].city}, {values.pointOfContact.addresses[0].stateProvince} {values.pointOfContact.addresses[0].postalCode}</td>
                                  <td>{values.pointOfContact.emailAddress}</td>
                                </tr>
                                </tbody>
                              </Table>
                            </Card>
                            <p className="mb-3">
                              <strong className="mr-3 mb-2 d-inline-block">Are you one of the owners of the company?</strong><br/>
                              <span className="d-inline-block">
                                <Button className="pl-4 pr-4 mr-2 font-weight-bolder" color="success" outline type="button" onClick={() => addPerson("individuals", values, true)}>Yes</Button>
                                <Button className="pl-4 pr-4 font-weight-bolder" color="success" outline type="button" onClick={() => showPersonForm("showIndividualForm")}>No</Button>
                              </span>
                            </p>
                          </Fragment>
                        : 
                          <p>The current Individual Owners are listed below. Please review this list for accuracy before continuing. You can remove any existing Individual Owner by clicking the "<strong className="text-danger">x</strong>" icon to the right of that Individual Owners information.</p>
                        }

                        <PersonList
                          values={values}
                          removePerson={removePerson}
                          personsData={state.formState.individuals}
                          personType="Individual"
                          personListFor="individuals" 
                          showPersonForm={() => showPersonForm("showIndividualForm")}
                        />
                        <PersonFormModal 
                          errors={errors} 
                          touched={touched} 
                          values={values} 
                          handleChange={handleChange}
                          validate={validate}
                          personType="Individual" 
                          personFormFor="individuals" 
                          fieldPrefix="individual" 
                          withEmail={true}
                          withCounty={true}
                          addPerson={addPerson}
                          showPersonForm={state.showIndividualForm}
                          togglePersonForm={() => showPersonForm("showIndividualForm")}
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <h4>Business Entity Information</h4>
                        <p>Please enter the name of the business entity. Owners that are business entities must be registered and active with the Division of Corporations. These can be corporations, limited liability companies, limited partnerships, general partnerships, or fictitious name registrations. Please enter the business entity name exactly the way it was filed with the Division of Corporations. It is critical to register the name correctly, as failure to do so may keep the registration from being accepted.</p>
                        <p>The entity may need to have an existing EIN number and be registered with the Florida Division of Corporations in order to use this option. Entities exempt from registering with the State must provide documentation supporting the exemption.</p>
                        <p>Only one signature is required and a daytime phone number is preferred.</p>

                        <FormGroup className="form-row">
                          <Label sm={3} className="text-sm-right" for="entityName">Entity Name</Label>
                          <Col sm={8}>
                            <Input tag={Field} invalid={errors.entityName && touched.entityName} onChange={handleChange} value={values.entityName} type="text" name="entityName" id="entityName" data-hj-masked />
                          </Col>
                        </FormGroup>
                        <FormGroup className="form-row">
                          <Label sm={3} className="text-sm-right" for="entityFirstName">First Name</Label>
                          <Col sm={8}>
                            <Input tag={Field} invalid={errors.entityFirstName && touched.entityFirstName} onChange={handleChange} value={values.entityFirstName} type="text" name="entityFirstName" id="entityFirstName" data-hj-masked />
                          </Col>
                        </FormGroup>
                        <FormGroup className="form-row">
                          <Label sm={3} className="text-sm-right" for="entityLastName">Last Name</Label>
                          <Col sm={8}>
                            <Input tag={Field} invalid={errors.entityLastName && touched.entityLastName} onChange={handleChange} value={values.entityLastName} type="text" name="entityLastName" id="entityLastName" data-hj-masked />
                          </Col>
                        </FormGroup>
                        <FormGroup className="form-row">
                          <Label sm={3} className="text-sm-right" for="entityStreet1">Address</Label>
                          <Col sm={8}>
                            <Input tag={Field} invalid={errors.entityStreet1 && touched.entityStreet1} onChange={handleChange} value={values.entityStreet1} type="text" name="entityStreet1" id="entityStreet1" data-hj-masked />
                          </Col>
                        </FormGroup>
                        <FormGroup className="form-row">
                          <Label sm={3} className="text-sm-right" for="entityStreet2">Suite. Apt. #, etc.</Label>
                          <Col sm={8}>
                            <Input tag={Field} invalid={errors.entityStreet1 && touched.entityStreet1} onChange={handleChange} value={state.entityStreet2} type="text" name="entityStreet2" id="entityStreet2" data-hj-masked />
                          </Col>
                        </FormGroup>
                        <FormGroup className="form-row">
                          <Label sm={3} className="text-sm-right" for="entityCity">City</Label>
                          <Col sm={8}>
                            <Input tag={Field} invalid={errors.entityCity && touched.entityCity} onChange={handleChange} value={values.entityCity} type="text" name="entityCity" id="entityCity" data-hj-masked />
                          </Col>
                        </FormGroup>
                        <FormGroup className="form-row">
                          <Label sm={3} className="text-sm-right" for="entityCounty">County</Label>
                          <Col xs={9} sm={5} lg={5}>
                            <CountyDropdown invalid={errors.entityCounty && touched.entityCounty} onChange={handleChange} value={values.entityCounty} name="entityCounty" id="entityCounty" data-hj-masked />
                          </Col>
                          <Col xs={3} sm={4} lg={3}>
                            <div className="mt-2"><strong>, {values.entityState}</strong></div>
                          </Col>
                        </FormGroup>
                        <FormGroup className="form-row">
                          <Label sm={3} className="text-sm-right" for="entityPostalCode">Zip Code</Label>
                          <Col sm={8}>
                            <Input tag={Field} invalid={errors.entityPostalCode && touched.entityPostalCode} onChange={handleChange} value={values.entityPostalCode} type="text" name="entityPostalCode" id="entityPostalCode" data-hj-masked />
                          </Col>
                        </FormGroup>
                        <FormGroup className="form-row">
                          <Label sm={3} className="text-sm-right" for="entityPhone">Phone</Label>
                          <Col sm={8}>
                            <InputMask className={errors.entityPhone && touched.entityPhone ? "form-control is-invalid" : "form-control"} onChange={handleChange} value={values.entityPhone} type="tel" name="entityPhone" id="entityPhone" data-hj-masked mask="999-999-9999" maskChar={"_"} placeholder="___-___-____" />
                          </Col>
                        </FormGroup>
                        <FormGroup className="form-row">
                          <Label sm={3} className="text-sm-right" for="entityFLDocumnentNumber">Entity FL Document #</Label>
                          <Col sm={8}>
                            <Input tag={Field} invalid={errors.entityFLDocumentNumber && touched.entityFLDocumentNumber} onChange={handleChange} value={values.entityFLDocumentNumber.toUpperCase()} type="text" name="entityFLDocumentNumber" id="entityFLDocumentNumber" data-hj-masked />
                          </Col>
                        </FormGroup>
                        <FormGroup className="form-row">
                          <Label sm={3} className="text-sm-right" for="entityEIN">Entity EIN</Label>
                          <Col sm={8}>
                            <InputMask className={errors.entityEIN && touched.entityEIN ? "form-control is-invalid" : "form-control"} onChange={event => {handleEINChange(event, setFieldValue)}} value={values.entityEIN} type="tel" name="entityEIN" id="entityEIN" data-hj-masked mask="99-9999999" maskChar={"_"} placeholder="__-_______" />
                          </Col>
                          <Label sm={12} className="offset-sm-3 mt-2">Don't have a EIN? Tell us why.</Label>
                          <Col sm={4} className="offset-sm-3">
                            <CustomInput invalid={errors.einAppliedFor && touched.einAppliedFor} onChange={event => {handleEINChange(event, setFieldValue)}} checked={values.einAppliedFor === true} className="mt-1 mt-sm-1" inline type="checkbox" name="einAppliedFor" id="einAppliedFor" label="Has been applied for" />
                          </Col>
                          <Col sm={4}>
                            <CustomInput invalid={errors.einNotApplicable && touched.einNotApplicable} onChange={event => {handleEINChange(event, setFieldValue)}} checked={values.einNotApplicable === true} className="mt-2 mt-sm-1" inline type="checkbox" name="einNotApplicable" id="einNotApplicable" label="Not applicable" />
                          </Col>
                        </FormGroup>

                      </Fragment>
                    )
                  }

                  <FormValidationAlert
                    submitCount={submitCount}
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                    errors={errors}
                  />

                  <ProcessingStatus showProcessing={state.isProcessing && !state.apiErrors} />

                  <FormErrorModal errors={state.apiErrors} />

                  {(!form[formType].isComplete || !form[formType].orderInfoId) ?
                    <FormNav action={{prev: `/contact-info/dba/${props.location.search}`}} />
                  : null }
                  
                </Form>
              )}
            </Formik>
          </Col>

          <Sidebar 
            orderDetailPids={state.orderDetailPids} 
            host={host} 
            lgr={state.formState.lgr} 
          />

        </Row>
      </Container>
    </Fragment>
  );
}

export default DBABusinessInformation;
