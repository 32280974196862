import React from "react";
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem
} from 'reactstrap';

const CompanyNames = (props) => {
  const name = props.data.name || props.data.companyName; 
  const nameCandidates = props.data.nameCandidates || []; 
  const fictitiousName = props.data.fictitiousName;

  const otherNameCandidates = nameCandidates.filter(item => item.choice !== 1).map((item, index) => {
    const choice = index + 2
    return <ListGroupItem key={index}>
      <span className="info-title">Choice #{choice}:</span>
      <span className="info-data">{item.name} {item.suffix}</span>
    </ListGroupItem>
  })

  const editLink = `/business-info/${props.accountType}/${props.queryString}`;

  const handleEdit = event => {
    event.preventDefault();
    props.history.push(editLink);
    window.scrollTo(0, 0) // making sure that user view is top of the page
  }
  const showEdit = !(props.data.orderInfoId || props.data.isComplete);

  if (name || nameCandidates.length > 0 || fictitiousName) {
    return (
      <Card className="card-summary">
        <CardHeader tag="h5" className="mt-0">{props.data.companyName ? "Company Information:" : "Company Names"} {showEdit && <a href="//:0" onClick={handleEdit} className="edit ml-auto">Edit</a>}</CardHeader>
        <ListGroup flush>
        <ListGroupItem>
            <span className="info-title">{props.data.companyName ? "DBA Name:" : "Choice #1:"}</span>
            <span className="info-data">{props.data.companyName ? props.data.companyName : `${name?.name} ${name?.suffix}`}</span>
          </ListGroupItem>
          {props.data.companyName ? 
            <ListGroupItem>
              <span className="info-title">Counties:</span>
              <span className="info-data">{props.data.counties}</span>
            </ListGroupItem>
          : null}
          {nameCandidates.length > 0 ? otherNameCandidates : null}
          { fictitiousName ? 
            <ListGroupItem>
              <span className="info-title">Fictitious Name:</span>
              <span className="info-data">{fictitiousName}</span>
            </ListGroupItem>
          : null }
        </ListGroup>
      </Card>
    );
  }
  else {
    return null;
  }

}

export default withRouter(CompanyNames);
