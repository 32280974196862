import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const FormValidationAlert = (props) => {
  const {
    errors,
    submitCount,
    isSubmitting,
    isValid,
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const effect = () => {
    if (submitCount > 0 && !isSubmitting && !isValid) {
      // Object.values(errors).forEach(item => console.log(item))
      setModal(true);
    }
  };

  useEffect(effect, [submitCount, isSubmitting]);

  function flatObject(input) {
    function flat(res, key, val, pre = '') {
      const prefix = [pre, key].filter(v => v).join('.');
      return typeof val === 'object'
        ? Object.keys(val).reduce((prev, curr) => flat(prev, curr, val[curr], prefix), res)
        : Object.assign(res, { [prefix]: val});
    }
    return Object.keys(input).reduce((prev, curr) => flat(prev, curr, input[curr]), {});
  }

  const errorList = Object.values(flatObject(errors)).map((item, index) => (
    <li key={index}>{item}</li>
  ))

  return (
    <Modal centered scrollable fade={false} backdrop={"static"} isOpen={modal} toggle={toggle} className="form-validation-error">
      <ModalHeader tag="h4" toggle={toggle}>Oops, please fix the following issues:</ModalHeader>
      <ModalBody>
        <ol className="error-list">{errorList}</ol>
      </ModalBody>
      <ModalFooter>
        <Button className="close-modal" color="primary" onClick={toggle}>OK</Button>
      </ModalFooter>
    </Modal>
  );

}

export default FormValidationAlert;