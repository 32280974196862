import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Alert,
} from 'reactstrap';
import queryString from "query-string";
import {Helmet} from "react-helmet";
import { Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import API from "../services/apiService";
import { generateKeys, generatePids } from "../utils/productMapping";
import Sidebar from "../components/Sidebar";
import ProgressStatus from "../components/ProgressStatus";
import FormNav from "../components/FormNav";
import FormValidationAlert from "../components/FormValidationAlert";
import ProcessingStatus from "../components/ProcessingStatus";
import FormErrorModal from "../components/FormErrorModal";
import ApplicationCompleteAlert from "../components/ApplicationCompleteAlert";
import InputMask from 'react-input-mask';
import { formErrorHandler, appErrorHandler } from "../utils/errorHandlerUtil";
import { storeApplication } from '../store/actions/formActions'	
import { accountInfoState } from "../utils/storeState";

function EntityClassification(props) {
  const form = useSelector(state => state.form);
  const dispatch = useDispatch();  

  const pageTitle = "Florida Incorporation Service Registration | Entity Classification",
        registrationStep = 2,
        accountType = props.match.params.accountType?.toLowerCase(),
        orderDetails = JSON.parse(sessionStorage.getItem("orderDetails")) || [],
        host = queryString.parse(props.location.search)?.host || "",
        formType = accountType === "dba" ? "dba" : "corp";

  // checks if current url is valid for the stored order data
  const { pid, lgr, pkg } = queryString.parse(props.location.search);
  if (!pid || !lgr || !host || (accountType !== "dba" ? !pkg : false)) {
    appErrorHandler("Custom Error", `Missing query string ${props.history.location.pathname}${props.history.location.search}`, dispatch)
    // props.history.replace(`/error/${accountType}/${props.location.search}`);
  }
  if (orderDetails.length && (Number(orderDetails[0].id) !== Number(pid) || orderDetails[0].lgr !== lgr?.toLowerCase())) {
    sessionStorage.removeItem('orderDetails');
    sessionStorage.removeItem('productsData');
    sessionStorage.removeItem('fisFormState');
    props.history.replace(`/account-info/${accountType}/${props.location.search}`);
  }

  const initState = {
    formState: {
      entityClassification: form[formType].llcEntityCode || "",
      einDirectorId: form[formType].einDirectorId ? form[formType].einDirectorId : form[formType].llcManagementCode === 1 ? form[formType].directors[0]?.id : null,
      subSElection: form[formType].subSElection || false,
      memberFirstName: form[formType].form8832Member?.firstName || "",
      memberLastName: form[formType].form8832Member?.lastName || "",
      memberSsn: form[formType].form8832Member?.ssn || "",
      fisPackage: form[formType].registrationVars?.fisPackage || "",
      lgr: form[formType].registrationVars?.lgr || "",
      optionalProducts: form[formType].optionalProducts || [], // array can contain one or more of the following: SubSElection, BylawsAndMinutes, CertificateOfStatus, CorporateEmbosser, CorpSealsAndCerts, Form8832Filing, Form8832Filing, LLCSealsAndCerts, OperationalAgreement 
      optionalServices: form[formType].optionalServices || [],
    },
    companyManageBy: form[formType].llcManagementCode === 1 ? "members" : "managers", // for LLC, will need to be based on previous elections and API data
    personsData: form[formType].directors || [],  // for LLC, will need to be based on previous elections and API data
  }

  const checkedProducts = Object.keys(initState.formState).filter(key => initState.formState[key]); // generate product keys for generatePids()
  const generatedPids = generatePids([...checkedProducts, ...initState.formState.optionalProducts, ...initState.formState.optionalServices])
  const uniquePids = new Set([Number(pid), ...generatedPids]); // removing duplicate PIDs
  const productKeys = generateKeys([...uniquePids]); // object of product keys with true value

  const [state, setState] = useState({
    ...initState,
    formState: {
      ...initState.formState,
      ...productKeys,
    },
    orderDetailPids: [...uniquePids],
  });

  const handleSubSElectionChange = (event, setFieldValue) => {
    const { name, value, type, checked } = event.target;
    const theValue = type === 'checkbox' ? checked : value;
    setFieldValue([name], theValue);
    let orderDetailPids = state.orderDetailPids;
    if (theValue) {
      orderDetailPids.push(Number(value));
    }
    else {
      orderDetailPids = orderDetailPids.filter(item => item !== Number(value))
    }
    orderDetailPids = new Set([...orderDetailPids]); // removing duplicate PIDs
    setState(state => ({
      ...state,
      orderDetailPids: [...orderDetailPids]
    }));
  }

  const handleFormSubmit = (values, actions, nextUrl) => {
    // console.log(values);
    setState(state => ({
      ...state,
      formState: {
        ...state.formState,
        ...values,
      },
      isProcessing: true,
    }));

    const goToNextPage = (apiData) => {
      sessionStorage.removeItem('fisFormState'); // deleting current formState from sessionStorage
      setState(state => ({
        ...state,
        isProcessing: false,
      }));
      // creating object that matched the redux state object to prevent removing any key/values that's not updated
      const applicationState = accountInfoState(accountType, form, apiData);
      dispatch(storeApplication(applicationState)) // sets redux store with form info
      // console.log("Submitted, no errors in form!")
      actions.resetForm(true);
      const nextUrl = `/last-steps/${accountType}/${props.location.search}`;  
      props.history.push(nextUrl); // push user to next page after form submission or api call
      window.scrollTo(0, 0) // making sure that user view is top of the page
    }

    const checkedProductsArray = Object.keys(values).filter(key => values[key]); // this grabs the keys that will need to be passed into the generatePids() function
    const optionalServicePids = [583, 701, 702];
    const optionalProducts = generatePids(checkedProductsArray).filter(pid => !optionalServicePids.includes(pid)); // filtering away optionalServices
    // console.log("optionalProducts", optionalProducts)

    const accountId = form.corp.account.id; // grabbing from redux
    const companyId = form.corp.id; // grabbing from redux
    const companyInfo = {
      subSElection: accountType === "llc" ? (values.entityClassification === "CC" || values.entityClassification === "SC") && values.subSElection : values.subSElection, 
      form8832DirectorId: state.formState.einDirectorId,
      llcEntityCode: values.entityClassification,
      // llcEntityString: values.entityClassification,
      registrationVars: {
        // ...form[formType].registrationVars,
        willFile8832: values.entityClassification === "CC" || values.entityClassification === "SC" 
      },
      optionalProducts: (accountType !== "llc" || ((values.entityClassification === "CC" || values.entityClassification === "SC") && values.subSElection)) ? optionalProducts : optionalProducts.filter(item => item !== 703),
    }
    const ein8832MemberInfo = {
      firstName: values.memberFirstName,
      lastName: values.memberLastName,
      ssn: values.memberSsn,
    }

    // console.log("values.subSElection", values.subSElection)
    // console.log("companyInfo", JSON.stringify(companyInfo, null, 2))
    API.patchCompany(accountId, companyId, companyInfo).then((companyData) => {
      // console.log("Updated Company:", companyData.data);
      if (accountType === "llc" && state.companyManageBy === "managers" && values.entityClassification !== "NO") {
        return API.addEIN8832Member(accountId, companyId, ein8832MemberInfo)
      }
      else {
        return companyData
      }
    })
    .then((companyData) => {
      // if (accountType === "llc" && state.companyManageBy === "managers" && values.entityClassification !== "NO") {
      //   console.log("Added EIN 8832 Member:", companyData.data);
      // }
      goToNextPage(companyData.data);
    })
    .catch(err => {
      formErrorHandler(err, setApiErrorState);
    });
  }
  
  const setApiErrorState = (err) => {
    setState(state => ({
      ...state,
      apiErrors: err,
    }));
  }

  const [showSSN, setShowSSN] = useState(true);

  const validationSchema = Yup.object().shape({
    entityClassification: Yup.string().when({
      is: () => accountType === "llc",
      then: Yup.string().required('Please select an entity classification'),
    }),
    memberFirstName: Yup.string().when('entityClassification', {
      is: (entityClassification) => accountType === "llc" && state.companyManageBy === "managers" && entityClassification !== "NO" ? true : false,
      then: Yup.string().min(2, 'Member first name is  too Short!').max(255, 'Member first name is too Long!').required('Member first name is required'),
    }),
    memberLastName: Yup.string().when('entityClassification', {
      is: (entityClassification) => accountType === "llc" && state.companyManageBy === "managers" && entityClassification !== "NO" ? true : false,
      then: Yup.string().min(2, 'Member last name is  too Short!').max(255, 'Member last name is too Long!').required('Member last name is required'),
    }),
    memberSsn: Yup.string().when('entityClassification', {
      is: (entityClassification) => accountType === "llc" && state.companyManageBy === "managers" && entityClassification !== "NO" ? true : false,
      then: Yup.string().matches(/^\d{3}-?\d{2}-?\d{4}$/, { message: 'SSN must match this format: XXX-XX-XXXX', excludeEmptyString: true }).required('Member\'s SSN is required'),
    }),
  })

  const personsData = state.personsData;
  const personDetails = personsData.map((item, index) => (
    <li key={index} ><strong>{item.person.firstName} {item.person.lastName}</strong></li>
  ))

  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href="https://www.floridaincorporationservice.com" />
      </Helmet>

      <ProgressStatus registrationStep={registrationStep} />

      <Container>
        <Row className="justify-content-center">
          {form[formType].isComplete || form[formType].orderInfoId ? 
            <ApplicationCompleteAlert />
          : null }          
          <Col lg="8" md="10" sm="12">
            <Formik
              enableReinitialize={true}
              initialValues={state.formState}
              validationSchema={validationSchema}
              // onChange={values => handleChange(values)}
              onSubmit={(values, actions) => handleFormSubmit(values, actions)}
            >
              {({ isValid, errors, touched, values, handleChange, setFieldValue, submitCount, isSubmitting }) => (
                <Form>

                  <h2>Entity Classification</h2>
                  { accountType === "llc" ? (
                      <Fragment>
                        <p>The IRS allows an LLC to choose to be taxed as a C-Corporation or S-Corporation, rather than the default classifications of sole proprietorship (one member) or partnership (2 or more members). This election is done on the IRS form 8832. We offer this as an option for only <strong>$39</strong>.</p>

                        {
                          state.companyManageBy === "members" ? (
                            <Fragment>
                              <p><strong>We show your LLC as having the following members (owners):</strong></p>
                              <ol className="person-details">
                                {personDetails}
                              </ol>
                              { state.personsData.length > 1 ? 
                                <p>This LLC has more than one member. Please make a selection in the area below or indicate if you do not wish to file at this time. (NOTE: If you choose not to file Form 8832 at this time, your tax classification will default to <strong>Partnership</strong>.)</p>
                              :
                                <p>This LLC has only one member. Please make a selection in the area below or indicate if you do not wish to file at this time. (NOTE: If you choose not to file Form 8832 at this time, your tax classification will default to <strong>Sole Proprietorship</strong>.)</p>
                              }
                            </Fragment>
                          ) : (
                            <p>Please make a selection in the area below or indicate if you do not wish to file at this time. (NOTE: If you choose not to file Form 8832 at this time, your tax classification will default to <strong>Sole Proprietorship</strong>.)</p>
                          )
                        }

                        <div className="indent-wrap">
                          <FormGroup className="form-row">
                            <Col className="col-12">
                              <CustomInput onChange={() => setFieldValue("entityClassification", "CC")} checked={values.entityClassification === "CC"} type="radio" name="entityClassification" id="CCorporation" value="CC" label={'C-Corporation'} inline data-hj-masked />
                            </Col>
                          </FormGroup>
                          <FormGroup className="form-row">
                            <Col className="col-12">
                              <CustomInput onChange={() => setFieldValue("entityClassification", "SC")} checked={values.entityClassification === "SC"} type="radio" name="entityClassification" id="SCorporation" value="SC" label={'S-Corporation'} inline data-hj-masked />
                            </Col>
                          </FormGroup>

                          { state.companyManageBy === "members" ? (
                            <Fragment>
                              { state.personsData.length > 1 ? 
                                <FormGroup className="form-row">
                                  <Col className="col-12">
                                    <CustomInput onChange={() => setFieldValue("entityClassification", "NO")} checked={values.entityClassification === "NO"} type="radio" name="entityClassification" id="Partnership" value="NO" label={state.companyManageBy === "members" ? "Do not file at this time (default to Partnership)" : "Partnership"} inline data-hj-masked />
                                  </Col>
                                </FormGroup>
                              :
                                <FormGroup className="form-row">
                                  <Col className="col-12">
                                    <CustomInput onChange={() => setFieldValue("entityClassification", "NO")} checked={values.entityClassification === "NO"} type="radio" name="entityClassification" id="SoleProprietorship" value="NO" label={state.companyManageBy === "members" ? "Do not file at this time (default to Sole Proprietorship)" : "Sole Proprietorship"} inline data-hj-masked />
                                  </Col>
                                </FormGroup>
                              }
                            </Fragment>
                          ) : 
                            <Fragment>
                              <FormGroup className="form-row">
                                <Col className="col-12">
                                  <CustomInput onChange={() => setFieldValue("entityClassification", "PA")} checked={values.entityClassification === "PA"} type="radio" name="entityClassification" id="Partnership" value="PA" label="Partnership" inline data-hj-masked />
                                </Col>
                              </FormGroup>
                              <FormGroup className="form-row">
                                <Col className="col-12">
                                  <CustomInput onChange={() => setFieldValue("entityClassification", "NO")} checked={values.entityClassification === "NO"} type="radio" name="entityClassification" id="DontFile" value="NO" label={'Do not file at this time (default to Sole Proprietorship)'} inline data-hj-masked />
                                </Col>
                              </FormGroup>
                            </Fragment>
                          }
                        </div>

                        {
                          (state.companyManageBy === "managers" && values.entityClassification !== "NO") ? (
                            <Fragment>
                              <br/>
                              <p>If you have chosen to file at this time, note that the IRS requires that at least one Member of the company be listed on Form 8832. Please provide the following information for one member of the company:</p>
                              <FormGroup className="form-row">
                                <Label sm={3} className="text-sm-right" for="memberFirstName">First Name</Label>
                                <Col sm={8}>
                                  <Input tag={Field} invalid={errors.memberFirstName && touched.memberFirstName} onChange={handleChange} value={values.memberFirstName} type="text" name="memberFirstName" id="memberFirstName" data-hj-masked />
                                </Col>
                              </FormGroup>
                              <FormGroup className="form-row">
                                <Label sm={3} className="text-sm-right" for="memberLastName">Last Name</Label>
                                <Col sm={8}>
                                  <Input tag={Field} invalid={errors.memberLastName && touched.memberLastName} onChange={handleChange} value={values.memberLastName} type="text" name="memberLastName" id="memberLastName" data-hj-masked />
                                </Col>
                              </FormGroup>
                              <FormGroup className="form-row">
                                <Label sm={3} className="text-sm-right" for="memberSsn">Social Security Number</Label>
                                <Col sm={8}>
                                  <InputMask className={errors.memberSsn && touched.memberSsn ? "form-control is-invalid" : "form-control"} onChange={handleChange} value={values.memberSsn} type={showSSN ? "tel" : "password"} name="memberSsn" id="memberSsn" data-hj-masked mask="999-99-9999" maskChar={"_"} placeholder="___-__-____" onFocus={()=>setShowSSN(true)} onBlur={()=>setShowSSN(false)} />
                                </Col>
                              </FormGroup>
                            </Fragment>
                          ) : (
                            null
                          )
                        }
                      </Fragment>
                    ) 
                    : null
                  }

                  { values.entityClassification === "CC" || values.entityClassification === "SC" || accountType !== "llc"  ?
                      <Fragment>
                        <Alert className="mt-4 px-3" color="primary" fade={false}>
                          <p className="mb-0">To qualify for S Corporation status, the corporation must be a domestic corporation; have only one class of stock; and have not more than 35 stockholders, who must be individuals, estates, or certain trusts.</p>
                        </Alert>
                        <h3 className="h4 mb-3">Most new corporations files in the State of Florida elect S Corporation status.</h3>
                        <p>An S Corporation is a corporation which has elected a special tax status. This tax treatment permits the income of the corporation to be "passed through" to the shareholders. Thus, shareholders report the income or loss which is generated by an S Corporation on their individual tax returns. In order to be considered an S Corporation, the stockholders of a properly filed corporation must elect such status within 75 days of formation for the current tax year, or at any time during the preceding tax year. This election is made by filing Form 2553 with the Internal Revenue Service (IRS). We offer this as an option for only $39.</p>
                        <p><strong>Do you wish to file the S Corporation election form?</strong></p>
                        <FormGroup className="form-row action-area">
                          <Col sm={12} className="ml-md-auto">
                            <CustomInput className="boxed-checkbox" onChange={event => handleSubSElectionChange(event, setFieldValue)} checked={values.subSElection} value={703} type="checkbox" name="subSElection" id="subSElection" label={<strong>Yes, file the S Corporation election form</strong>} inline data-hj-masked />
                          </Col>
                        </FormGroup>
                      </Fragment>
                  : null }

                  <FormValidationAlert
                    submitCount={submitCount}
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                    errors={errors}
                  />

                  <ProcessingStatus showProcessing={state.isProcessing && !state.apiErrors} />

                  <FormErrorModal errors={state.apiErrors} />

                  {(!form[formType].isComplete || !form[formType].orderInfoId) ?
                    <FormNav action={{prev: accountType === "llc" ? `/ein-contact/${accountType}/${props.location.search}` :  `/ein-application/${accountType}/${props.location.search}`}} />
                  : null }
                  
                </Form>
              )}
            </Formik>
          </Col>

          <Sidebar 
            orderDetailPids={state.orderDetailPids} 
            host={host} 
            lgr={state.formState.lgr} 
          />

        </Row>
      </Container>
    </Fragment>
  );
}

export default EntityClassification;
