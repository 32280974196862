// pass an array of "optionalProducts" or "optionalServices" descriptions, will not return a PID if description is not in the mapping
// note that the custom field names need to be the first instance otherwise it will not return the key/value pair for generateKeys()
const productsMap = {
  LLCService: 580,
  CertificateOfStatus: 581,
  certificateOfStatus: 581,
  CorporateEmbosser: 582,
  companyEmbosser: 582,
  FictitiousNameRegistration: 583,
  needFictitiousName: 583,
  RegisteredAgentFirstYear: 701,
  registeredAgent: 701,
  EINApplication: 702,
  einRegistration: 702,
  SubSElection: 703,
  subSElection: 703,
  BylawsAndMinutes: 704,
  corporateKit: 704,
  CorpSealsAndCerts: 705,
  certificateOfStocksAndCorporateSeals: 705,
  Form8832Filing: 707,
  LLCSealsAndCerts: 706,
  certificateOfOwnershipAndSeals: 706,
  OperationalAgreement: 749,
  companyKit: 749,
}

const productsKeyMap = {
  certificateOfStatus: 581,
  companyEmbosser: 582,
  needFictitiousName: 583,
  registeredAgent: 701,
  einRegistration: 702,
  subSElection: 703,
  corporateKit: 704,
  certificateOfStocksAndCorporateSeals: 705,
  certificateOfOwnershipAndSeals: 706,
  companyKit: 749,
}

export const generatePids = (optionalProducts) => { 
  if (optionalProducts.length > 0) {
    return optionalProducts.map(item => productsMap[item]).filter(pid => pid);
    // let pidsArray = [];
    // optionalProducts.forEach(key => {
    //   if (productsMap[key] !== undefined) {
    //     pidsArray.push(productsMap[key]);
    //   }
    // });
    // return pidsArray;
  } 
  else {
    return [];
  }
}

export const generateKeys = (orderPids) => { 
  if (orderPids.length > 0) {
    let keysObj = {};
    orderPids.forEach(pid => {
      const productKey = Object.keys(productsKeyMap).find(key => productsKeyMap[key] === pid);
      if (productKey !== undefined) {
        keysObj = {
          ...keysObj,
          [productKey]: true
        }
      }
    });
    return keysObj;
  } 
  else {
    return {};
  }
}

