import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, FormGroup, Label, Input } from "reactstrap";
import queryString from "query-string";
import { Helmet } from "react-helmet";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import API from "../services/apiService";
import jwt_decode from "jwt-decode";
import { generatePids } from "../utils/productMapping";
import Sidebar from "../components/Sidebar";
import CountyDropdown from "../components/CountyDropdown";
import ProgressStatus from "../components/ProgressStatus";
import FormNav from "../components/FormNav";
import FormValidationAlert from "../components/FormValidationAlert";
import ProcessingStatus from "../components/ProcessingStatus";
import FormErrorModal from "../components/FormErrorModal";
import ApplicationCompleteAlert from "../components/ApplicationCompleteAlert";
import { formErrorHandler, appErrorHandler } from "../utils/errorHandlerUtil";
import { storeApplication } from "../store/actions/formActions";
import { accountInfoState } from "../utils/storeState";
import { storeUser } from "../store/actions/authActions";

function DBABusinessInformation(props) {
  const form = useSelector((state) => state.form);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const pageTitle = "Florida Incorporation Service Registration | Business Information",
    registrationStep = 1,
    accountType = props.match.params.accountType?.toLowerCase(),
    orderDetails = JSON.parse(sessionStorage.getItem("orderDetails")) || [],
    host = queryString.parse(props.location.search)?.host || "",
    formType = accountType === "dba" ? "dba" : "corp",
    serviceType = accountType.toLowerCase() === "corp" ? 0 : accountType.toLowerCase() === "llc" ? 2 : 3;

  // checks if current url is valid for the stored order data
  const { pid, lgr, pkg } = queryString.parse(props.location.search);
  if (!pid || !lgr || !host || (accountType !== "dba" ? !pkg : false)) {
    appErrorHandler(
      "Custom Error",
      `Missing query string ${props.history.location.pathname}${props.history.location.search}`,
      dispatch
    );
    // props.history.replace(`/error/${accountType}/${props.location.search}`);
  }
  if (
    orderDetails.length &&
    (Number(orderDetails[0].id) !== Number(pid) || orderDetails[0].lgr !== lgr?.toLowerCase())
  ) {
    sessionStorage.removeItem("orderDetails");
    sessionStorage.removeItem("productsData");
    sessionStorage.removeItem("fisFormState");
    // props.history.replace(`/account-info/${accountType}/${props.location.search}`);
  }

  const initState = {
    formState: {
      fictitiousName: form[formType].companyName || "",
      mailingStreet1: form[formType].companyAddress?.street1 || "",
      mailingStreet2: form[formType].companyAddress?.street2 || "",
      mailingCity: form[formType].companyAddress?.city || "",
      mailingCounty: form[formType].companyAddress?.county || "",
      mailingPostalCode: form[formType].companyAddress?.postalCode || "",
      mailingState: form[formType].companyAddress?.stateProvince || "FL",
      mailingCountry: form[formType].companyAddress?.country || "US",
      operatesInCounty: form[formType].counties || "Multiple Counties",
      fisPackage: form[formType].registrationVars?.fisPackage || "",
      lgr: form[formType].registrationVars?.lgr || queryString.parse(props.location.search).lgr?.toLowerCase() || "",
      optionalProducts: form[formType].optionalProducts || [], // array can contain one or more of the following: SubSElection, BylawsAndMinutes, CertificateOfStatus, CorporateEmbosser, CorpSealsAndCerts, Form8832Filing, Form8832Filing, LLCSealsAndCerts, OperationalAgreement
      optionalServices: form[formType].optionalServices || [],
    },
    // isProcessing: true,
  };

  const checkedProducts = Object.keys(initState.formState).filter((key) => initState.formState[key]); // generate product keys for generatePids()
  const generatedPids = generatePids([
    ...checkedProducts,
    ...initState.formState.optionalProducts,
    ...initState.formState.optionalServices,
  ]);
  const uniquePids = new Set([Number(pid), ...generatedPids]); // removing duplicate PIDs
  // const productKeys = generateKeys([...uniquePids]); // object of product keys with true value

  const [state, setState] = useState({
    ...initState,
    formState: {
      ...initState.formState,
    },
    orderDetailPids: [...uniquePids],
  });

  // old app's behavior is to create a new account for every new registration coming from a frontend link
  // using ref=self means page visit is coming from the app itself
  const refQuery = queryString.parse(props.location.search)?.ref === "self";
  const newApplication =
    !auth.user ||
    !refQuery ||
    (auth.user && form[formType].account?.id === 0) ||
    (refQuery && !auth.user && form[formType].account?.id === 0);

  useEffect(() => {
    if (newApplication) {
      sessionStorage.removeItem("orderDetails");
      sessionStorage.removeItem("productsData");
      sessionStorage.removeItem("fisFormState");
      const applicationState = accountInfoState(null, null, {});
      dispatch(storeApplication(applicationState)); // sets redux store with form info
      setState((state) => ({
        ...state,
        orderDetailPids: [Number(pid)],
      }));
    }
  }, [dispatch, newApplication, pid]);

  const handleFormSubmit = (values, actions) => {
    // console.log(values);
    setState((state) => ({
      ...state,
      formState: values,
      isProcessing: true,
    }));

    const goToNextPage = (apiData) => {
      sessionStorage.removeItem("fisFormState"); // deleting current formState from sessionStorage
      setState((state) => ({
        ...state,
        isProcessing: false,
      }));
      // creating object that matched the redux state object to prevent removing any key/values that's not updated
      const applicationState = accountInfoState(accountType, form, apiData);
      dispatch(storeApplication(applicationState)); // sets redux store with form info
      // console.log("Submitted, no errors in form!")
      actions.resetForm(true);
      const nextUrl = `/contact-info/${accountType}/${props.location.search}${!refQuery ? "&ref=self" : ""}`;
      // need to replace history to add &ref=self in the url, meaning user have been authenticated and is not coming from a frontend link
      const refUrl = refQuery
        ? props.history.location.pathname + props.history.location.search
        : props.history.location.pathname + props.history.location.search + "&ref=self";
      props.history.replace(refUrl); // replace history to add &ref=self in the url
      props.history.push(nextUrl); // push user to next page after form submission or api call
      window.scrollTo(0, 0); // making sure that user view is top of the page
    };

    const companyInfo = {
      companyName: values.fictitiousName,
      counties: values.operatesInCounty,
      companyAddress: {
        street1: values.mailingStreet1,
        street2: values.mailingStreet2,
        city: values.mailingCity,
        postalCode: values.mailingPostalCode,
        county: values.mailingCounty,
        stateProvince: values.mailingState,
        country: values.mailingCountry,
      },
    };

    const setAccessToken = async (accountInfo) => {
      // console.log(accessData.data)
      const decodedToken = jwt_decode(accountInfo.accessToken.accessToken);
      const user = {
        access_token: accountInfo.accessToken.accessToken,
        token_type: accountInfo.accessToken.tokenType,
        refresh_token: accountInfo.accessToken.refreshToken,
        expires_in: decodedToken.exp,
        profile: {
          // sub: decodedToken.sub,
          peferred_username: accountInfo.account.userName,
        },
      };
      // also need to save in sessionStorage
      const { Identity } = JSON.parse(sessionStorage.getItem("appsettings"));
      // saving user info in sessionStorage give oidc-client-js all it needs to rehydrate its redux store
      sessionStorage.setItem(`oidc.user:${Identity.Authority}:${Identity.ClientId}`, JSON.stringify(user));
      dispatch(storeUser(user)); // sets redux store with form info, also sets bearer token header
    };

    const createUpdateAccountCompany = () => {
      if (newApplication) {
        return API.createAnonymousAccount();
      } else {
        // console.log("patchCompany")
        return API.patchDbaApplicant(form[formType].account?.id, form[formType].id, companyInfo);
      }
    };

    createUpdateAccountCompany()
      .then(async (accountCompanyData) => {
        // console.log("Created/Updated Account:", accountData.data);
        const accountId = accountCompanyData.data.account?.id || form[formType].account?.id || null;
        const companyInfo = {
          accountId: accountId,
          serviceType: serviceType,
        };
        if (newApplication || (!newApplication && form[formType].id === 0)) {
          await setAccessToken(accountCompanyData.data);
          // console.log("await patchAccount")
          return API.createDbaApplicant(accountId, companyInfo);
        } else {
          return accountCompanyData;
        }
      })
      .then(async (companyData) => {
        // console.log("Updated Company:", companyData.data);
        // console.log(JSON.stringify(companyData, 0, 2))
        const accountId = companyData.data.account?.id || form[formType].account?.id || null;
        const companyId = companyData.data?.id || form[formType]?.id || null;
        return API.patchDbaApplicant(accountId, companyId, companyInfo);
      })
      .then((companyData) => {
        // console.log("patchDbaApplicant", companyData.data)
        goToNextPage(companyData.data);
      })
      .catch((err) => {
        formErrorHandler(err, setApiErrorState);
      });
  };

  const setApiErrorState = (err) => {
    setState((state) => ({
      ...state,
      apiErrors: err,
    }));
  };

  const validationSchema = Yup.object().shape({
    fictitiousName: Yup.string()
      .min(2, "Fictitious name is too short!")
      .max(255, "Fictitious name is too long!")
      .required("Fictitious name is required"),
    mailingStreet1: Yup.string()
      .min(2, "Mailing street is too short!")
      .max(255, "Mailing street is too long!")
      .required("Mailing street is required"),
    mailingCity: Yup.string()
      .min(2, "Mailing city is too short!")
      .max(255, "Mailing city is too long!")
      .required("Mailing city is required"),
    mailingCounty: Yup.string()
      .min(2, "Mailing county is too short!")
      .max(255, "Mailing county is too long!")
      .required("Mailing county is required"),
    mailingPostalCode: Yup.string()
      .matches(/^\d{5}(?:[-\s]\d{4})?$/, {
        message: "Mailing zip code must match this format: XXXXX or XXXXX-XXXX",
        excludeEmptyString: true,
      })
      .required("Mailing zip code is required"),
  });

  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel='canonical' href='https://www.floridaincorporationservice.com' />
      </Helmet>

      <ProgressStatus registrationStep={registrationStep} />

      <Container>
        <Row className='justify-content-md-center'>
          {form[formType].isComplete || form[formType].orderInfoId ? <ApplicationCompleteAlert /> : null}
          <Col lg='8' md='10' sm='12'>
            <Formik
              enableReinitialize={true}
              initialValues={state.formState}
              validationSchema={validationSchema}
              // onChange={values => handleChange(values)}
              onSubmit={(values, actions) => handleFormSubmit(values, actions)}
            >
              {({ isValid, errors, touched, values, handleChange, submitCount, isSubmitting }) => (
                <Form>
                  <h2>Filing Information</h2>
                  <p>
                    Please enter the name you wish to register. Make sure the name you are filing is spelled correctly.
                    Only one name can be applied for at a time, so please list only one name. A fictitious name cannot
                    have the words "Corporation", "Incorporated", "Inc.", "Corp", "LLC", or "Limited Liability Company"
                    unless the business for which the name is registered is incorporated or has obtained a certificate
                    of authority to transact business in this state. (Florida Statutes 607 and 617)
                  </p>
                  <FormGroup className='form-row'>
                    <Label sm={3} className='text-sm-right' for='fictitiousName'>
                      Fictitious Name
                    </Label>
                    <Col sm={8}>
                      <Input
                        tag={Field}
                        invalid={errors.fictitiousName && touched.fictitiousName}
                        onChange={handleChange}
                        value={values.fictitiousName}
                        type='text'
                        name='fictitiousName'
                        id='fictitiousName'
                        data-hj-masked
                      />
                    </Col>
                  </FormGroup>

                  <h2>Business Mailing Address</h2>
                  <p>
                    Please enter the mailing address, though this address does not have to be the principal place of
                    business and may be sent to anyone's attention.{" "}
                    <strong>
                      Please don't use an address that is unoccupied. The address you provide is where the Division of
                      Corporations will be mailing all information pertaining to this application.
                    </strong>
                  </p>
                  <FormGroup className='form-row'>
                    <Label sm={3} className='text-sm-right' for='mailingStreet1'>
                      Address
                    </Label>
                    <Col sm={8}>
                      <Input
                        tag={Field}
                        invalid={errors.mailingStreet1 && touched.mailingStreet1}
                        onChange={handleChange}
                        value={values.mailingStreet1}
                        type='text'
                        name='mailingStreet1'
                        id='mailingStreet1'
                        data-hj-masked
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className='form-row'>
                    <Label sm={3} className='text-sm-right' for='mailingStreet2'>
                      Suite. Apt. #, etc.
                    </Label>
                    <Col sm={8}>
                      <Input
                        tag={Field}
                        invalid={errors.mailingStreet2 && touched.mailingStreet2}
                        onChange={handleChange}
                        value={values.mailingStreet2}
                        type='text'
                        name='mailingStreet2'
                        id='mailingStreet2'
                        data-hj-masked
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className='form-row'>
                    <Label sm={3} className='text-sm-right' for='mailingCity'>
                      City
                    </Label>
                    <Col sm={8}>
                      <Input
                        tag={Field}
                        invalid={errors.mailingCity && touched.mailingCity}
                        onChange={handleChange}
                        value={values.mailingCity}
                        type='text'
                        name='mailingCity'
                        id='mailingCity'
                        data-hj-masked
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className='form-row'>
                    <Label sm={3} className='text-sm-right' for='mailingCounty'>
                      County
                    </Label>
                    <Col sm={8}>
                      <CountyDropdown
                        invalid={errors.mailingCounty && touched.mailingCounty}
                        onChange={handleChange}
                        value={values.mailingCounty}
                        name='mailingCounty'
                        id='mailingCounty'
                        data-hj-masked
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className='form-row'>
                    <Label sm={3} className='text-sm-right' for='mailingPostalCode'>
                      Zip Code
                    </Label>
                    <Col sm={8}>
                      <Input
                        tag={Field}
                        invalid={errors.mailingPostalCode && touched.mailingPostalCode}
                        onChange={handleChange}
                        value={values.mailingPostalCode}
                        type='text'
                        name='mailingPostalCode'
                        id='mailingPostalCode'
                        data-hj-masked
                      />
                    </Col>
                  </FormGroup>
                  <h4>Your business operates in what Florida County(ies)?</h4>
                  <p>Select "Multiple Counties" if more than one Florida County applies.</p>
                  <FormGroup className='form-row'>
                    <Label sm={3} className='text-sm-right' for='operatesInCounty'>
                      County(ies)?
                    </Label>
                    <Col sm={8}>
                      <CountyDropdown
                        invalid={errors.operatesInCounty && touched.operatesInCounty}
                        onChange={handleChange}
                        value={values.operatesInCounty}
                        operatesIn={true}
                        name='operatesInCounty'
                        id='operatesInCounty'
                        data-hj-masked
                      />
                    </Col>
                  </FormGroup>

                  <FormValidationAlert
                    submitCount={submitCount}
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                    errors={errors}
                  />

                  <ProcessingStatus showProcessing={state.isProcessing && !state.apiErrors} />

                  <FormErrorModal errors={state.apiErrors} />

                  {!form[formType].isComplete || !form[formType].orderInfoId ? (
                    <FormNav action={{ prev: "" }} hidePrevButton={true} hideSaveButton={true} />
                  ) : null}
                </Form>
              )}
            </Formik>
          </Col>

          <Sidebar orderDetailPids={state.orderDetailPids} host={host} lgr={state.formState.lgr} />
        </Row>
      </Container>
    </Fragment>
  );
}

export default DBABusinessInformation;
