import {
  STORE_ERROR,
} from '../actions/types'

const initialState = {
  app: {
    error: {
      state: false,
      details: null,
    }
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case STORE_ERROR:
      // console.log("payload", action.payload)
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}