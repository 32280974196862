import React from "react";
import {
  Col,
  Alert,
} from 'reactstrap';

const ApplicationCompleteAlert = (props) =>  {
  return (
    <Col lg="12" md="10" sm="12" data-hj-masked>
      <Alert className="mt-4 mb-0 px-3" color="danger" fade={false}>
        <p className="mb-0">This application has been completed, you're no longer allowed to make any changes. If you want to start a new application please <a href="/logout">logout</a> and try again.</p>
      </Alert>
    </Col>
  );
}

export default ApplicationCompleteAlert;