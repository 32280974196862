import React, { useState } from 'react';
import {
  Col,
  FormGroup,
  CustomInput,
  Modal, 
  ModalHeader, 
  ModalBody
} from 'reactstrap';
import { displayPriceDollar } from "../../../utils/displayPrice";

const CertificateOfStatus = (props) => {
  const pid =  581;
  const [modal, setModal] = useState(false);
  const toggle = (e) => {
    e.preventDefault();
    setModal(!modal);
  }
  const price = displayPriceDollar(pid);
  
  return (
    <FormGroup className="form-row">
      <Col sm={12} className="ml-md-auto">
        <CustomInput onChange={props.onChange} checked={props.checked} type="checkbox" value={pid} name={props.name} id={props.id} label={<span>Certificate of Status - ${price} <a href="//:0" onClick={toggle} className="upsell_desc_trigger">?</a></span>} inline data-hj-masked />
        <Modal centered isOpen={modal} toggle={toggle} size="lg">
          <ModalHeader toggle={toggle}>Certificate of Status</ModalHeader>
          <ModalBody>
            <p>A Certificate of Status is issued by the Division of Corporations to show that your company is active and has paid the initial filing fees or Annual Report after the first year.</p>
            <p>Some Banks and governmental agencies require this certificate as verification that the company is an active Florida company.</p>
            <p className="mb-0">If you choose this certificate as part of your package, it is issued electronically by the Division of Corporations and will be emailed to you with your Articles by us. </p>
          </ModalBody>
        </Modal>
      </Col>
    </FormGroup>
  );
}

export default CertificateOfStatus;