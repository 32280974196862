import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {
  Container,
  Row,
  Col,
  FormGroup,
  CustomInput,
  Button,
  Table,
  Card,
} from 'reactstrap';
import queryString from "query-string";
import {Helmet} from "react-helmet";
import {  Form, Formik } from "formik";
import * as Yup from 'yup';
import API from "../services/apiService";
import { generatePids } from "../utils/productMapping";
import Sidebar from "../components/Sidebar";
import ProgressStatus from "../components/ProgressStatus";
import PersonFormModal from "../components/PersonFormModal";
import PersonList from "../components/PersonList";
import FormNav from "../components/FormNav";
import FormValidationAlert from "../components/FormValidationAlert";
import ProcessingStatus from "../components/ProcessingStatus";
import FormErrorModal from "../components/FormErrorModal";
import ApplicationCompleteAlert from "../components/ApplicationCompleteAlert";
import { formErrorHandler, appErrorHandler } from "../utils/errorHandlerUtil";

import { storeApplication } from '../store/actions/formActions'	
import { accountInfoState } from "../utils/storeState";


function BusinessManagement(props) {
  const form = useSelector(state => state.form);
  const dispatch = useDispatch()

  const pageTitle = "Florida Incorporation Service Registration | Business Management",
        registrationStep = 1,
        accountType = props.match.params.accountType?.toLowerCase(),
        orderDetails = JSON.parse(sessionStorage.getItem("orderDetails")) || [],
        host = queryString.parse(props.location.search)?.host || "",
        formType = accountType === "dba" ? "dba" : "corp";

  // checks if current url is valid for the stored order data
  const { pid, lgr, pkg } = queryString.parse(props.location.search);
  if (!pid || !lgr || !host || (accountType !== "dba" ? !pkg : false)) {
    appErrorHandler("Custom Error", `Missing query string ${props.history.location.pathname}${props.history.location.search}`, dispatch)
    // props.history.replace(`/error/${accountType}/${props.location.search}`);
  }
  if (orderDetails.length && (Number(orderDetails[0].id) !== Number(pid) || orderDetails[0].lgr !== lgr?.toLowerCase())) {
    sessionStorage.removeItem('orderDetails');
    sessionStorage.removeItem('productsData');
    sessionStorage.removeItem('fisFormState');
    props.history.replace(`/account-info/${accountType}/${props.location.search}`);
  }
  
  const initState = {
    formState: {
      companyManageBy: !form[formType].llcManagementCode || form[formType].llcManagementCode === 1 ? "members" : "managers",
      members: form[formType].llcManagementCode === 1 ? form[formType].directors : [],
      managers: form[formType].llcManagementCode === 2 ? form[formType].directors : [],
      directors: form[formType].directors || [],
      officers: form[formType].officers || [],
      pointOfContact: form[formType].pointOfContact || {},
      fisPackage: form[formType].registrationVars?.fisPackage || "",
      lgr: form[formType].registrationVars?.lgr || "",
      optionalProducts: form[formType].optionalProducts || [], // array can contain one or more of the following: SubSElection, BylawsAndMinutes, CertificateOfStatus, CorporateEmbosser, CorpSealsAndCerts, Form8832Filing, Form8832Filing, LLCSealsAndCerts, OperationalAgreement 
      optionalServices: form[formType].optionalServices || [],
    },
    showMemberForm: false,
    showManagerForm: false,
    showDirectorForm: false,
    showOfficerForm: false,
  }

  const checkedProducts = Object.keys(initState.formState).filter(key => initState.formState[key]); // generate product keys for generatePids()
  const generatedPids = generatePids([...checkedProducts, ...initState.formState.optionalProducts, ...initState.formState.optionalServices])
  const uniquePids = new Set([Number(pid), ...generatedPids]); // removing duplicate PIDs
  // const productKeys = generateKeys([...uniquePids]); // object of product keys with true value

  const [state, setState] = useState({
    ...initState,
    orderDetailPids: [...uniquePids]
  });

  const showPersonForm = showPersonForm => {
    setState(state => ({
      ...state,
      [showPersonForm]: !state[showPersonForm],
    }));
  }

  const addPerson = (personType, values, addCurrentPerson) => {
    // console.log(values)
    const titles = ["President", "Vice President", "Secretary", "Treasurer"];
    const personState = values[personType];
    // if (!addCurrentPerson && (values.tempPersonInfo[personType].title !== "" && !values[personType].find(item => item.title === values.tempPersonInfo[personType].title))) {
    if (!addCurrentPerson && (values.tempPersonInfo[personType].title !== "" && values[personType].filter(item => item.title === values.tempPersonInfo[personType].title).length > 0)) {
      const index = values[personType].findIndex(item => item.title === values.tempPersonInfo[personType].title);
      values[personType].splice(index, 1);
      // values.personIndex = values.personIndex - 1;
    }
    let tempPersonInfo = {};
    if (!addCurrentPerson) {
      tempPersonInfo = {
        ...values.tempPersonInfo[personType],
        companyId: form.corp.id || "",
        person: {
          ...values.tempPersonInfo[personType].person,
          title: values.tempPersonInfo[personType].title,
        }
      };
    }
    else {
      tempPersonInfo = {
        companyId: form.corp.id || "",
        title: form[formType].pointOfContact.title || "",
        person: {
          title: form[formType].pointOfContact.title || "",
          firstName: form[formType].pointOfContact.firstName || "",
          lastName: form[formType].pointOfContact.lastName || "",
          emailAddress: form[formType].pointOfContact.emailAddress || "",
          addresses: [
            {
              street1: form[formType].pointOfContact.addresses[0]?.street1 || "",
              street2: form[formType].pointOfContact.addresses[0]?.street2 || "",
              city: form[formType].pointOfContact.addresses[0]?.city || "",
              postalCode:form[formType].pointOfContact.addresses[0]?.postalCode || "",
              county: form[formType].pointOfContact.addresses[0]?.county || "",
              stateProvince: form[formType].pointOfContact.addresses[0]?.stateProvince || "",
              country: form[formType].pointOfContact.addresses[0]?.country || "US",
            }
          ]
        },
      }
    }
    if (addCurrentPerson && personType === "officers") {
      for(let title of titles) {
        const officerPerson = {
          ...tempPersonInfo,
          title: title,
          person: {
            ...tempPersonInfo.person,
            title: title,
          }
        }
        personState.push(officerPerson);
      }
    }
    else {
      personState.push(tempPersonInfo);
    }
    setState(state => ({
      ...state,
      formState: { 
        ...values,
        [personType]: personState,
      },
      showDirectorForm: personType === "directors" ? false : state.showDirectorForm,
      showOfficerForm: personType === "officers" ? false : state.showOfficerForm,
      showMemberForm: personType === "members" ? false : state.showMemberForm,
      showManagerForm: personType === "managers" ? false : state.showManagerForm,
    }));
  }

  const removePerson = (index, personType, values) => {
    let personState = values[personType];
    personState.splice(index, 1);
    setState(state => ({
      ...state,
      formState: { 
        ...values,
        [personType]: personState,
      }
    }));
  }

  const handleFormSubmit = (values, actions) => {
    // console.log(values);
    setState(state => ({
      ...state,
      formState: { 
        ...state.formState,
        ...values,
      }
    }));

    const goToNextPage = (apiData) => {
      sessionStorage.removeItem('fisFormState'); // deleting current formState from sessionStorage
      setState(state => ({
        ...state,
        isProcessing: false,
      }));
      // creating object that matched the redux state object to prevent removing any key/values that's not updated
      const applicationState = accountInfoState(accountType, form, apiData);
      dispatch(storeApplication(applicationState)) // sets redux store with form info
      actions.resetForm(true);
      const nextUrl = `/registered-agent/${accountType}/${props.location.search}`;
      props.history.push(nextUrl); // push user to next page after form submission or api call
      window.scrollTo(0, 0) // making sure that user view is top of the page
    }

    const cleanPerson = items => {
      return items.map(item => {
        delete item.id;
        delete item.person.id;
        delete item.person.created;
        delete item.person.lastModified;
        delete item.person.addresses[0].id;
        delete item.person.addresses[0].created;
        delete item.person.addresses[0].lastModified;
        item.person.emailAddress = item.person.emailAddress || null;
        return item;
      })
    }
    const accountId = form.corp.account.id; // grabbing from redux
    const companyId = form.corp.id; // grabbing from redux
    // need to clean up the person's info and remove unecessary key/value pairs
    const directorsInfo = cleanPerson(values.directors)
    const officersInfo = cleanPerson(values.officers)
    // LLC managers and managing members get saved as directors in the DB so use the director's endpoint
    const membersInfo = cleanPerson(values.members);
    const managersInfo = cleanPerson(values.managers);
    let personInfo = [];
    if (accountType !== "llc") {
      personInfo = [...directorsInfo]
    }
    else if (accountType === "llc" && values.companyManageBy === "members") {
      personInfo = [...membersInfo]
    }
    else if (accountType === "llc" && values.companyManageBy === "managers") {
      personInfo = [...managersInfo]
    }
    // is either MGR or MEM, only used for LLC application
    const companyInfo = {
      llcManagementCode: values.companyManageBy === "members" ? "MEM" : "MGR",
      einDirectorId: null,
    }
    
    // console.log("personInfo", JSON.stringify(personInfo, null, 2))
    // set to POST for now but needs to be set to PUT API.updateDirectors() once API is fix
    // this is really important for LLC since both members and managers are saved as directors
    API.updateDirectors(accountId, companyId, personInfo).then((companyData) => {
      // console.log("Added Directors/Managers:", companyData.data);
      if (accountType === "corp") {
        return API.updateOfficers(accountId, companyId, officersInfo)
      }
      else {
        return API.patchCompany(accountId, companyId, companyInfo)
      }
    })
    .then((companyData) => {
      // if (accountType === "corp") {
      //   console.log("Added Officers:", companyData.data);
      // }
      // else {
      //   console.log("Updated Company:", companyData.data);
      // }
      goToNextPage(companyData.data);
    })
    .catch(err => {
      formErrorHandler(err, setApiErrorState);
    });
  }
  
  const setApiErrorState = (err) => {
    setState(state => ({
      ...state,
      apiErrors: err,
    }));
  }

  const validationSchema = Yup.object().shape({
    companyManageBy: Yup.string(),
    members: Yup.array().when('companyManageBy', {
      is: (companyManageBy) => companyManageBy === 'members' && accountType === 'llc',
      then: Yup.array().min(1, 'Please add at least one managing member'),
    }),
    managers: Yup.array().when('companyManageBy', {
      is: (companyManageBy) => companyManageBy === 'managers' && accountType === 'llc',
      then: Yup.array().min(1, 'Please add at least one manager'),
    }),
    directors: Yup.array().when({
      is: () => accountType === 'corp',
      then: Yup.array().min(1, 'Please add at least one director'),
    }),
    officers: Yup.array().when({
      is: () => accountType === 'corp',
      then: Yup.array().min(1, 'Please add at least one officer, a president').test('president-check', 'Please add a "President" officer', function (value) {
        return value.filter(officer => officer.title === "President").length > 0
      }),
    }),
  })

  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href="https://www.floridaincorporationservice.com" />
      </Helmet>

      <ProgressStatus registrationStep={registrationStep} />

      <Container>
        <Row className="justify-content-md-center">
          {form[formType].isComplete || form[formType].orderInfoId ? 
            <ApplicationCompleteAlert />
          : null }          
          <Col lg="8" md="10" sm="12">
            <Formik
              enableReinitialize={true}
              initialValues={state.formState}
              validationSchema={validationSchema}
              // onChange={values => handleChange(values)}
              onSubmit={(values, actions) => handleFormSubmit(values, actions)}
            >
              {({ isValid, errors, touched, values, handleChange, submitCount, isSubmitting, validate }) => (
                <Form>
                  <h2>Business Management</h2>
                  {(accountType === "corp" && (values.directors.length === 0 || values.officers.length === 0)) ? 
                    <Fragment>
                      <p className="mb-2"><strong>We show your information as:</strong></p>
                      <Card className="person-list mb-3">
                        <Table className="mb-0" size="sm" responsive>
                          <thead className="thead-light">
                            <tr>
                              <th>Name</th>
                              <th>Address</th>
                              <th>Email</th>
                            </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <td><strong>{values.pointOfContact.firstName} {values.pointOfContact.lastName}</strong></td>
                            <td>{values.pointOfContact.addresses[0].street1}, {values.pointOfContact.addresses[0].street2 ? `${values.pointOfContact.addresses[0].street2},` : null} {values.pointOfContact.addresses[0].city}, {values.pointOfContact.addresses[0].stateProvince} {values.pointOfContact.addresses[0].postalCode}</td>
                            <td>{values.pointOfContact.emailAddress}</td>
                          </tr>
                          </tbody>
                        </Table>
                      </Card>
                    </Fragment>
                  : null }
                  { 
                    accountType === "llc" ? (
                      <Fragment>
                        <p>An LLC may be run either by certain designated members (owners) within the company, or it may be run by external managers (hired) who are not members (owners) of the company.</p>
                        <p><strong>Will this LLC be run by managing members (owners) or by managers (hired)?</strong></p>
                        <FormGroup className="form-row">
                          <Col sm={12} className="ml-md-auto">
                            <CustomInput onChange={handleChange} checked={values.companyManageBy === "members"} className="mb-2" type="radio" name="companyManageBy" value="members" id="companyMember" label="Managed by Member(s)" inline data-hj-masked />
                            <CustomInput onChange={handleChange} checked={values.companyManageBy === "managers"} className="mb-2" type="radio" name="companyManageBy" value="managers" id="companyManager" label="Managed by Manager(s)" inline data-hj-masked />
                          </Col>
                        </FormGroup>
                        {
                          values.companyManageBy === "members" ? (
                            <Fragment>
                              <h4>Managing Member Information</h4>
                              {/* <p><strong>Who are the Members (owners) of the business?</strong></p> */}
                              {values.members.length === 0 ? 
                                <Fragment>
                                  <p className="mb-2"><strong>We show your information as:</strong></p>
                                  <Card className="person-list mb-3">
                                    <Table className="mb-0" size="sm" responsive>
                                      <thead className="thead-light">
                                        <tr>
                                          <th>Name</th>
                                          <th>Address</th>
                                          <th>Email</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                      <tr>
                                        <td><strong>{values.pointOfContact.firstName} {values.pointOfContact.lastName}</strong></td>
                                        <td>{values.pointOfContact.addresses[0].street1}, {values.pointOfContact.addresses[0].street2 ? `${values.pointOfContact.addresses[0].street2},` : null} {values.pointOfContact.addresses[0].city}, {values.pointOfContact.addresses[0].stateProvince} {values.pointOfContact.addresses[0].postalCode}</td>
                                        <td>{values.pointOfContact.emailAddress}</td>
                                      </tr>
                                      </tbody>
                                    </Table>
                                  </Card>
                                </Fragment>
                              : 
                                <p>The current Managing Member (owner)s are listed below. Please review this list for accuracy before continuing. You can remove any existing Managing Member (owner) by clicking the "<strong className="text-danger">x</strong>" icon to the right of that Managing Member (owner)'s information.</p>
                              }
                            </Fragment>
                          ) : (
                            <Fragment>
                              <h4>Manager Information</h4>
                              {/* <p><strong>Who are the Managers of the business?</strong></p> */}
                              {values.managers.length === 0 ?
                                <Fragment>
                                  <p className="mb-2"><strong>We show your information as:</strong></p>
                                  <Card className="person-list mb-3">
                                    <Table className="mb-0" size="sm" responsive>
                                      <thead className="thead-light">
                                        <tr>
                                          <th>Name</th>
                                          <th>Address</th>
                                          <th>Email</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                      <tr>
                                        <td><strong>{values.pointOfContact.firstName} {values.pointOfContact.lastName}</strong></td>
                                        <td>{values.pointOfContact.addresses[0].street1}, {values.pointOfContact.addresses[0].street2 ? `${values.pointOfContact.addresses[0].street2},` : null} {values.pointOfContact.addresses[0].city}, {values.pointOfContact.addresses[0].stateProvince} {values.pointOfContact.addresses[0].postalCode}</td>
                                        <td>{values.pointOfContact.emailAddress}</td>
                                      </tr>
                                      </tbody>
                                    </Table>
                                  </Card>
                                </Fragment>
                              :
                                <p>The current External (hired) Managers are listed below. Review this list for accuracy before continuing. You can remove any existing External (hired) Manager by clicking the "<strong className="text-danger">x</strong>" icon to the right of that External (hired) Manager's information.</p>
                              }
                            </Fragment>
                          )
                        }
                        { values.companyManageBy === "members" && values.members.length === 0 ? 
                          <Fragment>
                            <p className="mb-3">
                              <strong className="mr-3 mb-2 d-inline-block">Will you be a Managing Member (owner) of the company?</strong><br/>
                              <span className="d-inline-block">
                                <Button className="pl-4 pr-4 mr-2 font-weight-bolder" color="success" outline type="button" onClick={() => addPerson("members", values, true)}>Yes</Button>
                                <Button className="pl-4 pr-4 font-weight-bolder" color="success" outline type="button" onClick={() => showPersonForm("showMemberForm")}>No</Button>
                              </span>
                            </p>
                          </Fragment>
                        : null }
                        { values.companyManageBy === "managers" && values.managers.length === 0 ? 
                          <Fragment>
                            <p className="mb-3">
                              <strong className="mr-3 mb-2 d-inline-block">Will you be an External (hired) Manager of the company?</strong><br/>
                              <span className="d-inline-block">
                                <Button className="pl-4 pr-4 mr-2 font-weight-bolder" color="success" outline type="button" onClick={() => addPerson("managers", values, true)}>Yes</Button>
                                <Button className="pl-4 pr-4 font-weight-bolder" color="success" outline type="button" onClick={() => showPersonForm("showManagerForm")}>No</Button>
                              </span>
                            </p>
                          </Fragment>
                        : null }

                        <PersonList
                          values={values}
                          removePerson={removePerson}
                          personsData={values.companyManageBy === "members" ? state.formState.members : state.formState.managers}
                          personType={values.companyManageBy === "members" ? "Member" : "Manager"}
                          personListFor={values.companyManageBy} 
                          showPersonForm={() => values.companyManageBy === "members" ? showPersonForm("showMemberForm") : showPersonForm("showManagerForm")}
                        />
                        <PersonFormModal 
                          errors={errors} 
                          touched={touched} 
                          values={values} 
                          handleChange={handleChange}
                          validate={validate}
                          personType={values.companyManageBy === "members" ? "Member" : "Manager"}  
                          personFormFor={values.companyManageBy === "members" ? "members" : "managers"}
                          fieldPrefix={values.companyManageBy === "members" ? "member" : "manager"}
                          withEmail={false} // we need to set this to false once the FIS-91 is fixed
                          addPerson={addPerson}
                          showPersonForm={values.companyManageBy === "members" ? state.showMemberForm : state.showManagerForm}
                          togglePersonForm={() => values.companyManageBy === "members" ? showPersonForm("showMemberForm") : showPersonForm("showManagerForm")}
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <h3>Director Information</h3>
                        <p>Your company must have at least one director, but may have more. You may also be the only director. A director must be a legal adult (18 years of age or older) and <strong>is NOT required to live within the State of Florida.</strong></p>
                        { values.directors.length === 0 ? 
                          <Fragment>
                            <p className="mb-3">
                              <strong className="mr-3 mb-2 d-inline-block">Would you like to be a director?</strong><br/>
                              <span className="d-inline-block">
                                <Button className="pl-4 pr-4 mr-2 font-weight-bolder" color="success" outline type="button" onClick={() => addPerson("directors", values, true)}>Yes</Button>
                                <Button className="pl-4 pr-4 font-weight-bolder" color="success" outline type="button" onClick={() => showPersonForm("showDirectorForm")}>No</Button>
                              </span>
                            </p>
                          </Fragment>
                        : 
                          <p>The current corporate directors are listed below. Review this list for accuracy before continuing. You can remove any existing director by clicking the "<strong className="text-danger">x</strong>" icon to the right of that director's information.</p>
                        }

                        <PersonList
                          values={values}
                          removePerson={removePerson}
                          personsData={state.formState.directors}
                          personType="Director"
                          personListFor="directors" 
                          showPersonForm={() => showPersonForm("showDirectorForm")}
                        />
                        <PersonFormModal 
                          errors={errors} 
                          touched={touched} 
                          values={values} 
                          handleChange={handleChange}
                          validate={validate}
                          personType="Director" 
                          personFormFor="directors" 
                          fieldPrefix="director"
                          withEmail
                          addPerson={addPerson}
                          showPersonForm={state.showDirectorForm}
                          togglePersonForm={() => showPersonForm("showDirectorForm")}
                        />
                        <h3>Officer Information</h3>
                        <p>One person may be a director and hold all officer positions. Typically, a Florida corporation has a President, Secretary, and Treasurer.</p>
                        { values.officers.length === 0 ? 
                          <Fragment>
                            <p className="mb-3">
                              <strong className="mr-3 mb-2 d-inline-block">Would you like to hold all officer positions?</strong><br/>
                              <span className="d-inline-block">
                                <Button className="pl-4 pr-4 mr-2 font-weight-bolder" color="success" outline type="button" onClick={() => addPerson("officers", values, true)}>Yes</Button>
                                <Button className="pl-4 pr-4 font-weight-bolder" color="success" outline type="button" onClick={() => showPersonForm("showOfficerForm")}>No</Button>
                              </span>
                            </p>
                          </Fragment>
                        : 
                          <p>The current corporate officers are listed below. Review this list for accuracy before continuing. You can remove any existing officer by clicking the "<strong className="text-danger">x</strong>" icon to the right of that officer's information. </p>
                        }
                        <PersonList
                          values={values}
                          removePerson={removePerson}
                          personsData={state.formState.officers}
                          personType="Officer"
                          personListFor="officers" 
                          showPersonForm={() => showPersonForm("showOfficerForm")}
                        />
                        <PersonFormModal 
                          errors={errors} 
                          touched={touched} 
                          values={values} 
                          handleChange={handleChange}
                          validate={validate}
                          personType="Officer" 
                          personFormFor="officers" 
                          fieldPrefix="officer"
                          withEmail
                          addPerson={addPerson}
                          showPersonForm={state.showOfficerForm}
                          togglePersonForm={() => showPersonForm("showOfficerForm")}
                        />
                      </Fragment>
                    )
                  } 

                  <FormValidationAlert
                    submitCount={submitCount}
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                    errors={errors}
                  />

                  <ProcessingStatus showProcessing={state.isProcessing && !state.apiErrors} />
                  
                  <FormErrorModal errors={state.apiErrors} />

                  {(!form[formType].isComplete || !form[formType].orderInfoId) ?
                    <FormNav action={{prev: `/contact-info/${accountType}/${props.location.search}`}} />
                  : null }
                  
                </Form>
              )}
            </Formik>
          </Col>

          <Sidebar 
            orderDetailPids={state.orderDetailPids} 
            host={host} 
            lgr={state.formState.lgr} 
          />

        </Row>
      </Container>
    </Fragment>
  );
}

export default BusinessManagement;
