import React from "react";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem
} from 'reactstrap';

import "./style.scss";

const SkeletonCard = () => {
  return (
    <>
      <Card className="card-summary skeleton-card">
        <CardHeader tag="h5" className="mt-0">
          <span className="skeleton-header animated-placeholder"></span>
          <span className="float-right ml-auto text-small">
            <span className="dots">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </span>
          </span>
        </CardHeader>
        <ListGroup flush>
          <ListGroupItem>
            <span className="info-title">
              <span className="animated-placeholder short"></span>
            </span>
            <span className="info-data">
              <span className="animated-placeholder medium"></span>
            </span>
          </ListGroupItem>
          <ListGroupItem>
            <span className="info-title">
              <span className="animated-placeholder medium"></span>
            </span>
            <span className="info-data">
              <span className="animated-placeholder long"></span> <br/>
              <span className="animated-placeholder medium"></span>
            </span>
          </ListGroupItem>
          <ListGroupItem>
            <span className="info-title">
              <span className="animated-placeholder medium"></span>
            </span>
            <span className="info-data">
              <span className="animated-placeholder short"></span>
            </span>
          </ListGroupItem>
          <ListGroupItem>
            <span className="info-title">
              <span className="animated-placeholder short"></span>
            </span>
            <span className="info-data">
              <span className="animated-placeholder medium"></span>
            </span>
          </ListGroupItem>
        </ListGroup>
      </Card>
      <Card className="card-summary skeleton-card">
        <CardHeader tag="h5" className="mt-0">
          <span className="skeleton-header animated-placeholder"></span>
          <span className="float-right ml-auto text-small">
            <span className="dots">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </span>
          </span>
        </CardHeader>
        <ListGroup flush>
          <ListGroupItem>
            <span className="info-title">
              <span className="animated-placeholder short"></span>
            </span>
            <span className="info-data">
              <span className="animated-placeholder medium"></span>
            </span>
          </ListGroupItem>
          <ListGroupItem>
            <span className="info-title">
              <span className="animated-placeholder medium"></span>
            </span>
            <span className="info-data">
              <span className="animated-placeholder short"></span>
            </span>
          </ListGroupItem>
          <ListGroupItem>
            <span className="info-title">
              <span className="animated-placeholder short"></span>
            </span>
            <span className="info-data">
              <span className="animated-placeholder long"></span>
            </span>
          </ListGroupItem>
        </ListGroup>
      </Card>
      <Card className="card-summary skeleton-card">
        <CardHeader tag="h5" className="mt-0">
          <span className="skeleton-header animated-placeholder"></span>
          <span className="float-right ml-auto text-small">
            <span className="dots">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </span>
          </span>
        </CardHeader>
        <ListGroup flush>
          <ListGroupItem>
            <span className="info-title">
              <span className="animated-placeholder short"></span>
            </span>
            <span className="info-data">
              <span className="animated-placeholder medium"></span>
            </span>
          </ListGroupItem>
          <ListGroupItem>
            <span className="info-title">
              <span className="animated-placeholder medium"></span>
            </span>
            <span className="info-data">
              <span className="animated-placeholder short"></span>
            </span>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </>
  );
}

export default SkeletonCard;
