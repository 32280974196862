import React from "react";
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem
} from 'reactstrap';

const OfficeLocation = (props) => {
  const principalOffice  = props.data.principalOffice;
  const mailingAddress  = props.data.mailingAddress;
  const companyAddress = props.data.companyAddress;

  const editLink = `/business-info/${props.accountType}/${props.queryString}`;

  const handleEdit = event => {
    event.preventDefault();
    props.history.push(editLink);
    window.scrollTo(0, 0) // making sure that user view is top of the page
  }

  const showEdit = !(props.data.orderInfoId || props.data.isComplete);
  
  if ((principalOffice?.street1 && mailingAddress?.street1) || companyAddress?.street1) {
    return (
      <Card className="card-summary">
        <CardHeader tag="h5" className="mt-0">{companyAddress?.street1 ? "Company Address" : "Office Location"} {showEdit && <a href="//:0" onClick={handleEdit} className="edit ml-auto">Edit</a>}</CardHeader>
        <ListGroup flush>
          {principalOffice?.street1 && mailingAddress?.street1 ? 
            <>
              <ListGroupItem>
                <span className="info-title">Physical Address:</span>
                <span className="info-data">
                    {principalOffice.street1}, {principalOffice.street2}<br/>
                    {principalOffice.city}, {principalOffice.stateProvince} {principalOffice.postalCode}<br/>
                    {principalOffice.phone}
                  </span>
              </ListGroupItem>
              <ListGroupItem>
                <span className="info-title">Mailing Address:</span>
                  <span className="info-data">
                    {mailingAddress.street1}, {mailingAddress.street2}<br/>
                    {mailingAddress.city}, {mailingAddress.stateProvince} {mailingAddress.postalCode}
                  </span>
              </ListGroupItem>
            </>
          : 
            <ListGroupItem>
              <span className="info-title">Mailing Address:</span>
                <span className="info-data">
                  {companyAddress.street1}, {companyAddress.street2}<br/>
                  {companyAddress.city}, {companyAddress.stateProvince} {companyAddress.postalCode}
                </span>
            </ListGroupItem>
            }
        </ListGroup>
      </Card>
    );
  }
  else {
    return null;
  }
}

export default withRouter(OfficeLocation);
