import React from "react";
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem
} from 'reactstrap';

const SubSElection = (props) => {
  const {
    subSElection
  } = props.data;

  const editLink = `/entity-classification/${props.accountType}/${props.queryString}`;

  const handleEdit = event => {
    event.preventDefault();
    props.history.push(editLink);
    window.scrollTo(0, 0) // making sure that user view is top of the page
  }

  const showEdit = !(props.data.orderInfoId || props.data.isComplete);
 
  return (
    <Card className="card-summary">
      <CardHeader tag="h5" className="mt-0">Sub-S Election {showEdit && <a href="//:0" onClick={handleEdit} className="edit ml-auto">Edit</a>}</CardHeader>
      <ListGroup flush>
        <ListGroupItem>
          <span className="info-title">File as Sub-S:</span>
          <span className="info-data">{subSElection ? "Yes" : "No"}</span>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
}

export default withRouter(SubSElection);
