const initialCorpFormData = {
  id: 0,
  account: {
    id: 0,
    userName: "",
    person: {
      id: 0,
      title: "",
      firstName: "",
      middleName: "",
      lastName: "",
      emailAddress: "",
      mobilePhone: "",
      businessPhone: "",
      ssn: "",
      sex: "",
      race: "",
      dateOfBirth: "",
      driverLicenseNumber: "",
      addresses: [
        {
          id: 0,
          street1: "",
          street2: "",
          city: "",
          postalCode: "",
          county: "",
          stateProvince: "FL",
          country: "US",
          phone: "",
          phoneExt: "",
          fax: "",
        }
      ]
    }
  },
  serviceType: 0,
  nameCandidates: [
    {
      id: 0,
      name: "",
      suffix: "",
      choice: 0
    }
  ],
  name: {
    id: 0,
    name: "",
    suffix: "",
    choice: 0
  },
  principalOffice: {
    id: 0,
    street1: "",
    street2: "",
    city: "",
    postalCode: "",
    county: "",
    stateProvince: "FL",
    country: "US",
    phone: "",
    phoneExt: "",
    fax: "",
  },
  mailingAddress: {
    id: 0,
    street1: "",
    street2: "",
    city: "",
    postalCode: "",
    county: "",
    stateProvince: "FL",
    country: "US",
    phone: "",
    phoneExt: "",
    fax: "",
  },
  pointOfContact: {
    id: 0,
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    emailAddress: "",
    mobilePhone: "",
    businessPhone: "",
    ssn: "",
    sex: "",
    race: "",
    dateOfBirth: "",
    driverLicenseNumber: "",
    addresses: [
      {
        id: 0,
        street1: "",
        street2: "",
        city: "",
        postalCode: "",
        county: "",
        stateProvince: "",
        country: "",
        phone: "",
        phoneExt: "",
        fax: "",
      }
    ]
  },
  registeredAgent: {
    id: 0,
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    emailAddress: "",
    mobilePhone: "",
    businessPhone: "",
    ssn: "",
    sex: "",
    race: "",
    dateOfBirth: "",
    driverLicenseNumber: "",
    addresses: [
      {
        id: 0,
        street1: "",
        street2: "",
        city: "",
        postalCode: "",
        county: "",
        stateProvince: "",
        country: "",
        phone: "",
        phoneExt: "",
        fax: "",
        created: "",
        lastModified: ""
      }
    ]
  },
  incorporatorAddress: {
    id: 0,
    street1: "",
    street2: "",
    city: "",
    postalCode: "",
    county: "",
    stateProvince: "",
    country: "",
    phone: "",
    phoneExt: "",
    fax: "",
  },
  numStockShares: 0,
  stockParValue: 0,
  subSElection: false,
  businessActivity: "",
  businessActivityOther: "",
  incorporationDate: "",
  firstWagesDate: "",
  firstDayOfBusiness: "",
  numHouseholdEmployees: 0,
  numAgriculturalEmployees: 0,
  numOtherEmployees: 0,
  directors: [
    {
      id: 0,
      companyId: 0,
      person: {
        id: 0,
        title: "",
        firstName: "",
        middleName: "",
        lastName: "",
        emailAddress: "",
        mobilePhone: "",
        businessPhone: "",
        ssn: "",
        sex: "",
        race: "",
        dateOfBirth: "",
        driverLicenseNumber: "",
        addresses: [
          {
            id: 0,
            street1: "",
            street2: "",
            city: "",
            postalCode: "",
            county: "",
            stateProvince: "",
            country: "",
            phone: "",
            phoneExt: "",
            fax: "",
          }
        ]
      }
    }
  ],
  officers: [
    {
      id: 0,
      title: "",
      person: {
        id: 0,
        title: "",
        firstName: "",
        middleName: "",
        lastName: "",
        emailAddress: "",
        mobilePhone: "",
        businessPhone: "",
        ssn: "",
        sex: "",
        race: "",
        dateOfBirth: "",
        driverLicenseNumber: "",
        addresses: [
          {
            id: 0,
            street1: "",
            street2: "",
            city: "",
            postalCode: "",
            county: "",
            stateProvince: "",
            country: "",
            phone: "",
            phoneExt: "",
            fax: "",
          }
        ]
      }
    }
  ],
  einDirectorId: 0,
  form8832DirectorId: 0,
  electronicSig: true,
  incorporatorSig: "",
  status: "",
  orderInfoId: 0,
  isComplete: false,
  dateRegistrationCompleted: "",
  dateSuppliesOrdered: "",
  companyPurpose: "",
  effectiveDate: "",
  llcEntityCode: "",
  llcEntityString: "",
  llcManagementCode: 1,
  irsContactPerson: {
    id: 0,
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    emailAddress: "",
    mobilePhone: "",
    businessPhone: "",
    ssn: "",
    sex: "",
    race: "",
    dateOfBirth: "",
    driverLicenseNumber: "",
    addresses: [
      {
        id: 0,
        street1: "",
        street2: "",
        city: "",
        postalCode: "",
        county: "",
        stateProvince: "",
        country: "",
        phone: "",
        phoneExt: "",
        fax: "",
      }
    ]
  },
  fictitiousName: "",
  registrationVars: {
    fisPackage: "", // Silver, LLC or DBA?
    isAdvanced: false,
    isCorporation: false,
    lgr: "",
    addEIN: false,
    addDBA: false,
    willFileEIN: false,
    willFile8832: false,
    unlinkMenuSteps: true,
    useElectronicSig: true,
    returnToSummary: true,
    llcFilingAsCorp: false,
    contactHasPOBox: false,
    doFileSubS: false,
    takeSurvey: false,
    isDirector: false,
    addAnotherDirector: false,
    addAnotherMember: false,
    addAnotherOwner: false,
    isAllOfficers: false,
    addAnotherOfficer: false,
    officerConfirmed: false,
    willManage: false,
    ownedByOtherEntity: false
  },
  optionalProducts: [],
  optionalServices: [],
  managerManagedEinPerson: null,
  form8832Member: null,
  wizardBranchPoints: [],
}

export default initialCorpFormData;