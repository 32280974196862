import { UserManager } from 'oidc-client';
import { storeUserError, storeUser } from '../store/actions/authActions'
import queryString from "query-string";
import axios from 'axios';


// console.log("authService called")
const { Identity, PublicUri } = JSON.parse(sessionStorage.getItem("appsettings"));
let FIS_AUTHORITY = Identity.Authority;

const REACT_APP_ENV = process.env.REACT_APP_ENV;
// console.log("REACT_APP_ENV:", REACT_APP_ENV)
// console.log("FIS_AUTHORITY:", FIS_AUTHORITY)
if (REACT_APP_ENV === 'staging') {
  FIS_AUTHORITY = FIS_AUTHORITY.replace('dllogin', 'mllogin');
}
else if (REACT_APP_ENV === 'production') {
  FIS_AUTHORITY = FIS_AUTHORITY.replace('dllogin', 'login');
}
// console.log("FIS_AUTHORITY", FIS_AUTHORITY)

const config = {
  authority: FIS_AUTHORITY,
  client_id: Identity.ClientId,
  redirect_uri: PublicUri + "/callback", // callback component to process user login via IS4
  automaticSilentRenew: true,
  silent_redirect_uri: PublicUri + "/callback-renew", // callback component that process auth renewal
  // post_logout_redirect_uri: PublicUri + "/logged-out", // url to redirect users after they logout or save their application for later
  post_logout_redirect_uri: PublicUri, // url to redirect users after they logout or save their application for later
  grant_type: "authorization_code",
  response_type: "code",
  scope: Identity.Scope,
  // accessTokenExpiringNotificationTime: 3540, // used for testing silent renew 59min = 3540sec
};

// const config = {
//   authority: 'https://demo.identityserver.io/',
//   client_id: 'spa',
//   redirect_uri: "https://localhost:3000/login",
//   silent_redirect_uri: "https://localhost:3000/silent-renew",
//   post_logout_redirect_uri: "https://localhost:3000/logout",
//   response_type: 'code',
//   scope: 'openid profile email api',
// };

const userManager = new UserManager(config);

export async function loadUserFromStorage(store) {
  try {
    let user = await userManager.getUser()
    // console.log("user", user)
    if (!user) { return store.dispatch(storeUserError()) }
    store.dispatch(storeUser(user))
  } catch (e) {
    console.error(`User not found: ${e}`)
    store.dispatch(storeUserError())
  }
}

export function signinRedirect(params) {
  return userManager.signinRedirect(params)
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback()
}

export function signinSilent() {
  return userManager.signinSilent()
}

export function signinSilentCallback() {
  return userManager.signinSilentCallback()
}

export function getAccessToken(token) {
  const tokenUrl = `${FIS_AUTHORITY}/connect/token`;
  const data = {
    grant_type: "one_time_token",
    scope: `${Identity.Scope} offline_access`,
    client_id: Identity.ClientId,
    token: token,
  }
  const options = {
    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
  };
  return axios.post(tokenUrl, queryString.stringify(data), options);
}

// export async function createSignoutRequest() {
//   const signout_request = await userManager.createSignoutRequest();
//   axios.get(signout_request.url, {
//     headers: {'Access-Control-Allow-Origin': '*'},
//     crossdomain: true
//   }).then(response => {
//     console.log(response);
//     userManager.clearStaleState();
//     userManager.removeUser();
//   }).catch(e => console.log(e));
// }

// export function createSignoutRequest() {
//   return userManager.createSignoutRequest().then(signout_request => {
//     axios.get(signout_request.url).then(response => {
//       console.log(response)
//       userManager.clearStaleState()
//       userManager.removeUser()
//     }).catch(e => console.log(e))
//   })
// }

// export function signoutPopup() {
//   return userManager.signoutPopup()
// }

// export function signoutRedirect() {
//   userManager.clearStaleState()
//   userManager.removeUser()
//   return userManager.signoutRedirect()
// }

export async function signoutRedirect() {
  let user = await userManager.getUser()
  let userId = user?.id_token
  userManager.clearStaleState()
  userManager.removeUser()
  return userManager.signoutRedirect({ id_token_hint: userId })
}

export function signoutRedirectCallback() {
  userManager.clearStaleState()
  userManager.removeUser()
  return userManager.signoutRedirectCallback()
}

export default userManager
