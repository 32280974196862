import React from 'react'
import { useSelector } from 'react-redux'
import ErrorState from "../pages/ErrorState"

function ErrorHandler({ children }) {
  const error = useSelector(state => state.app.error)

  return error.state
    ? (<ErrorState error={error} />)
    : (children)
}

export default ErrorHandler;