import React from "react";
import { withRouter } from 'react-router-dom';

import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem
} from 'reactstrap';

const BusinessActivity = (props) => {
  const {
    businessActivity, 
    businessActivityOther, 
    companyPurpose,
  } = props.data;

  const editLink = `/business-info/${props.accountType}/${props.queryString}`;

  const handleEdit = event => {
    event.preventDefault();
    props.history.push(editLink);
    window.scrollTo(0, 0) // making sure that user view is top of the page
  }

  const showEdit = !(props.data.orderInfoId || props.data.isComplete);

  if (businessActivity || businessActivityOther || companyPurpose) {
    return (
      <Card className="card-summary">
        <CardHeader tag="h5" className="mt-0">Business Activity {showEdit && <a href="//:0" onClick={handleEdit} className="edit ml-auto">Edit</a>}</CardHeader>
        <ListGroup flush>
          <ListGroupItem>
            <span className="info-title">Business Activity:</span>
            <span className="info-data">{businessActivity}</span>
          </ListGroupItem>
          { businessActivityOther ? 
            <ListGroupItem>
              <span className="info-title">Specific Activity:</span>
              <span className="info-data">{businessActivityOther}</span>
            </ListGroupItem>
          : null }
          <ListGroupItem>
            <span className="info-title">Company Purpose:</span>
            <span className="info-data">{companyPurpose}</span>
          </ListGroupItem>
        </ListGroup>
      </Card>
    );
  }
  else {
    return null;
  }
}

export default withRouter(BusinessActivity);

