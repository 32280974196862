import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const FormErrorModal = (props) => {
  const {
    errors, // will be coming from the parent component or API
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    setModal(true);
  }, [errors]);

  const theError = errors || [];
  const errorList = theError.map((item, index) => (
    <li key={index}>{item}</li>
  ))

  if (errorList.length > 0) {
    return (
      <Modal centered scrollable fade={false} backdrop={"static"} isOpen={modal} toggle={toggle} className="form-validation-error">
        <ModalHeader tag="h4">Oops, something went wrong...</ModalHeader>
        <ModalBody>
          { errors[0] === "401 Unauthorized" || errors[0] === "403 Forbidden" ?
            <p className="mb-1">{errors[0]}: Looks like you're not authorized to access this account or you're session has timed out. If you continue to see this error please logout and login to try again.</p>
          : errors[0] === "400 Bad Request" || errors[0] === "404 Not Found" || errors[0] === "500 Internal Server Error" ? 
            <p className="mb-1">{errors[0]}: Looks like we're experiencing some technical difficulties. Please <a href="//:0" onClick={() => window.location.reload()}>refresh this page</a> and try again.</p>
          :
            <ol className="error-list">{errorList}</ol>
          }
        </ModalBody>
        <ModalFooter>
          { errors[0] !== "404 Not Found" ?
            <Button className="close-modal" color="primary" onClick={toggle}>OK</Button>
          :
            <Button className="close-modal" color="primary" onClick={() => window.location.reload()}>OK</Button>
          }
        </ModalFooter>
      </Modal>
    );
  }
  else {
    return null;
  }

}

export default FormErrorModal;
