import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import queryString from "query-string";
import { Helmet } from "react-helmet";
import { Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import API from "../services/apiService";
// import { getAccessToken } from "../services/authService";
import { generatePids } from "../utils/productMapping";
import Sidebar from "../components/Sidebar";
import StateDropdown from "../components/StateDropdown";
import CountyDropdown from "../components/CountyDropdown";
import ProgressStatus from "../components/ProgressStatus";
import FormNav from "../components/FormNav";
import FormValidationAlert from "../components/FormValidationAlert";
import ProcessingStatus from "../components/ProcessingStatus";
import FormErrorModal from "../components/FormErrorModal";
import ApplicationCompleteAlert from "../components/ApplicationCompleteAlert";
import { formErrorHandler, appErrorHandler } from "../utils/errorHandlerUtil";
import InputMask from 'react-input-mask';
import { storeApplication } from '../store/actions/formActions'	
import { accountInfoState } from "../utils/storeState";

// import { createSignoutRequest } from '../services/authService'

const CreateAccount = props => {
  const form = useSelector(state => state.form);
  const dispatch = useDispatch()

  const pageTitle = "Florida Incorporation Service Registration | Create Account",
        registrationStep = 1,
        accountType = props.match.params.accountType?.toLowerCase(),
        orderDetails = JSON.parse(sessionStorage.getItem("orderDetails")) || [],
        host = queryString.parse(props.location.search)?.host || null,
        formType = accountType === "dba" ? "dba" : "corp"; 

  const { pid, lgr, pkg } = queryString.parse(props.location.search);
  if (!pid || !lgr || !host || (accountType !== "dba" ? !pkg : false)) {
    appErrorHandler("Custom Error", `Missing query string ${props.history.location.pathname}${props.history.location.search}`, dispatch)
    // props.history.replace(`/error/${accountType}/${props.location.search}`);
  }
  // let orderDetailPidsState = state.orderDetailPids;
  if (orderDetails.length && (Number(orderDetails[0].id) !== Number(pid) || orderDetails[0].lgr !== lgr?.toLowerCase())) {
    sessionStorage.removeItem('orderDetails');
    sessionStorage.removeItem('productsData');
    sessionStorage.removeItem('fisFormState');
    props.history.replace(`/business-info/${accountType}/${props.location.search}`);
    // props.history.replace(`/error/${accountType}/${props.location.search}`);
  }
  
  const initState = {
    formState: {
      firstName: form[formType].pointOfContact?.firstName || form[formType].account?.person?.firstName || "",
      lastName: form[formType].pointOfContact?.lastName || form[formType].account?.person?.lastName || "",
      street1: form[formType].pointOfContact?.addresses[0]?.street1 || form[formType].account?.person?.addresses[0]?.street1 || "",
      street2: form[formType].pointOfContact?.addresses[0]?.street2 || form[formType].account?.person?.addresses[0]?.street2 || "",
      city: form[formType].pointOfContact?.addresses[0]?.city || form[formType].account?.person?.addresses[0]?.city || "",
      postalCode: form[formType].pointOfContact?.addresses[0]?.postalCode || form[formType].account?.person?.addresses[0]?.postalCode || "",
      county: form[formType].pointOfContact.addresses[0]?.county ||  form[formType].account?.person?.addresses[0]?.county ||"",
      state: form[formType].pointOfContact.addresses[0]?.stateProvince || form[formType].account?.person?.addresses[0]?.stateProvince || "FL",
      country: form[formType].pointOfContact?.addresses[0]?.country || form[formType].account?.person?.addresses[0]?.country || "US",
      phone: form[formType].pointOfContact?.businessPhone ||  form[formType].account?.person?.businessPhone ||"",
      email: form[formType].pointOfContact?.emailAddress || form[formType].account?.person?.emailAddress || "",
      validateEmail: form[formType].pointOfContact?.emailAddress || form[formType].account?.person?.emailAddress || "",
      // username: form[formType].account?.userName || "",
      // password: "",
      fisPackage: form[formType].registrationVars?.fisPackage || queryString.parse(props.location.search).pkg || null,
      lgr: form[formType].registrationVars?.lgr || queryString.parse(props.location.search).lgr?.toLowerCase() || "",
      optionalProducts: form[formType].optionalProducts || [], // array can contain one or more of the following: SubSElection, BylawsAndMinutes, CertificateOfStatus, CorporateEmbosser, CorpSealsAndCerts, Form8832Filing, Form8832Filing, LLCSealsAndCerts, OperationalAgreement 
      optionalServices: form[formType].optionalServices || [],
    },
    // isProcessing: true,
  };

  const checkedProducts = Object.keys(initState.formState).filter(key => initState.formState[key]); // generate product keys for generatePids()
  const generatedPids = generatePids([...checkedProducts, ...initState.formState.optionalProducts, ...initState.formState.optionalServices])
  const uniquePids = new Set([Number(pid), ...generatedPids]); // removing duplicate PIDs
  // const productKeys = generateKeys([...uniquePids]); // object of product keys with true value

  const [state, setState] = useState({
    ...initState,
    orderDetailPids: [...uniquePids]
  });

  const handleFormSubmit = (values, actions, nextUrl) => {
    // check if application has been completed, if yes then don't allow user to update
    // if (form[formType].isComplete || form[formType].orderInfoId) {
    //   formErrorHandler('This application has been completed, you are no longer allowed to make changes', setApiErrorState);
    //   return;
    // }
    // console.log(values);
    setState(state => ({
      ...state,
      formState: values,
      isProcessing: true,
    }));

    const goToNextPage = (apiData) => {
      sessionStorage.removeItem('fisFormState'); // deleting current formState from sessionStorage
      setState(state => ({
        ...state,
        isProcessing: false,
      }));
      // creating object that matched the redux state object to prevent removing any key/values that's not updated
      const applicationState = accountInfoState(accountType, form, apiData);
      dispatch(storeApplication(applicationState)) // sets redux store with form info
      actions.resetForm(true);
      // nextUrl custom to this page only
      const nextUrl = accountType === "dba" ? `/business-ownership/dba/${props.location.search}` : `/business-management/${accountType}/${props.location.search}`;
      props.history.push(nextUrl); // push user to next page after form submission or api call
      window.scrollTo(0, 0) // making sure that user view is top of the page
    }

    let accountInfo = {
      person: {
        firstName: values.firstName,
        lastName: values.lastName,
        emailAddress: values.email,
        businessPhone: values.phone,
      }
    }

    API.patchAccount(form[formType].account?.id, accountInfo).then(companyData => {
      // console.log("Created/Updated Account/Company/DBA:", companyData.data);
      const accountId = form[formType].account?.id;
      const companyId = form[formType]?.id;
      const dbaId = form[formType]?.id;
      const companyInfo = {
        pointOfContact: {
          firstName: values.firstName,
          lastName: values.lastName,
          emailAddress: values.email,
          businessPhone: values.phone,
          addresses: [
            {
              street1: values.street1,
              street2: values.street2,
              city: values.city,
              postalCode: values.postalCode,
              county: values.county,
              stateProvince: values.state,
              country: values.country,
              phone: values.phone,
            }
          ]
        },
        registrationVars: {
          lgr: values.lgr,
          // fisPackage: values.fisPackage,
          // isCorporation: accountType !== "dba" ? true : false,
        },
      }
      // values used to check if FIS is the registered agent is predefined, we'll need to update this if any info changes
      if (form[formType].registeredAgent && form[formType].registeredAgent?.id !== 0 && form[formType].registeredAgent?.emailAddress !== "info@floridaincorporationservice.com" && form[formType].registeredAgent?.businessPhone !== ("407-629-4811" || "1-800-370-2942")) {
        companyInfo.registeredAgent = companyInfo.pointOfContact;
      }
      const dbaInfo = {
        ...companyInfo,
        person: {
          firstName: values.firstName,
          lastName: values.lastName,
          emailAddress: values.email,
          businessPhone: values.phone,
          addresses: [
            {
              street1: values.street1,
              street2: values.street2,
              city: values.city,
              postalCode: values.postalCode,
              county: values.county,
              stateProvince: values.state,
              country: values.country,
              phone: values.phone,
            }
          ]
        },
      }
      return accountType !== "dba" ? API.patchCompany(accountId, companyId, companyInfo) : API.patchDbaApplicant(accountId, dbaId, dbaInfo);
    })
    .then(companyData => {
      // console.log("Patched Company/DBA:", companyData.data);
      sessionStorage.removeItem('completedApplicationData');

      goToNextPage(companyData.data);
    })
    .catch(err => {
      // console.log(err)
      formErrorHandler(err, setApiErrorState);
    });
  }

  const setApiErrorState = (err) => {
    setState(state => ({
      ...state,
      apiErrors: err,
    }));
  }

  // const checkUsername = event => {
  //   const { value } = event.target;
  //   const userName = {
  //     username: value
  //   }
  //   if (value) {
  //     API.checkUsername(userName).then(userNameData => {
  //       setState(state => ({
  //         ...state,
  //         usernameUnavailable: userNameData.data?.isUser, // value is always true/false
  //       }));
  //     })
  //     .catch(err => {
  //       console.log("Couldn't verify if username is available!",err)
  //       setApiErrorState(["Couldn't verify if username is available, you can still try it."]);
  //     });
  //   }
  // }

  // const [showPassword, setShowPassword] = useState(false);
  // const togglePassword = () => {
  //   setShowPassword(!showPassword);
  // }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'First Name is too Short!').max(255, 'First Name is too Long!').required('First Name is required'),
    lastName: Yup.string().min(2, 'Last Name is too Short!').max(255, 'Last Name is too Long!').required('Last Name is required'),
    phone: Yup.string().matches(/^[1-9]\d{2}-\d{3}-\d{4}$/, { message: 'Phone must match this format: XXX-XXX-XXXX', excludeEmptyString: true }).required('Phone number is required'),
    email: Yup.string().email().required('Email is required'),
    validateEmail: Yup.string().oneOf([Yup.ref('email'), null], "Email must match").required('Validate Email is required'),
    // username: Yup.string().when({
    //   is: () => newApplication,
    //   then: Yup.string().matches(/^\S+$/, { message: 'Username cannot contain spaces.', excludeEmptyString: true }).min(6, 'Username is too Short!').max(255, 'Username is too Long!').required('Username is required'),
    // }),
    // password: Yup.string().when({
    //   is: () => newApplication,
    //   then: Yup.string().matches(/^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/, { message: 'Password must be at least 6 characters long, contain at least 1 letter and 1 number, and cannot contain spaces.', excludeEmptyString: true }).required('Password is required'),
    // }),
    // password: Yup.string().matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, { message: 'Password should at least be 8 characters long, contain at least 1 uppercase letter, 1 lowercase letter, and a number.', excludeEmptyString: true }).required('Password is required'),
    street1: Yup.string().min(2, 'Address is  too Short!').max(255, 'Address is too Long!').required('Address is required'),
    street2: Yup.string().min(2, 'Suite/Apt # is oo Short!').max(255, 'Suite/Apt # is too Long!'),
    city: Yup.string().min(2, 'City is too Short!').max(255, 'City is too Long!').required('City is required'),
    county: Yup.string().when({
      is: () => accountType === 'dba',
      then: Yup.string().min(2, 'County is too Short!').max(255, 'County is oo Long!').required('County is required'),
    }),
    state: Yup.string().min(2, 'State is too Short!').max(255, 'State is oo Long!').required('State is required'),
    postalCode: Yup.string().matches(/^\d{5}(?:[-\s]\d{4})?$/, { message: 'Zip Code must match this format: XXXXX or XXXXX-XXXX', excludeEmptyString: true }).required('Zip Code is required'),
  })

  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href="https://www.floridaincorporationservice.com" />
      </Helmet>

      <ProgressStatus registrationStep={registrationStep} />

      <Container>
        <Row className="justify-content-center">
          {form[formType].isComplete || form[formType].orderInfoId ? 
            <ApplicationCompleteAlert />
          : null }          
          <Col lg="8" md="10" sm="12">
            <Formik
              enableReinitialize={true}
              initialValues={state.formState}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => handleFormSubmit(values, actions)}
            >
              {({ isValid, errors, touched, values, handleChange, submitCount, isSubmitting }) => (
                <Form>
                  <h2>Primary Contact Information</h2>
                  <p>Please provide the following information for the designated Point of Contact for this registration. This will be our primary way of contacting you.</p>
                  <FormGroup className="form-row">
                    <Label sm={3} className="text-sm-right" for="firstname">First Name</Label>
                    <Col sm={8}>
                      <Input tag={Field} invalid={errors.firstName && touched.firstName} onChange={handleChange} value={values.firstName} type="text" name="firstName" id="firstName" data-hj-masked />
                    </Col>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label sm={3} className="text-sm-right" for="lastName">Last Name</Label>
                    <Col sm={8}>
                      <Input tag={Field} invalid={errors.lastName && touched.lastName} onChange={handleChange} value={values.lastName} type="text" name="lastName" id="lastName" data-hj-masked />
                    </Col>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label sm={3} className="text-sm-right" for="phone">Phone</Label>
                    <Col sm={8}>
                      {/* <Input tag={Field} invalid={errors.phone && touched.phone} onChange={handleChange} type="tel" name="phone" id="phone" data-hj-masked /> */}
                      <InputMask className={errors.phone && touched.phone ? "form-control is-invalid" : "form-control"} onChange={handleChange} value={values.phone} type="tel" name="phone" id="phone" data-hj-masked mask="999-999-9999" maskChar={"_"} placeholder="___-___-____" />
                    </Col>
                  </FormGroup>
                  {/* { !auth.user ? 
                    <FormGroup className="form-row mb-0 mb-sm-3">
                      <p className="offset-sm-3 mb-0 col-sm-8 font-weight-lighter">Your username and password reset link will be sent immediately to the email address below.</p>
                    </FormGroup>
                  : null } */}
                  <FormGroup className="form-row">
                    <Label sm={3} className="text-sm-right" for="email">Email</Label>
                    <Col sm={8}>
                      <Input tag={Field} invalid={errors.email && touched.email} onChange={handleChange} value={values.email} type="email" name="email" id="email" data-hj-masked />
                    </Col>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label sm={3} className="text-sm-right" for="validateEmail">Validate Email</Label>
                    <Col sm={8}>
                      <Input tag={Field} invalid={errors.validateEmail && touched.validateEmail} onChange={handleChange} value={values.validateEmail} type="email" name="validateEmail" id="validateEmail" data-hj-masked />
                    </Col>
                  </FormGroup>
                  {/* { newApplication ? 
                    <Fragment>
                      <div className="form-row justify-content-end">
                        <p className="col-sm-9 mb-0 mb-sm-2 mb-md-3 font-weight-light">With this username and password, you can return to this website at any time to complete your registration.</p>
                      </div>
                      <FormGroup className="form-row">
                        <Label sm={3} className="text-sm-right" for="username">Username</Label>
                        <Col sm={8}>
                          <Input tag={Field} invalid={(errors.username && touched.username) || state.usernameUnavailable} onChange={handleChange} value={values.username} type="text" name="username" id="username" data-hj-masked data-private onBlur={checkUsername} />
                          { state.usernameUnavailable ?
                            <small className="form-text text-danger">The username you entered is already taken, please use a different one.</small> 
                          : null }
                        </Col>
                      </FormGroup>
                      <FormGroup className="form-row">
                        <Label sm={3} className="text-sm-right" for="password">Password</Label>
                        <Col sm={8} className="password-field">
                          <Input tag={Field} invalid={errors.password && touched.password} onChange={handleChange} value={values.password} type={showPassword ? "text" : "password"} name="password" id="password" data-hj-masked data-private />
                          <span className={showPassword ? "show-hide-icon show" : "show-hide-icon"} onClick={togglePassword}></span>
                          <small className="form-text text-muted">Password must be at least 6 characters long, contain at least 1 letter and 1 number, and cannot contain spaces.</small> 
                        </Col>
                      </FormGroup>
                    </Fragment>
                  : null } */}
                  <h2>Contact Person Mailing Address</h2>
                  <p><strong className="text-danger">All packages and other correspondence from our office will be sent to the address below.</strong></p>
                  <p>Please be sure to give us a complete and occupied address, including suite or apartment numbers. If your order is returned because of a bad address, we will have to charge you for redelivery of your items.</p>
                  <FormGroup className="form-row">
                    <Label sm={3} className="text-sm-right" for="street1">Address</Label>
                    <Col sm={8}>
                      <Input tag={Field} invalid={errors.street1 && touched.street1} onChange={handleChange} value={values.street1} type="text" name="street1" id="street1" data-hj-masked />
                    </Col>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label sm={3} className="text-sm-right" for="street2">Suite. Apt. #, etc.</Label>
                    <Col sm={8}>
                      <Input tag={Field} invalid={errors.street2 && touched.street2} onChange={handleChange} value={values.street2} type="text" name="street2" id="street2" data-hj-masked />
                    </Col>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label sm={3} className="text-sm-right" for="city">City</Label>
                    <Col sm={8}>
                      <Input tag={Field} invalid={errors.city && touched.city} onChange={handleChange} value={values.city} type="text" name="city" id="city" data-hj-masked />
                    </Col>
                  </FormGroup>
                  { accountType === "dba" ? 
                    <FormGroup className="form-row">
                      <Label sm={3} className="text-sm-right" for="county">County</Label>
                      <Col sm={8}>
                        <CountyDropdown invalid={errors.county && touched.county} onChange={handleChange} value={values.county} name="county" id="county" data-hj-masked />
                      </Col>
                    </FormGroup>
                    :
                    <FormGroup className="form-row">
                      <Label sm={3} className="text-sm-right" for="state">State</Label>
                      <Col sm={8}>
                        <StateDropdown invalid={errors.state && touched.state} onChange={handleChange} value={values.state} name="state" id="state" data-hj-masked />
                      </Col>
                    </FormGroup>
                  }

                  <FormGroup className="form-row">
                    <Label sm={3} className="text-sm-right" for="postalCode">Zip Code</Label>
                    <Col sm={8}>
                      <Input tag={Field} invalid={errors.postalCode && touched.postalCode} onChange={handleChange} value={values.postalCode} type="text" name="postalCode" id="postalCode" data-hj-masked />
                    </Col>
                  </FormGroup>
                  
                  <FormValidationAlert
                    submitCount={submitCount}
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                    errors={errors}
                  />   
                                
                  <ProcessingStatus showProcessing={state.isProcessing && !state.apiErrors} />

                  <FormErrorModal errors={state.apiErrors} />
                  
                  {(!form[formType].isComplete || !form[formType].orderInfoId) ?
                    <FormNav action={{prev: `/business-info/${accountType}/${props.location.search}`}} hideSaveButton={true} />
                  : null }

                  
                </Form>
              )}
            </Formik>
            
          </Col>

          <Sidebar 
            orderDetailPids={state.orderDetailPids} 
            host={host} 
            lgr={state.formState.lgr} 
          />

        </Row>
      </Container>
    </Fragment>
  );
}

export default CreateAccount;
