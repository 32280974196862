import React, { Fragment, useState, useEffect } from "react";
import {
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter
} from 'reactstrap';
import { Field } from "formik";
import * as Yup from 'yup';
import StateDropdown from "../StateDropdown";
import CountyDropdown from "../CountyDropdown";

const PersonForm = props => {
  const initialState = {
    personFormFor: props.personFormFor,
    // fieldPrefix: props.fieldPrefix === undefined ? "" : `${props.fieldPrefix}_`,
    showModal: false,
    personFormErrors: props.errors,
    touched: {
      title: false,
      firstName: false,
      lastName: false,
      street1: false,
      street2: false,
      city: false,
      county: false,
      stateProvince: false,
      postalCode: false,
      emailAddress: false,
    },
    title: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    street1: "",
    street2: "",
    city: "",
    postalCode: "",
    county: "",
    stateProvince: "FL",
    country: "US",
  }
  const [state, setState] = useState(initialState)

  useEffect(() => {
    setState(state => ({
      ...state,
      personFormFor: props.personFormFor,
      showModal: props.showPersonForm,
      touched: {
        title: false,
        firstName: false,
        lastName: false,
        street1: false,
        street2: false,
        city: false,
        county: false,
        stateProvince: false,
        postalCode: false,
        emailAddress: false,
      }
    }))
  }, [props.personFormFor, props.showPersonForm])

  const toggleModal = () => {
    setState(state => ({
      ...state,
      showModal: !state.showModal,
      personFormErrors: {},
    }))
    props.togglePersonForm()
  }

  const handleChange = event => {
    const { name, value, type, checked } = event.target;
    const theValue = type === 'checkbox' ? checked : value;
    setState(state => ({
      ...state,
      [name]: theValue

    }))
    validateField(event)
  }

  const validateField = event => {
    const { name } = event.target;
    const theName = name.split('.').pop();
    setState(state => ({
      ...state,
      touched: {
        ...state.touched,
        [theName]: true,
      },
    }))
    tempPersonInfoSchema.validate(state, {abortEarly: false}).then(() => {
      setState(state => ({
        ...state,
        personFormErrors: {},
      }))
    })
    .catch((error) => {
      if (error.inner) {
        // console.log(error); // Validation Error Object
        // console.log(error.errors);
        const errorObj = error.inner.reduce((obj, key) => ({ ...obj, [key.path.split('.').pop()]: key.message }), {})
        // console.log(errorObj)
        setState(state => ({
          ...state,
          personFormErrors: errorObj,
        }))
      }
    })
  }

  const validatePersonForm = (values) => {
    setState(state => ({
      ...state,
      touched: {
        title: true,
        firstName: true,
        lastName: true,
        street1: true,
        street2: true,
        city: true,
        county: true,
        stateProvince: true,
        postalCode: true,
        emailAddress: true,
      },
    }))
    tempPersonInfoSchema.validate(state, { abortEarly: false }).then(() => {
      const formValues = {
        ...values,
        tempPersonInfo: {
          ...values.tempPersonInfo,
          [props.personFormFor]: {
            // ...[props.personFormFor],
            title: state.title,
            person: {
              title: state.title,
              firstName: state.firstName,
              lastName: state.lastName,
              emailAddress: state.emailAddress,
              addresses: [
                {
                  street1: state.street1,
                  street2: state.street2,
                  city: state.city,
                  postalCode: state.postalCode,
                  county: state.county,
                  stateProvince: state.stateProvince,
                  country: state.country,
                }
              ]
            },
          },
        },
      }
      // console.log("state", state)
      // console.log("formValues", formValues)
      props.addPerson(props.personFormFor, formValues)
      setState(state => ({
        ...state,
        title: "",
        firstName: "",
        lastName: "",
        emailAddress: "",
        street1: "",
        street2: "",
        city: "",
        postalCode: "",
        county: "",
        stateProvince: "",
        country: "US",
        personFormErrors: {},
        touched: {
          title: false,
          firstName: false,
          lastName: false,
          street1: false,
          street2: false,
          city: false,
          county: false,
          stateProvince: false,
          postalCode: false,
          emailAddress: false,
        },
      }))
    })
    .catch((error) => {
      if (error.inner) {
        // console.log(error); // Validation Error Object
        // console.log(error.errors);
        // have to rename the object keys and make sure that it's a one level error object using .split('.').pop()
        const errorObj = error.inner.reduce((obj, key) => ({ ...obj, [key.path.split('.').pop()]: key.message }), {})
        // console.log(errorObj)
        setState(state => ({
          ...state,
          personFormErrors: errorObj,
        }))
      }
    })
  }

  const tempPersonInfoSchema = Yup.object().shape({
    title: Yup.string().when({
      is: () => props.personType === "Officer",
      then: Yup.string().min(2, 'Position is too Short!').max(255, 'Position is too Long!').required('Position is required'),
    }),
    firstName: Yup.string().min(2, 'First name is too Short!').max(255, 'First name is too Long!').required('First name is required'),
    lastName: Yup.string().min(2, 'Last name is too Short!').max(255, 'Last name is too Long!').required('Last name is required'),
    // email: Yup.string().email().required('Email is required'),
    emailAddress: Yup.string().when({
      is: () => props.withEmail,
      then: Yup.string().email().required('Email is required'),
    }),
    street1: Yup.string().min(2, 'Address is  too Short!').max(255, 'Address is too Long!').required('Address is required'),
    // street2: Yup.string().min(2, 'Suite/Apt # is too Short!').max(255, 'Suite/Apt # is too Long!'),
    city: Yup.string().min(2, 'City is too Short!').max(255, 'City is too Long!').required('City is required'),
    // county: Yup.string().min(2, 'County is too Short!').max(255, 'Position is too Long!').required('County is required'),
    county: Yup.string().when({
      is: () => props.withCounty,
      then: Yup.string().min(2, 'County is too Short!').max(255, 'Position is too Long!').required('County is required'),
    }),
    stateProvince: Yup.string().when({
      is: () => !props.withCounty,
      then: Yup.string().min(2, 'State is too Short!').max(255, 'State is too Long!').required('State is required'),
    }),
    postalCode: Yup.string().matches(/^\d{5}(?:[-\s]\d{4})?$/, { message: 'Zip code must match this format: XXXXX or XXXXX-XXXX', excludeEmptyString: true }).required('Zip code is required'),
  })

  const { personFormFor } = state;
  const values = props.values;
  const personFormErrors = state.personFormErrors;
  const touched = state.touched;
  const touchedAll = Object.values(touched).filter(value => value === false).length === 0;
  const errorList = Object.values(state.personFormErrors);

  return (
    <Fragment>
      <Modal centered fade={false} isOpen={state.showModal} toggle={toggleModal} backdrop="static" className="form-validation-error">
        <ModalHeader tag="h4" toggle={toggleModal}>Add {props.personType} Information</ModalHeader>
        <ModalBody>
          { props.personType === "Officer" ? 
            <FormGroup className="mb-2">
              <Label className="mb-1" for={`${personFormFor}position`}>Position</Label>
              <CustomInput invalid={personFormErrors.title && touched.title} onChange={handleChange} onBlur={(event) => validateField(event, values)} value={state.title} type="select" name="title" id={`${personFormFor}position`}  data-hj-masked>
                <option value="">Select Position</option>
                <option value="President">President</option>
                <option value="Vice President">Vice President</option>
                <option value="Secretary">Secretary</option>
                <option value="Treasurer">Treasurer</option>
              </CustomInput>
            </FormGroup>
          : 
            null
          }
          <FormGroup className="mb-2">
            <Label className="mb-1" for={`${personFormFor}firstName`}>First Name</Label>
            <Input tag={Field} invalid={personFormErrors.firstName && touched.firstName} onChange={handleChange} onBlur={(event) => validateField(event, values)} value={state.firstName} type="text" name="firstName" id={`${personFormFor}firstName`} data-hj-masked />
          </FormGroup>
          <FormGroup className="mb-2">
            <Label className="mb-1" for={`${personFormFor}lastName`}>Last Name</Label>
            <Input tag={Field} invalid={personFormErrors.lastName && touched.lastName} onChange={handleChange} onBlur={(event) => validateField(event, values)} value={state.lastName} type="text" name="lastName" id={`${personFormFor}lastName`} data-hj-masked />
          </FormGroup>
          <FormGroup className="mb-2">
            <Label className="mb-1" for={`${personFormFor}street1`}>Address</Label>
            <Input tag={Field} invalid={personFormErrors.street1 && touched.street1} onChange={handleChange} onBlur={(event) => validateField(event, values)} value={state.street1} type="text" name="street1" id={`${personFormFor}street1`}  data-hj-masked />
          </FormGroup>
          <FormGroup className="mb-2">
            <Label className="mb-1" for={`${personFormFor}street2`}>Suite. Apt. #, etc.</Label>
            <Input tag={Field} invalid={personFormErrors.street2 && touched.street2} onChange={handleChange} onBlur={(event) => validateField(event, values)} value={state.street2} type="text" name="street2" id={`${personFormFor}street2`}  data-hj-masked />
          </FormGroup>
          <FormGroup className="mb-2">
            <Label className="mb-1" for={`${personFormFor}city`}>City</Label>
            <Input tag={Field} invalid={personFormErrors.city && touched.city} onChange={handleChange} onBlur={(event) => validateField(event, values)} value={state.city} type="text" name="city" id={`${personFormFor}city`} data-hj-masked />
          </FormGroup>
          { props.withCounty ? 
            <FormGroup className="mb-2">
              <Label className="mb-1" for={`${personFormFor}county`}>County</Label>
              <CountyDropdown invalid={personFormErrors.county && touched.county} onChange={handleChange} onBlur={(event) => validateField(event, values)} value={state.county} name="county" id={`${personFormFor}county`} data-hj-masked />
            </FormGroup>
            :
            <FormGroup className="mb-2">
              <Label className="mb-1" for={`${personFormFor}state`}>State</Label>
              <StateDropdown invalid={personFormErrors.stateProvince && touched.stateProvince} onChange={handleChange} onBlur={(event) => validateField(event, values)} value={state.stateProvince} name="stateProvince" id={`${personFormFor}state`} data-hj-masked />
            </FormGroup>
          }
          <FormGroup className="mb-2">
            <Label className="mb-1" for={`${personFormFor}postalCode`}>Zip Code</Label>
            <Input tag={Field} invalid={personFormErrors.postalCode && touched.postalCode} onChange={handleChange} onBlur={(event) => validateField(event, values)} value={state.postalCode} type="text" name="postalCode" id={`${personFormFor}postalCode`} data-hj-masked />
          </FormGroup>
          { props.withEmail ? 
            <FormGroup className="mb-2">
              <Label className="mb-1" for={`${personFormFor}emailAddress`}>Email</Label>
              <Input tag={Field} invalid={personFormErrors.emailAddress && touched.emailAddress} onChange={handleChange} onBlur={(event) => validateField(event, values)} value={state.emailAddress} type="text" name="emailAddress" id={`${personFormFor}emailAddress`} data-hj-masked />
            </FormGroup>
          :
            null
          }
          {/* <FormGroup className="mb-2">
            <Button className="add-person ml-auto mt-2" color="success" type="button" onClick={() => validatePersonForm(props.values)}><i className="material-icons">group_add</i> Add {props.personType}</Button>
          </FormGroup> */}
        </ModalBody>
        <ModalFooter>
          {errorList.length > 0 && touchedAll ? <span className="text-danger w-100 text-right">Please complete required fields*</span> : null}
          <Button className="add-person close-modal mr-auto mt-1" color="success" outline type="button" onClick={toggleModal}>Cancel</Button>
          <Button className="add-person close-modal ml-2 mt-1" color="success" type="button" onClick={() => validatePersonForm(props.values)}><i className="material-icons">group_add</i> Add {props.personType}</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

export default PersonForm;
