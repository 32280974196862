import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import queryString from "query-string";
import {Helmet} from "react-helmet";
import API from "../services/apiService";
import * as Yup from 'yup';
import { Field, Form, Formik } from "formik";
import { generatePids } from "../utils/productMapping";
import Sidebar from "../components/Sidebar";
// import ProgressStatus from "../components/ProgressStatus";
import FormNav from "../components/FormNav";
import {
  CompanyNames,
  BusinessActivity,
  OfficeLocation,
  PointOfContact,
  RegisteredAgent,
  Managers,
  EinApplication,
  EntityClassification,
  SubSElection,
  Signatures,
  Directors,
  Officers,
  StockShares,
  DbaOwners,
  SkeletonCards,
} from "../components/CompanySummary";
import FormValidationAlert from "../components/FormValidationAlert";
import ProcessingStatus from "../components/ProcessingStatus";
import FormErrorModal from "../components/FormErrorModal";
import { storeApplication } from '../store/actions/formActions'	
import { accountInfoState } from "../utils/storeState";
import { formErrorHandler } from "../utils/errorHandlerUtil";
import CreateAccountModal from "../components/CreateAccountModal";

function CompanySummary(props) {
  const form = useSelector(state => state.form);
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();  

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const pageTitle = "Florida Incorporation Service Registration | Business Information Summary",
        accountType = props.match.params.accountType?.toLowerCase(),
        orderDetails = JSON.parse(sessionStorage.getItem("orderDetails")) || [],
        host = queryString.parse(props.location.search)?.host || "",
        formType = accountType === "dba" ? "dba" : "corp";

  const initState = {
    formState: {
      fisPackage: form[formType].registrationVars.fispackage || "",
      serviceType: form[formType].serviceType,
      lgr: form[formType].registrationVars.lgr || "",
      optionalProducts: form[formType].optionalProducts || [], // array can contain one or more of the following: SubSElection, BylawsAndMinutes, CertificateOfStatus, CorporateEmbosser, CorpSealsAndCerts, Form8832Filing, Form8832Filing, LLCSealsAndCerts, OperationalAgreement 
      optionalServices: form[formType].optionalServices || [],
      username: "",
      password: "",
    },
    accountType: accountType || "",
    orderDetailPids: orderDetails ||[],
    companyData: form[formType] || {}, // this will need to come from the API
    companies: [],
    dbaApplicants: [],
    isLoading: true,
  }

  const { pid, _gl } = queryString.parse(props.location.search);
  const linkParam = _gl ? `&_gl=${_gl}` : "";
  const checkedProducts = Object.keys(initState.formState).filter(key => initState.formState[key]); // generate product keys for generatePids()
  const generatedPids = generatePids([...checkedProducts, ...initState.formState.optionalProducts, ...initState.formState.optionalServices])
  const statePid = pid || (initState.formState.serviceType === 0 ? 531 : initState.formState.serviceType === 2 ? 580 : 583); 
  const uniquePids = new Set([Number(statePid), ...generatedPids]); // removing duplicate PIDs

  const [state, setState] = useState({
    ...initState,
    formState: {
      ...initState.formState,
    },
    pid: statePid,
    orderDetailPids: [...uniquePids],
  });

  // when user have an account or have logged in
  useEffect(() => {
    // console.log(state.accountType)
    const getExternalAccount = async () => {
      const account = await API.getExternalAccount(auth.user.profile.sub);
      // console.log(account.data)
      // const applicationData = accountData.data.companies.length > 0 ? accountData.data.companies[0] : accountData.data.dbaApplicants[0];
      if (account.data.companies.length === 1 && account.data.dbaApplicants.length === 0) {
        return API.getCompany(account.data.id, account.data.companies[0].id);
      }
      else if (account.data.dbaApplicants.length === 1 && account.data.companies.length === 0) {
        return API.getDbaApplicant(account.data.id, account.data.dbaApplicants[0].id);
      }
      else if (account.data.companies.length === 0 && account.data.dbaApplicants.length === 0) {
        return account;
      }
      else if (account.data.companies.length > 1 || account.data.dbaApplicants.length > 1) {
        // display company list for user to pick
        console.log("Doing SSO thing...");
        return account;
      }
    }

    if (auth.user.profile.sub) {
      getExternalAccount().then((companyData) => {
        // console.log(companyData.data)
        // when getExternalAccount() returns the company/dba data
        if (!companyData.data?.companies || !companyData.data?.dbaApplicants) {
          // console.log("Company Info:", companyData.data);
          const serviceType = companyData.data.serviceType; // serviceType is based on: Corp = 0, LLC = 2, DBA = 3
          const accountType = serviceType === 0 ? "corp" : serviceType === 2 ? "llc" : "dba";
          const pid = serviceType === 0 ? 531 : serviceType === 2 ? 580 : 583; 
          const pkg = serviceType === 0 ? "silver" : serviceType === 2 ? "llc" : "dba";
          const pkgQuery = serviceType === 0 ? "pkg=silver" : serviceType === 2 ? "pkg=llc" : "";
          const lgr = companyData.data.registrationVars.lgr
          let orderDetailPids = [pid];
          if (accountType !== "dba") {
            const generatedPids = generatePids([...companyData.data.optionalProducts, ...companyData.data.optionalServices])
            const uniquePids = new Set([...generatedPids]); // removing duplicate PIDs
            orderDetailPids = [...orderDetailPids, ...uniquePids]
          }
      
          setState(state => ({
            ...state,
            orderDetailPids: [...orderDetailPids],
            pid,
            pkg,
            accountType,
            formState: {
              ...state.formState,
              lgr,
            },
            companyData: {
              ...companyData.data,
            },
            isLoading: false,
          }));

          sessionStorage.removeItem('fisFormState'); // deleting current formState from sessionStorage
          const applicationState = accountInfoState(accountType, null, companyData.data);
          // console.log("applicationState", applicationState)
          dispatch(storeApplication(applicationState)) // sets redux store with form info
    
          if (companyData.data.orderInfoId || companyData.data.isComplete) {
            sessionStorage.setItem('completedApplicationData', JSON.stringify(companyData.data));
            props.history.replace('/completed-summary')
          }
          else {
            if (!props.location.search) {
              const queryParams = `?${pkgQuery}&scc=amsc-floridais&pid=${pid}&host=floridaincorporationservice.com&lgr=${lgr}&ref=self`;
              props.history.replace(`/company-summary/${accountType}/${queryParams}`);
            }
          }
        }
        // only needed for testing without OTT
        else if (companyData.data.companies.length === 0 && companyData.data.dbaApplicants.length === 0) {
          const corpData = {
            // ...form.corp,
            account: {
              // ...form.corp.account,
              id: companyData.data.id,
              userName: companyData.data.userName,
              person: companyData.data.person,
            },
          }
          const dbaData = {
            // ...form.dba,
            account: {
              // ...form.dba.account,
              id: companyData.data.id,
              userName: companyData.data.userName,
              person: companyData.data.person,
            },
          }
          sessionStorage.removeItem('fisFormState'); // deleting current formState from sessionStorage
          const corpState = accountInfoState("corp", null, corpData);
          dispatch(storeApplication(corpState)) // sets redux store with form info
          const dbaState = accountInfoState("dba", null, dbaData);
          dispatch(storeApplication(dbaState)) // sets redux store with form info
  
          setState(state => ({
            ...state,
            isLoading: false,
          }));
        }
        // only for SSO which we dont support right now
        else {
          setState(state => ({
            ...state,
            companies: companyData.data.companies,
            dbaApplicants: companyData.data.dbaApplicants,
            isLoading: false,
          }));   
        }
      })
      .catch(err => {
        // console.log("catch section", err)
        if (err.response?.status > 400) { console.log(err.response?.status, err.response?.statusText) }
        else { console.log(err.response?.data?.errors) }
      });
    }

    // if user haven't created an account
    else {
      const serviceType = state.formState.serviceType; // serviceType is based on: Corp = 0, LLC = 2, DBA = 3
      const accountType = serviceType === 0 ? "corp" : serviceType === 2 ? "llc" : "dba";
      const pid = serviceType === 0 ? 531 : serviceType === 2 ? 580 : 583; 
      const pkgQuery = serviceType === 0 ? "pkg=silver" : serviceType === 2 ? "pkg=llc" : "";
      const lgr = state.formState.lgr;
  
      if (!props.location.search) {
        const queryParams = `?${pkgQuery}&scc=amsc-floridais&pid=${pid}&host=floridaincorporationservice.com&lgr=${lgr}&ref=self`;
        props.history.replace(`/company-summary/${accountType}/${queryParams}`);
      }
  
      setState(state => ({
        ...state,
        accountType: accountType,
        isLoading: false,
      }));   
    }

  }, [auth.user.profile.sub, dispatch, props.history, props.location.search, state.formState.serviceType, state.formState.lgr]);

  // when user haven't created an account
  // useEffect(() => {
  //   const serviceType = form[formType].serviceType; // serviceType is based on: Corp = 0, LLC = 2, DBA = 3
  //   const accountType = serviceType === 0 ? "corp" : serviceType === 2 ? "llc" : "dba";
  //   const pid = serviceType === 0 ? 531 : serviceType === 2 ? 580 : 583; 
  //   const pkgQuery = serviceType === 0 ? "pkg=silver" : serviceType === 2 ? "pkg=llc" : "";
  //   const lgr = form[formType].registrationVars.lgr;

  //   if (!props.location.search) {
  //     const queryParams = `?${pkgQuery}&scc=amsc-floridais&pid=${pid}&host=floridaincorporationservice.com&lgr=${lgr}&ref=self`;
  //     props.history.replace(`/company-summary/${accountType}/${queryParams}`);
  //   }

  //   setState(state => ({
  //     ...state,
  //     accountType: accountType,
  //     isLoading: false,
  //   }));   
  // }, [form, formType, props.history, props.location.search]);

  
  const checkUsername = (event, setFieldTouched) => {
    const { value } = event.target;
    const userName = {
      username: value
    }
    setFieldTouched("username", true, true)
    if (value) {
      API.checkUsername(userName).then(userNameData => {
        setState(state => ({
          ...state,
          usernameUnavailable: userNameData.data?.isUser, // value is always true/false
        }));
      })
      .catch(err => {
        console.log("Couldn't verify if username is available!",err)
        setApiErrorState(["Couldn't verify if username is available, you can still try it."]);
      });
    }
  }

  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = () => {
    setShowPassword(!showPassword);
  }

  const validationSchema = Yup.object().shape({
    username: Yup.string().when({
      is: () => !form[formType].account.externalUserId,
      then: Yup.string().matches(/^([A-Za-z0-9_]|([\w|.|-]*@\w*\.[\w|.]))+$/, { message: 'Username cannot contain spaces or special characters other than what\'s allowed on emails addresses.', excludeEmptyString: true }).min(6, 'Username must be at least 6 characters.').max(255, 'Username is too Long!').required('Username is required'),
    }),
    password: Yup.string().when({
      is: () => !form[formType].account.externalUserId,
      then: Yup.string().matches(/^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/, { message: 'Password must be at least 6 characters long, contain at least 1 letter and 1 number, and cannot contain spaces.', excludeEmptyString: true }).required('Password is required'),
    }),
  })

  const handleFormSubmit = async (values, actions) => {
    setState(state => ({
      ...state,
      formState: {
        ...state.formState,
        ...values,
      },
      isProcessing: true,
    }));
    
    if (!form[formType].account.externalUserId) {
      const goToNextPage = async (apiData) => {
        sessionStorage.removeItem('fisFormState'); // deleting current formState from sessionStorage
        // setState(state => ({
        //   ...state,
        //   isProcessing: false,
        // }));
        // creating object that matched the redux state object to prevent removing any key/values that's not updated
        const applicationState = accountInfoState(accountType, form, apiData);
        dispatch(storeApplication(applicationState)) // sets redux store with form info
        actions.resetForm(true);
        const nextUrl = checkoutUrl;
        // props.history.push(nextUrl); // push user to next page after form submission or api call
        // const linkParam = await getGaLinkParam()
        window.location = `${nextUrl}${linkParam}`;
        // window.scrollTo(0, 0) // making sure that user view is top of the page
      }
  
      const formData = form[formType];
      const accountInfo = {
        userName: values.username,
        password: values.password,
        firstName: formData.account?.person?.firstName,
        lastName: formData.account?.person?.lastName,
        emailAddress: formData.account?.person?.emailAddress,
        phoneNumber: formData.account?.person?.businessPhone,
      }
      API.createExternalAccount(formData.account.id, accountInfo).then(account => {
        // console.log("account", account)
        return accountType !== "dba" ? API.getCompany(formData.account.id, formData.id) : API.getDbaApplicant(formData.account.id, formData.id);
      })
      .then(company => {
        // console.log("company", company.data)
        goToNextPage(company.data)
      })
      .catch(err => {
        console.log("Save Application Error:",err)
        formErrorHandler(err, setApiErrorState);
      });
    }
    else {
      // const linkParam = await getGaLinkParam()
      window.location = `${checkoutUrl}${linkParam}`;
    }
  }

  const setApiErrorState = (err) => {
    setState(state => ({
      ...state,
      apiErrors: err,
    }));
  }

  // const getGaLinkParam = () => {
  //   return new Promise(resolve => {
  //     setTimeout(() => {
  //       let tracker = "";
  //       window.ga(function() {
  //         const trackers = window.ga?.getAll();
  //         tracker = trackers ? `&${trackers[0].get('linkerParam')}` : '';
  //       });    
  //       resolve(tracker);
  //     }, 1000);
  //   });
  // }

  const queryParams = props.location.search || `?pkg=${state.pkg}&scc=amsc-floridais&pid=${state.pid}&host=floridaincorporationservice.com&lgr=${state.formState.lgr}&ref=self`;
  const prevUrl = `/last-steps/${accountType}/${queryParams}`;
  const nextUrl = ""; 

  const incorporatorSig =  state.companyData.incorporatorSig;
  const incorporatorAddress = state.companyData.incorporatorAddress;
  const addEinCheck = state.companyData.registrationVars?.addEIN ? state.companyData.registrationVars.addEIN && state.companyData.firstDayOfBusiness : true;
  const applicantSig = state.companyData?.applicantSig;
  const signatories = state.companyData?.signatories ? state.companyData?.signatories[0] : null;

  const companyId = accountType !== "dba" ? `&c=${form[formType].id}` : `&d=${form[formType].id}`
  const companyOpt = accountType !== "dba" ? `&opt=${state.companyData.optionalItemsRegistrationParameter}` : "";
  // need to find a way to determine DL/ML/RL checkout to send user for testing
  const { PublicUri } = JSON.parse(sessionStorage.getItem("appsettings"));
  const checkoutDomain = (PublicUri.includes("dlapp") || PublicUri.includes("localhost")) ? "dlhome.amersc.com" : PublicUri.includes("mlapp") ? "mlhome.amersc.com" : "home.uceusa.com"
  const checkoutUrl = `https://${checkoutDomain}/registration/Index.aspx?cid=23&host=fis&pid=${state.pid}${companyId}${companyOpt}&language=en&fis=true&lgr=${state.formState.lgr}`;

  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href="https://www.floridaincorporationservice.com" />
      </Helmet>

      {/* <ProgressStatus registrationStep={registrationStep} /> */}
      <Container>
        <Row className="justify-content-center">
          <Col lg="8" md="10" sm="12" data-hj-masked>
            { state.companies?.length === 0 && state.dbaApplicants?.length === 0 ?
              <Fragment>
                {state.companyData.orderInfoId || state.companyData.isComplete ?
                  <Fragment>
                    <h2>Your application is complete</h2>
                    { state.accountType !== "dba" ?
                      <Fragment>
                        <p>You should receive your incorporation documents by email within <strong>approximately five business days</strong>.</p>
                        <p>If you purchased additional items, such as the corporate kit, stock certificates, or tax forms, you will receive these items within <strong>approximately 5-7 business days</strong>.</p>
                        <p>If you purchased an embosser, you will receive this by mail within <strong>approximately two weeks</strong>.</p>
                      </Fragment>
                    : 
                      <Fragment>
                        <p>Thank you. Your registration is complete and has been received by Florida Incorporation Service. We will notify you within two business days regarding the status of your Fictitious Name company.</p>
                      </Fragment>
                    }
                    <p>Below is a summary of the information you have provided us.</p>
                  </Fragment>
                :
                  <Fragment>
                    <h2>Company Information Summary</h2>
                    <p>Following is a summary of your company's information as you have entered it; please verify this is complete and accurate as shown. If any of the information is incorrect, please click the Edit link for that section to be taken to the appropriate page to change it.</p>
                    <p>If you would like one of our support representatives to clarify the meaning of any of the information below, please call us at 1-800-370-2942.</p>
                  </Fragment>
                }
                {!state.isLoading ? 
                  <Fragment>
                    <PointOfContact data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                    <CompanyNames data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                    <BusinessActivity data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                    <OfficeLocation data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                    { state.accountType === "dba" ?
                      <DbaOwners data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                    : null }
                    { state.accountType === "llc" ?
                      <Managers data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                    :
                      <Fragment>
                        <Officers data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                        <Directors data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                        <StockShares data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                      </Fragment>
                    }
                    <RegisteredAgent data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                    { state.accountType !== "dba" ?
                      <EinApplication data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                    : null }
                    { state.accountType === "llc" ?
                      <EntityClassification data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                    : null }
                    { state.companyData.registrationVars?.addEIN && (state.companyData.subSElection || !state.companyData.subSElection) ?
                      <SubSElection data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                    : null }
                    <Signatures data={state.companyData} accountType={state.accountType} queryString={queryParams} />
                  </Fragment>
                :
                  <SkeletonCards />
                }
              </Fragment>
            : 
              <p>Multiple application in one account.</p>
            }

            <Formik
              enableReinitialize={true}
              initialValues={state.formState}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => handleFormSubmit(values, actions)}
            >
              {({ isValid, actions, errors, touched, values, handleChange, submitCount, isSubmitting, setFieldTouched }) => (
                <Form>
                  { !form[formType].account.externalUserId && !state.isLoading && ((incorporatorSig && incorporatorAddress && addEinCheck) || (applicantSig && signatories)) ? 
                    <Fragment>
                      <h3>Create Account</h3>
                      <p>With this username and password, you can return to this website at any time to complete or check on the status of your application. An email confirmation will be sent to <strong>{form[formType].account?.person?.emailAddress}</strong>.</p>
                      <FormGroup className="form-row">
                        <Label sm={3} className="text-sm-right" for="username">Username</Label>
                        <Col sm={8}>
                          <Input tag={Field} invalid={(errors.username && touched.username) || state.usernameUnavailable} onChange={handleChange} value={values.username} type="text" name="username" id="username" data-hj-masked data-private onBlur={event => checkUsername(event, setFieldTouched)} />
                          { errors.username && touched.username ?
                            <small className="form-text text-danger">{errors.username}</small> 
                          : null }
                          { state.usernameUnavailable ?
                            <small className="form-text text-danger">The username you entered is already taken, please use a different one.</small> 
                          : null }
                        </Col>
                      </FormGroup>
                      <FormGroup className="form-row">
                        <Label sm={3} className="text-sm-right" for="password">Password</Label>
                        <Col sm={8} className="password-field">
                          <Input tag={Field} invalid={errors.password && touched.password} onChange={handleChange} value={values.password} type={showPassword ? "text" : "password"} name="password" id="password" data-hj-masked data-private />
                          <span className={showPassword ? "show-hide-icon show" : "show-hide-icon"} onClick={togglePassword}></span>
                          <small className="form-text text-muted">Password must be at least 6 characters long, contain at least 1 letter and 1 number, and cannot contain spaces.</small> 
                        </Col>
                      </FormGroup>

                      <FormValidationAlert
                        submitCount={submitCount}
                        isValid={isValid}
                        isSubmitting={isSubmitting}
                        errors={errors}
                      />
                      
                      <ProcessingStatus showProcessing={state.isProcessing && !state.apiErrors} />
                      
                      <FormErrorModal errors={state.apiErrors} />

                    </Fragment>
                  : null }

                  {!(state.companyData.orderInfoId || state.companyData.isComplete) ?
                    <Fragment>
                      {!state.isLoading ? 
                        <Fragment>
                          { (incorporatorSig && incorporatorAddress && addEinCheck) || (applicantSig && signatories) ?
                            <FormGroup className="form-row form-nav justify-content-end justify-content-sm-between flex-column flex-sm-row">
                              <Button className="btn btn-lg btn-success app-btn next-btn ml-sm-auto order-sm-1 mx-3" color="success" size="lg" type="submit">Checkout Now <i className="material-icons">arrow_forward</i></Button>
                              
                              { !auth.user.profile.sub && !form[formType].account.externalUserId ? 
                                <Fragment>
                                  <button type="button" className="app-btn save-btn text-muted btn btn-link btn-lg my-3 my-sm-2 mt-0 order-sm-0" onClick={toggleModal}><i className="material-icons-outlined">folder_shared</i> Save and Continue Later</button>
                                  <CreateAccountModal isOpen={modal} toggle={toggleModal} />
                                </Fragment>
                              : null}
                            </FormGroup>
                          :
                            <FormNav action={{prev: prevUrl, next: nextUrl}} hidePrevButton={true} hideSaveButton={true} nextButtonText="Continue Application" continueApplication={true} />
                          }
                        </Fragment>
                      : 
                        <FormGroup className="form-row form-nav justify-content-between">
                          <span className="btn btn-lg btn-secondary app-btn next-btn ml-auto disabled">Continue <i className="material-icons">arrow_forward</i></span>
                        </FormGroup>
                      }
                    </Fragment>
                  : null }

                  <ProcessingStatus showProcessing={state.isProcessing && !state.apiErrors} />

                </Form>
              )}
            </Formik>

          </Col>

          {(!state.companyData.orderInfoId || !state.companyData.isComplete) ?
            <Sidebar 
              orderDetailPids={state.orderDetailPids} 
              host={host} 
              lgr={state.formState.lgr} 
              isLoading={state.isLoading}
            />
          : null }
          {/* { !state.isLoading && state.companies?.length === 0 && state.dbaApplicants?.length === 0 ?
            <Sidebar 
              orderDetailPids={state.orderDetailPids} 
              host={host} 
              lgr={state.formState.lgr} 
            />
          : null } */}

        </Row>
      </Container>
    </Fragment>
  );
}

export default CompanySummary;
