import React, { useState, useEffect } from "react";
import {
  CustomInput
} from 'reactstrap';
import API from "../../services/apiService";
import { useDispatch } from 'react-redux'
import { appErrorHandler } from '../../utils/errorHandlerUtil'	

// import states from "./states.json";

const StateDropdown = props => {  
  const [states, setStates] = useState(JSON.parse(sessionStorage.getItem("fisStates")) || [])
  // const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!sessionStorage.getItem("fisStates")) {
      API.getStates().then(apiData => {
        // console.log("States:", apiData.data);
        setStates(apiData.data)
        // setIsLoading(false)
        sessionStorage.setItem("fisStates", JSON.stringify(apiData.data))
      })
      .catch(err => {
        // this sets a redux global app error
        appErrorHandler("StateDropdown", err, dispatch)
      });
    }
  }, [dispatch])

  const statesOptions = states.map((item, index) => (
    <option key={index} value={item.initials}>{item.name}</option>
  ))

  return (
    <CustomInput type="select" {...props} data-hj-masked>
      <option value="">Select State</option>
      {statesOptions}
    </CustomInput>
  );
}

export default StateDropdown;