import React, { Fragment } from "react";
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import { formatDate } from 'react-day-picker/moment';

const Signatures = (props) => {
  const acceptsAgreement = props.data.acceptsAgreement;
  const effectiveDate =  props.data.effectiveDate;
  const incorporatorSig =  props.data.incorporatorSig;
  const incorporatorAddress = props.data.incorporatorAddress;
  const street1 = incorporatorAddress?.street1;
  const street2 = incorporatorAddress?.street2;
  const city = incorporatorAddress?.city;
  const postalCode = incorporatorAddress?.postalCode;
  const stateProvince = incorporatorAddress?.stateProvince;
  const applicantSig = props.data?.applicantSig;
  const signatories = props.data?.signatories ? props.data?.signatories[0] : null;

  const editLink = `/last-steps/${props.accountType}/${props.queryString}`;

  const handleEdit = event => {
    event.preventDefault();
    props.history.push(editLink);
    window.scrollTo(0, 0) // making sure that user view is top of the page
  }

  const showEdit = !(props.data.orderInfoId || props.data.isComplete);

  if ((incorporatorSig && incorporatorAddress) || (applicantSig && signatories && acceptsAgreement)) {
    return (
      <Card className="card-summary">
        <CardHeader tag="h5" className="mt-0">Signatures {showEdit && <a href="//:0" onClick={handleEdit} className="edit ml-auto">Edit</a>}</CardHeader>
        <ListGroup flush>
        {/* for Corp and LLC */}
        {incorporatorSig && incorporatorAddress ?
            <Fragment>
              { effectiveDate ?
                <ListGroupItem>
                  <span className="info-title">Delayed Effective Date:</span>
                  <span className="info-data">{formatDate(effectiveDate) !== "Invalid date" ? formatDate(effectiveDate) :  "Not Provided"} </span>
                </ListGroupItem>
              : 
                null
              }
              <ListGroupItem>
                <span className="info-title">Signature:</span>
                <span className="info-data">{incorporatorSig}</span>
              </ListGroupItem>
              <ListGroupItem>
                <span className="info-title">Address:</span>
                <span className="info-data">
                  {street1}, {street2} <br/>
                  {city}, {stateProvince} {postalCode}
                </span>
              </ListGroupItem>
            </Fragment>
          : 
            null
          } 
         {/* for DBA */}
         {(applicantSig && signatories) ?
            <Fragment>
              <ListGroupItem>
                <span className="info-title">Owner's Electronic Signature:</span>
                <span className="info-data">{signatories.name}</span>
              </ListGroupItem>
              <ListGroupItem>
                <span className="info-title">Phone:</span>
                <span className="info-data">{signatories.phone}</span>
              </ListGroupItem>
              <ListGroupItem>
                <span className="info-title">Electronic Signature:</span>
                <span className="info-data">{applicantSig}</span>
              </ListGroupItem>
            </Fragment>
          : 
            null
          } 
        </ListGroup>
      </Card>
    );
  }
  else {
    return null;
  }
}

export default withRouter(Signatures);
