import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {
  Container,
  Row,
  Col,
  FormGroup,
  CustomInput,
  Label,
  Input,
} from 'reactstrap';
import queryString from "query-string";
import {Helmet} from "react-helmet";
import API from "../services/apiService";
// import generatePids from "../utils/generatePids";
// import generateKeys from "../utils/generateKeys";
import { generateKeys, generatePids } from "../utils/productMapping";
import { Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import Sidebar from "../components/Sidebar";
import ProgressStatus from "../components/ProgressStatus";
import CertificateOfOwnershipAndSeals from "../components/Upsells/CertificateOfOwnershipAndSeals";
import CompanyKit from "../components/Upsells/CompanyKit";
import CorporateKit from "../components/Upsells/CorporateKit";
import CompanyEmbosser from "../components/Upsells/CompanyEmbosser";
import CertificateOfStatus from "../components/Upsells/CertificateOfStatus";
import CertificateOfStocksAndCorporateSeals from "../components/Upsells/CertificateOfStocksAndCorporateSeals"
import FictitiousName from "../components/Upsells/FictitiousName";
import FormNav from "../components/FormNav";
import FormValidationAlert from "../components/FormValidationAlert";
import ProcessingStatus from "../components/ProcessingStatus";
import FormErrorModal from "../components/FormErrorModal";
import ApplicationCompleteAlert from "../components/ApplicationCompleteAlert";
import { formErrorHandler, appErrorHandler } from "../utils/errorHandlerUtil";
import { displayPriceDollar } from "../utils/displayPrice";
import { storeApplication } from '../store/actions/formActions'	
import { accountInfoState } from "../utils/storeState";

function BusinessOptions(props) {
  const form = useSelector(state => state.form);
  const dispatch = useDispatch();  

  const pageTitle = "Florida Incorporation Service Registration | Compliance & Business Options",
        registrationStep = 2,
        accountType = props.match.params.accountType?.toLowerCase(),
        orderDetails = JSON.parse(sessionStorage.getItem("orderDetails")) || [],
        host = queryString.parse(props.location.search)?.host || "",
        formType = accountType === "dba" ? "dba" : "corp";

  // checks if current url is valid for the stored order data
  const { pid, lgr, pkg } = queryString.parse(props.location.search);
  if (!pid || !lgr || !host || (accountType !== "dba" ? !pkg : false)) {
    appErrorHandler("Custom Error", `Missing query string ${props.history.location.pathname}${props.history.location.search}`, dispatch)
    // props.history.replace(`/error/${accountType}/${props.location.search}`);
  }
  if (orderDetails.length && (Number(orderDetails[0].id) !== Number(pid) || orderDetails[0].lgr !== lgr?.toLowerCase())) {
    sessionStorage.removeItem('orderDetails');
    sessionStorage.removeItem('productsData');
    sessionStorage.removeItem('fisFormState');
    props.history.replace(`/account-info/${accountType}/${props.location.search}`);
  }

  const initState = {
    formState: {
      numStockShares: form[formType].numStockShares || 2000,
      certificateOfStatus: false,
      needFictitiousName: false,
      fictitiousName: form[formType].fictitiousName || "",
      certificateOfOwnershipAndSeals: false,
      companyKit: false,
      companyEmbosser: false,
      einRegistration: (form[formType].numStockShares || form[formType].optionalProducts?.length > 0) ? form[formType].registrationVars?.addEIN : true,
      corporateKit: false,
      certificateOfStocksAndCorporateSeals: false,
      fisPackage: form[formType].registrationVars?.fisPackage || "",
      lgr: form[formType].registrationVars?.lgr || "",
      optionalProducts: form[formType].optionalProducts || [], // array can contain one or more of the following: SubSElection, BylawsAndMinutes, CertificateOfStatus, CorporateEmbosser, CorpSealsAndCerts, Form8832Filing, Form8832Filing, LLCSealsAndCerts, OperationalAgreement 
      optionalServices: form[formType].optionalServices || [],
    },
    // isProcessing: true,
  };

  const checkedProducts = Object.keys(initState.formState).filter(key => initState.formState[key]); // generate product keys for generatePids()
  const generatedPids = generatePids([...checkedProducts, ...initState.formState.optionalProducts, ...initState.formState.optionalServices])
  const uniquePids = new Set([Number(pid), ...generatedPids]); // removing duplicate PIDs
  const productKeys = generateKeys([...uniquePids]); // object of product keys with true value

  const [state, setState] = useState({
    ...initState,
    formState: {
      ...initState.formState,
      ...productKeys,
    },
    orderDetailPids: [...uniquePids]
  });

  const handleOptionsChange = (event, setFieldValue) => {
    const { name, value, type, checked } = event.target;
    const theValue = type === 'checkbox' ? checked : value;
    setFieldValue([name], theValue);
    let orderDetailPids = state.orderDetailPids;
    if (theValue && type === 'checkbox') {
      orderDetailPids.push(Number(value));
    }
    else {
      orderDetailPids = orderDetailPids.filter(item => item !== Number(value))
    }
    orderDetailPids = new Set([...orderDetailPids]); // removing duplicate PIDs
    setState(state => ({
      ...state,
      orderDetailPids: [...orderDetailPids]
    }));
  }

  const removeUpsell = (event, name, pid, setFieldValue) => {
    event.preventDefault();
    setFieldValue([name], false);
    let orderDetailPids = state.orderDetailPids;
    orderDetailPids = orderDetailPids.filter(item => item !== Number(pid))
    orderDetailPids = new Set([...orderDetailPids]); // removing duplicate PIDs
    setState(state => ({
      ...state,
      orderDetailPids: [...orderDetailPids]
    }));
  }

  const handleFormSubmit = (values, actions) => {
    setState(state => ({
      ...state,
      formState: {
        ...state.formState,
        ...values,
      },
      isProcessing: true,
    }));

    const goToNextPage = (apiData) => {
      sessionStorage.removeItem('fisFormState'); // deleting current formState from sessionStorage
      setState(state => ({
        ...state,
        isProcessing: false,
      }));
      // creating object that matched the redux state object to prevent removing any key/values that's not updated
      const applicationState = accountInfoState(accountType, form, apiData);
      dispatch(storeApplication(applicationState)) // sets redux store with form info
      actions.resetForm(true);
      // const nextUrl = accountType === "llc" && values.einRegistration === true ? `/ein-contact/${accountType}/${props.location.search}` : accountType !== "llc" && values.einRegistration === true ? `/ein-application/${accountType}/${props.location.search}` :  `/last-steps/${accountType}/${props.location.search}`;
      const nextUrl = values.einRegistration === true ? `/ein-application/${accountType}/${props.location.search}` :  `/last-steps/${accountType}/${props.location.search}`;
      props.history.push(nextUrl); // push user to next page after form submission or api call
      window.scrollTo(0, 0) // making sure that user view is top of the page
    }
    
    const checkedProductsArray = Object.keys(values).filter(key => values[key]); // this grabs the keys that will need to be passed into the generatePids() function
    const optionalServicePids = [583, 701, 702]; // hardcode because there's no better way to grab this from some settings
    const optionalProducts = generatePids(checkedProductsArray).filter(pid => !optionalServicePids.includes(pid)); // removing optionalServices PIDs
    // console.log("optionalProducts", optionalProducts)
    const accountId = form.corp.account.id; // grabbing from redux
    const companyId = form.corp.id; // grabbing from redux
    const companyInfo = {
      subSElection: false, // needed to set to false to allow user to progress
      // numStockShares: accountType !== "llc" ? values.numStockShares : 0,
      registrationVars: {
        addEIN: values.einRegistration,
        addDBA: values.needFictitiousName,
      },
      optionalProducts,
    }
    if (accountType !== "llc") {
      companyInfo.numStockShares = values.numStockShares;
    }
    const fictitiousName = {
      fictitiousName: values.fictitiousName,
    }
    // console.log("companyInfo", companyInfo)
    API.patchCompany(accountId, companyId, companyInfo).then((companyData) => {
      // console.log("Patched Company:", companyData.data);
      if (values.needFictitiousName && values.einRegistration) {
        // console.log("Added Fictitious Name:", companyData?.data);
        return API.addFictitiousName(accountId, companyId, fictitiousName)
      }
      else if (values.needFictitiousName && !values.einRegistration){
        return API.addFictitiousName(accountId, companyId, fictitiousName).then((companyData) => {
          // console.log("Added Fictitious Name & Removed EIN Application:", companyData?.data);
          return API.removeEINApplication(accountId, companyId)
        })
      }
      else if (!values.needFictitiousName && values.einRegistration){
        // console.log("Removed Fictitious Name:", companyData?.data);
        return API.removeFictitiousName(accountId, companyId)
      }
      else if (!values.needFictitiousName && !values.einRegistration){
        return API.removeFictitiousName(accountId, companyId).then((companyData) => {
          // console.log("Removed Fictitious Name & Removed EIN Application:", companyData?.data);
          return API.removeEINApplication(accountId, companyId)
        })
      }
      else {
        return companyData
      }
    })
    .then((companyData) => {
      // console.log("Updated Company:", companyData.data);
      goToNextPage(companyData.data);
    })
    .catch(err => {
      // console.log(err)
      formErrorHandler(err, setApiErrorState);
    });
  }
  
  const setApiErrorState = (err) => {
    setState(state => ({
      ...state,
      apiErrors: err,
    }));
  }

  const validationSchema = Yup.object().shape({
    numStockShares: Yup.number().min(1, 'Shares can\'t be 0').required('Shares is required'),
    needFictitiousName: Yup.boolean(),
    fictitiousName: Yup.string().when('needFictitiousName', {
      is: true,
      then: Yup.string().min(2, 'Fictitious Name is too short!').max(255, 'Fictitious Name is too Long!').required('Fictitious Name is required'),
    }),

  })

  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href="https://www.floridaincorporationservice.com" />
      </Helmet>

      <ProgressStatus registrationStep={registrationStep} />
      
      <Container>
        <Row className="justify-content-center">
          {form[formType].isComplete || form[formType].orderInfoId ? 
            <ApplicationCompleteAlert />
          : null }          
          <Col lg="8" md="10" sm="12">
            <Formik
              enableReinitialize={true}
              initialValues={state.formState}
              validationSchema={validationSchema}
              // onChange={values => handleChange(values)}
              onSubmit={(values, actions) => handleFormSubmit(values, actions)}
            >
              {({ isValid, errors, touched, values, handleChange, setFieldValue, submitCount, isSubmitting }) => (
                <Form>
                  { accountType !== "llc" ? 
                    <Fragment>
                      <h2>Authorized Shares of Stock</h2>
                      <p>In Florida, to register a new corporation with the Division of Corporations, whether this is an S or C corporation, requires the corporation to have at least one (1) share. The issuing of shares is how a Corporation is owned.</p>
                      <p><strong>Your new corporation will have 2000 authorized shares that can be issued, unless you designate otherwise below:</strong></p>
                      <div className="indent-wrap">
                        <FormGroup className="form-row">
                          <Col xs={10} sm={6}>
                            <Label className="text-sm-right" for="numStockShares"><strong>Shares</strong></Label>
                            <Input tag={Field} invalid={errors.numStockShares && touched.numStockShares} onChange={handleChange} value={values.numStockShares} type="number" name="numStockShares" id="numStockShares" maxLength="50" data-hj-masked />
                          </Col>
                        </FormGroup>
                      </div>
                    </Fragment>
                  : 
                    null
                  }

                  <h2>Compliance and Optional Items</h2>
                  <p>Listed below are a few additional items that we offer for new companies. To add any of these items to your order, simply check the box next to the item(s) you need.</p>
                  <div className="indent-wrap">
                    <CertificateOfStatus tag={Field} onChange={event => handleOptionsChange(event, setFieldValue)} checked={values.certificateOfStatus} name="certificateOfStatus" id="certificateOfStatus" />
                    <FictitiousName tag={Field} invalid={errors.fictitiousName && touched.fictitiousName} onChange={event => handleOptionsChange(event, setFieldValue)} checked={values.needFictitiousName} value={values.fictitiousName} name="needFictitiousName" id="needFictitiousName" />
                    { accountType === "llc" ? 
                      <Fragment>
                        <CertificateOfOwnershipAndSeals tag={Field} onChange={event => handleOptionsChange(event, setFieldValue)} checked={values.certificateOfOwnershipAndSeals} name="certificateOfOwnershipAndSeals" id="certificateOfOwnershipAndSeals" />
                        <CompanyKit tag={Field} onChange={event => handleOptionsChange(event, setFieldValue)} checked={values.companyKit} name="companyKit" id="companyKit" />
                      </Fragment>
                    : 
                      <Fragment>
                        <CertificateOfStocksAndCorporateSeals tag={Field} onChange={event => handleOptionsChange(event, setFieldValue)} checked={values.certificateOfStocksAndCorporateSeals} name="certificateOfStocksAndCorporateSeals" id="certificateOfStocksAndCorporateSeals" />
                        <CorporateKit tag={Field} onChange={event => handleOptionsChange(event, setFieldValue)} checked={values.corporateKit} name="corporateKit" id="corporateKit" />
                      </Fragment>
                    }
                    <CompanyEmbosser tag={Field} onChange={event => handleOptionsChange(event, setFieldValue)} checked={values.companyEmbosser} name="companyEmbosser" id="companyEmbosser" />
                  </div>

                  <h2>EIN Registration</h2>
                  <h4>What is an EIN and why would I need one?</h4>
                  <p>An EIN has many different names - FEIN, FEI, Employer Tax ID number, Federal Tax ID number, and IRS tax number.</p>
                  <p>This number is assigned by the IRS as a Federal Employer Tax Identification number. It is used to identify the tax accounts of employers and certain others who have no employees. The IRS uses the number to identify taxpayers who are required to file various business tax returns.</p>
                  <h5><em>If you wish to file S corporate status, which most new corporations prefer, you will need to file an EIN at this time.</em></h5>
                  <p>Banks require this number to open a new corporate account. Other governmental and regulated industries may also require this number for licensure or transacting business.</p>
                  <p>If you choose for us to obtain an EIN for you, we will email that number along with your Articles of Incorporation as quickly as possible, usually within 2-3 business days. We offer this as an option for only ${displayPriceDollar(702)}.</p>
                  <FormGroup className="form-row action-area">
                    <Col sm={12} className="ml-md-auto">
                      <CustomInput className="boxed-checkbox" onChange={event => handleOptionsChange(event, setFieldValue)} checked={values.einRegistration} type="checkbox" value={702} name="einRegistration" id="einRegistration" label={<strong>I need to file for an EIN</strong>} inline data-hj-masked />
                      <a href="/" className="small text-muted font-weight-light idontwant" onClick={event => {removeUpsell(event, 'einRegistration', 702, setFieldValue)}}>I don't need to file for an EIN</a>
                    </Col>
                  </FormGroup>

                  <FormValidationAlert
                    submitCount={submitCount}
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                    errors={errors}
                  />

                  <ProcessingStatus showProcessing={state.isProcessing && !state.apiErrors} />

                  <FormErrorModal errors={state.apiErrors} />

                  {(!form[formType].isComplete || !form[formType].orderInfoId) ?
                    <FormNav action={{prev: `/registered-agent/${accountType}/${props.location.search}`}} />
                  : null }
                  
                </Form>
              )}
            </Formik>
          </Col>

          <Sidebar 
            orderDetailPids={state.orderDetailPids} 
            host={host} 
            lgr={state.formState.lgr} 
          />

        </Row>
      </Container>
    </Fragment>
  );
}


export default BusinessOptions;
