import { createStore, compose } from 'redux';
import rootReducer from './reducers'
import initialCorpState from './reducers/initialCorpState'
import initialDbaState from './reducers/initialDbaState'
import { loadState, saveState } from '../utils/sessionStorage'
import { setAuthHeader } from '../utils/axiosHeaders'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const { Identity } = JSON.parse(sessionStorage.getItem("appsettings"));
const sessionUser = JSON.parse(sessionStorage.getItem(`oidc.user:${Identity.Authority}:${Identity.ClientId}`));

if (sessionUser) {
  setAuthHeader(sessionUser.access_token)
}

const initialFormState = {
  corp: initialCorpState,
  dba: initialDbaState,
};

// needs to set some initial values
const initialState = {
  auth: {
    user: sessionUser || null,
    isLoadingUser: false,
    userLoaded: false,
  },
  form: loadState() || initialFormState,
  app: {
    error: {
      state: false,
      details: null,
    }
  }
}

const store = createStore(
  rootReducer,
  initialState,
  compose(
    composeEnhancers(),
  )
  // composeEnhancers(),
)

store.subscribe(() => {
  saveState({
    corp: store.getState().form.corp,
    dba: store.getState().form.dba,
  });
});

export default store;