import React, { useState } from 'react';
import {
  Col,
  FormGroup,
  CustomInput,
  Modal, 
  ModalHeader, 
  ModalBody
} from 'reactstrap';
import { displayPriceDollar } from "../../../utils/displayPrice";

const CertificateOfOwnershipAndSeals = (props) => {
  const pid = 706;
  const [modal, setModal] = useState(false);
  const toggle = (e) => {
    e.preventDefault();
    setModal(!modal);
  }
  const price = displayPriceDollar(pid);

  return (
    <FormGroup className="form-row action-area">
      <Col sm={12} className="ml-md-auto">
        <CustomInput onChange={props.onChange} checked={props.checked} type="checkbox" value={pid} name={props.name} id={props.id} label={<span>Certificates of Ownership and Seals - ${price} <a href="//:0" onClick={toggle} className="upsell_desc_trigger">?</a></span>} inline data-hj-masked />
        <Modal centered isOpen={modal} toggle={toggle} size="lg">
          <ModalHeader toggle={toggle}>Certificates of Ownership and Seals</ModalHeader>
          <ModalBody>
            <p>Ownership in an LLC is conveyed by certificates of ownership. By issuing these certificates for an LLC you are issuing ownership in that LLC. Certificates are the legal document that shows this ownership. Some government agencies may require seeing the issued certificates to see proof of ownership. These certificates are personalized and come with personalized seals showing the year of incorporation.</p>
            <p className="mb-0"><strong>Please note</strong>: If you are applying for <strong>Workman's Compensation Exemption</strong>, you will need to supply a certificate of ownership to them with your application of exemption.</p>
          </ModalBody>
        </Modal>
      </Col>
    </FormGroup>
  );
}

export default CertificateOfOwnershipAndSeals;