import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NoMatch from "./pages/NoMatch";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AccountInformation from "./pages/AccountInformation";
import BusinessInformation from "./pages/BusinessInformation";
import DBABusinessInformation from "./pages/DBABusinessInformation";
import BusinessManagement from "./pages/BusinessManagement";
import DBABusinessOwnership from "./pages/DBABusinessOwnership";
import RegisteredAgent from "./pages/RegisteredAgent";
import BusinessOptions from "./pages/BusinessOptions";
import EINContact from "./pages/EINContact";
import EINApplication from "./pages/EINApplication";
import EntityClassification from "./pages/EntityClassification";
import LastSteps from "./pages/LastSteps";
import DBALastSteps from "./pages/DBALastSteps";
import CompanySummary from "./pages/CompanySummary";
import CompletedApplication from "./pages/CompletedApplication";
import CompletedSummary from "./pages/CompletedSummary";
import LoginCallback from "./pages/LoginCallback";
import UsernameCheck from "./pages/UsernameCheck";
import Logout from "./pages/Logout";
import SessionExpired from "./pages/SessionExpired";
import LoginRenew from "./pages/LoginRenew";

import TagManager from "react-gtm-module";

import { Provider } from "react-redux";
import store from "./store";
import userManager, { loadUserFromStorage } from "./services/authService";
import AuthProvider from "./utils/authProvider";
import PrivateRoute from "./utils/protectedRoute";
import ErrorHandlerRoute from "./utils/errorHandlerRoute";

import "./scss/App.scss";

const tagManagerArgs = {
  gtmId: "GTM-NCNHK6",
};
TagManager.initialize(tagManagerArgs);

function App() {
  useEffect(() => {
    loadUserFromStorage(store);
  }, []);

  return (
    <Provider store={store}>
      <AuthProvider userManager={userManager} store={store}>
        <Router>
          <Header />
          <main>
            <ErrorHandlerRoute>
              <Switch>
                <Route exact path='/' component={UsernameCheck} />
                {/* <Route exact path="/account-info" component={AccountInformation} />
                <Route exact path="/account-info/dba" component={DBAAccountInformation} /> */}
                {/* <Route exact path='/account-info/:accountType' component={AccountInformation} /> */}
                <PrivateRoute exact path='/contact-info/:accountType' component={AccountInformation} />

                {/* <Route exact path="/business-info/" component={BusinessInformation} /> */}
                {/* <Route exact path="/business-info/dba" component={DBABusinessInformation} /> */}
                {/* <PrivateRoute exact path="/business-info/:accountType" component={BusinessInformation} /> */}
                {/* <PrivateRoute
                  exact
                  path='/business-info/:accountType'
                  render={(props) =>
                    props.match.params.accountType === "dba" ? (
                      <DBABusinessInformation {...props} />
                    ) : (
                      <BusinessInformation {...props} />
                    )
                  }
                /> */}
                <Route
                  exact
                  path='/business-info/:accountType'
                  render={(props) =>
                    props.match.params.accountType === "dba" ? (
                      <DBABusinessInformation {...props} />
                    ) : (
                      <BusinessInformation {...props} />
                    )
                  }
                />
                {/* adding as fail-safe for user cached marketing website registration URLs */}
                <Route
                  exact
                  path='/account-info/:accountType'
                  render={(props) =>
                    props.match.params.accountType === "dba" ? (
                      <DBABusinessInformation {...props} />
                    ) : (
                      <BusinessInformation {...props} />
                    )
                  }
                />

                <PrivateRoute exact path='/business-management/:accountType' component={BusinessManagement} />
                <PrivateRoute exact path='/registered-agent/:accountType' component={RegisteredAgent} />
                <PrivateRoute exact path='/business-options/:accountType' component={BusinessOptions} />
                <PrivateRoute exact path='/ein-contact/:accountType' component={EINContact} />
                <PrivateRoute exact path='/ein-application/:accountType' component={EINApplication} />
                <PrivateRoute exact path='/entity-classification/:accountType' component={EntityClassification} />

                <PrivateRoute exact path='/business-ownership/:accountType' component={DBABusinessOwnership} />

                {/* <Route exact path="/last-steps/dba" component={DBALastSteps} />
                <Route exact path="/last-steps/:accountType" component={LastSteps} /> */}
                <PrivateRoute
                  exact
                  path='/last-steps/:accountType'
                  render={(props) =>
                    props.match.params.accountType === "dba" ? <DBALastSteps {...props} /> : <LastSteps {...props} />
                  }
                />

                <PrivateRoute exact path='/company-summary' component={CompanySummary} />
                <PrivateRoute exact path='/company-summary/:accountType' component={CompanySummary} />

                <Route exact path='/completed-application' component={CompletedApplication} />
                <Route exact path='/completed-summary/' component={CompletedSummary} />
                <Route exact path='/completed-summary/:accountType' component={CompletedSummary} />
                <Route exact path='/login' component={UsernameCheck} />
                <Route exact path='/callback' component={LoginCallback} />
                <Route exact path='/login-renew' component={LoginRenew} />
                <Route exact path='/callback-renew' component={LoginRenew} />
                <Route exact path='/logout' component={Logout} />
                <Route exact path='/session-expired' component={SessionExpired} />

                <PrivateRoute exact path='/private' component={AccountInformation} />

                <Route component={NoMatch} />
              </Switch>
            </ErrorHandlerRoute>
          </main>
          <Footer />
        </Router>
      </AuthProvider>
    </Provider>
  );
}

export default App;
