export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('fisFormState');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('fisFormState', serializedState);
  } catch {
    // ignore write errors
    console.error("Store state in sessionStorage failed!")
  }
};

