import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
} from 'reactstrap';
import queryString from "query-string";
import {Helmet} from "react-helmet";
import { Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import API from "../services/apiService";
import { generatePids } from "../utils/productMapping";
import Sidebar from "../components/Sidebar";
import ProgressStatus from "../components/ProgressStatus";
import FormNav from "../components/FormNav";
import UserAgreementCheckbox from "../components/UserAgreementCheckbox";
import FormValidationAlert from "../components/FormValidationAlert";
import ProcessingStatus from "../components/ProcessingStatus";
import FormErrorModal from "../components/FormErrorModal";
import ApplicationCompleteAlert from "../components/ApplicationCompleteAlert";
import { formErrorHandler, appErrorHandler } from "../utils/errorHandlerUtil";
import InputMask from 'react-input-mask';
import { storeApplication } from '../store/actions/formActions'	
import { accountInfoState } from "../utils/storeState";

function DBALastSteps(props) {
  const form = useSelector(state => state.form);
  const dispatch = useDispatch();  

  const pageTitle = "Florida Incorporation Service Registration | Last Steps",
        registrationStep = 2,
        accountType = props.match.params.accountType?.toLowerCase(),
        orderDetails = JSON.parse(sessionStorage.getItem("orderDetails")) || [],
        host = queryString.parse(props.location.search)?.host || "",
        formType = accountType === "dba" ? "dba" : "corp";

  // checks if current url is valid for the stored order data
  const { pid, lgr, pkg } = queryString.parse(props.location.search);
  if (!pid || !lgr || !host || (accountType !== "dba" ? !pkg : false)) {
    appErrorHandler("Custom Error", `Missing query string ${props.history.location.pathname}${props.history.location.search}`, dispatch)
    // props.history.replace(`/error/${accountType}/${props.location.search}`);
  }
  if (orderDetails.length && (Number(orderDetails[0].id) !== Number(pid) || orderDetails[0].lgr !== lgr?.toLowerCase())) {
    sessionStorage.removeItem('orderDetails');
    sessionStorage.removeItem('productsData');
    sessionStorage.removeItem('fisFormState');
    props.history.replace(`/account-info/${accountType}/${props.location.search}`);
  }

  const initState = {
    formState: {
      ownerPhone: form[formType].signatories[0]?.phone || "",
      ownerSignature: form[formType].signatories[0]?.name || "",
      userAgreement: form[formType].acceptsAgreement,
      electronicSignature: form[formType].applicantSig || "",
      pointOfContactEmail: form[formType].pointOfContact.emailAddress || "",
      fisPackage: form[formType].registrationVars?.fisPackage || "",
      lgr: form[formType].registrationVars?.lgr || "",
      optionalProducts: form[formType].optionalProducts || [], // array can contain one or more of the following: SubSElection, BylawsAndMinutes, CertificateOfStatus, CorporateEmbosser, CorpSealsAndCerts, Form8832Filing, Form8832Filing, LLCSealsAndCerts, OperationalAgreement 
      optionalServices: form[formType].optionalServices || [],
    },
     // isProcessing: true,
  };

  const checkedProducts = Object.keys(initState.formState).filter(key => initState.formState[key]); // generate product keys for generatePids()
  const generatedPids = generatePids([...checkedProducts, ...initState.formState.optionalProducts, ...initState.formState.optionalServices])
  const uniquePids = new Set([Number(pid), ...generatedPids]); // removing duplicate PIDs
  // const productKeys = generateKeys([...uniquePids]); // object of product keys with true value

  const [state, setState] = useState({
    ...initState,
    formState: {
      ...initState.formState,
    },
    orderDetailPids: [...uniquePids]
  });

  // handleChange = event => {
  //   const { name, value, type, checked } = event.target;
  //   const theValue = type === 'checkbox' ? checked : value;
  //   setState({
  //     [name]: theValue
  //   }, () => console.log(state));
  // }

  const handleFormSubmit = (values, actions, nextUrl) => {
    // console.log(values);
    setState(state => ({
      ...state,
      formState: values,
      isProcessing: true,
    }));

    const goToNextPage = (apiData) => {
      sessionStorage.removeItem('fisFormState'); // deleting current formState from sessionStorage
      setState(state => ({
        ...state,
        isProcessing: false,
      }));
      // creating object that matched the redux state object to prevent removing any key/values that's not updated
      const applicationState = accountInfoState(accountType, form, apiData);
      dispatch(storeApplication(applicationState)) // sets redux store with form info
      // console.log("Submitted, no errors in form!")
      actions.resetForm(true);
      const nextUrl = `/company-summary/dba/${props.location.search}`;
      props.history.push(nextUrl); // push user to next page after form submission or api call
      window.scrollTo(0, 0) // making sure that user view is top of the page
    }

    const accountId = form[formType].account.id; // grabbing from redux
    const companyId = form[formType].id; // grabbing from redux
    const companyInfo = {
      signatories: [
        {
          dbaApplicantId: companyId,
          name: values.ownerSignature,
          phone: values.ownerPhone,
        }
      ],
      registrationVars: {
        useElectronicSig: true,
      },
    }
    const fillingAuthorization = {
      acceptsAgreement: values.userAgreement,
      applicantSig: values.electronicSignature,
    }
    API.patchDbaApplicant(accountId, companyId, companyInfo).then((companyData) => {
      // console.log("Updated DBA:", companyData.data);
      return API.setDbaFillingAuthorization(accountId, companyId, fillingAuthorization)
    })
    .then((companyData) => {
      // console.log("Updated DBA:", companyData.data);
      goToNextPage(companyData.data);
    })
    .catch(err => {
      // console.log(err)
      formErrorHandler(err, setApiErrorState);
    });
  }
  
  const setApiErrorState = (err) => {
    setState(state => ({
      ...state,
      apiErrors: err,
    }));
  }

  const validationSchema = Yup.object().shape({
    ownerSignature: Yup.string().min(2, 'Owner\'s electronic signature is too Short!').max(255, 'Owner\'s electronic signature is too Long!').required('Owner\'s electronic signature is required'),
    ownerPhone: Yup.string().matches(/^[1-9]\d{2}-\d{3}-\d{4}$/, { message: 'Phone must match this format: XXX-XXX-XXXX', excludeEmptyString: true }).required('Phone number is required'),
    userAgreement: Yup.boolean().oneOf([true], 'The user agreement must be accepted.'),
    electronicSignature: Yup.string().min(2, 'Electronic signature is  too Short!').max(255, 'Electronic signature is too Long!').required('Electronic signature is required'),
  })

  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href="https://www.floridaincorporationservice.com" />
      </Helmet>

      <ProgressStatus registrationStep={registrationStep} />

      <Container>
        <Row className="justify-content-md-center">
          {form[formType].isComplete || form[formType].orderInfoId ? 
            <ApplicationCompleteAlert />
          : null }          
          <Col lg="8" md="10" sm="12">
            <Formik
              enableReinitialize={true}
              initialValues={state.formState}
              validationSchema={validationSchema}
              // onChange={values => handleChange(values)}
              onSubmit={(values, actions) => handleFormSubmit(values, actions)}
            >
              {({ isValid, errors, touched, values, handleChange, submitCount, isSubmitting }) => (
                <Form>
                  <h2>Signatures</h2>
                  <h4>New! Legal Organ</h4>
                  <p>As of July 1, 2009, the Florida Department of State mandates that all new Florida fictitious name registrations must include an attestation that the fictitious name has or will be advertised at least once in a daily or weekly newspaper in the county where the principal place of business of the applicant. This process must be completed within 48 hours of completion of your registration with Florida Incorporation Service.</p>
                  <p>An owner must enter the signature in the "Owner's Signature" area. A person's name has to appear in this field. If the owner of the entity is a business, a person must sign on behalf of the entity. This can be a director or officer of the business entity. By "signing" in the signature area, you are certifying that all the information entered is true and accurate.</p>
                  <p>It is preferred that a daytime phone number be provided in order to contact the applicant if there are any questions about the application.</p>
                  <p><em>I (we) the undersigned certify that I have or will file the mandatory publishing with the legal organ in my county.</em></p>
                  <p><em>I (we) the undersigned, being the sole (all the) party(ies) owning interest in the above fictitious name, certify that the information indicated on this form is true and accurate. I (we) understand that the signature(s) below shall have the same legal effect as if made under oath.</em></p>
                  <div className="indent-wrap mb-4">
                    <p className="mb-1"><strong>Owner's Electronic Signature</strong></p>
                    <FormGroup className="form-row">
                      <Col sm={8}>
                        <Input tag={Field} invalid={errors.ownerSignature && touched.ownerSignature} onChange={handleChange} value={values.ownerSignature} type="text" name="ownerSignature" id="ownerSignature" data-hj-masked />
                      </Col>
                    </FormGroup>
                    <p className="mb-1"><strong>Phone</strong></p>
                    <FormGroup className="form-row">
                      <Col sm={8}>
                        {/* <Input tag={Field} invalid={errors.ownerPhone && touched.ownerPhone} onChange={handleChange} value={values.ownerPhone} type="tel" name="ownerPhone" id="ownerPhone" data-hj-masked /> */}
                        <InputMask className={errors.ownerPhone && touched.ownerPhone ? "form-control is-invalid" : "form-control"} onChange={handleChange} value={values.ownerPhone} type="tel" name="ownerPhone" id="ownerPhone" data-hj-masked mask="999-999-9999" maskChar={"_"} placeholder="___-___-____" />
                      </Col>
                    </FormGroup>
                  </div>
                  <h2>Last Steps</h2>
                  <p>You're almost done! We will now review your information and complete your documents. Upon completion of the registration, it will be posted with the Division of Corporations in 1-2 business days. You will receive an email confirmation of your filing at <strong>{values.pointOfContactEmail}</strong>.</p>
                  <p>The final step is to authorize Florida Incorporation Service to file these documents with the Division of Corporations, on your behalf. Because our service is entirely online, you must do this by providing your "electronic signature," meaning simply that you must accept the user agreement, and type your name into the box below.</p>
                  <div className="indent-wrap">
                    <UserAgreementCheckbox className={errors.userAgreement && touched.userAgreement ? "is-invalid" : ""} onChange={handleChange} checked={values.userAgreement} name="userAgreement" id="userAgreement" userAgreementFor={accountType} required />
                    <p className="mb-1"><strong>Electronic Signature</strong></p>
                    <FormGroup className="form-row">
                      <Col sm={8}>
                        <Input tag={Field} invalid={errors.electronicSignature && touched.electronicSignature} onChange={handleChange} value={values.electronicSignature} type="text" name="electronicSignature" id="electronicSignature" data-hj-masked />
                      </Col>
                    </FormGroup>
                  </div>

                  <FormValidationAlert
                    submitCount={submitCount}
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                    errors={errors}
                  />

                  <ProcessingStatus showProcessing={state.isProcessing && !state.apiErrors} />

                  <FormErrorModal errors={state.apiErrors} />

                  {(!form[formType].isComplete || !form[formType].orderInfoId) ?
                    <FormNav action={{prev: `/business-ownership/dba/${props.location.search}`}} />
                  : null }
                  
                </Form>
              )}
            </Formik>
          </Col>

          <Sidebar 
            orderDetailPids={state.orderDetailPids} 
            host={host} 
            lgr={state.formState.lgr} 
          />

        </Row>
      </Container>
    </Fragment>
  );
}

export default DBALastSteps;
