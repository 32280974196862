import React from "react";
import {
  Col,
} from 'reactstrap';

import OrderSummary from "../OrderSummary";

const Sidebar = ({orderDetailPids, host, lgr, isLoading, ...props}) =>  {
  return (
    <Col lg="4" md="10" sm="12">
      <OrderSummary orderDetailPids={orderDetailPids} host={host} lgr={lgr} isLoading={isLoading} />
    </Col>
  );
}

export default Sidebar;