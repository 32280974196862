// this page is used as the "callback" page for /services/authServices.js set in the "redirect_uri" config
import React, { Fragment, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
} from 'reactstrap';
import { Helmet } from "react-helmet";
import { signinRedirect } from '../services/authService';

function UsernamcCheck(props) {  
  const { Identity } = JSON.parse(sessionStorage.getItem("appsettings"));
  const fisAuth = Identity.Authority;
  let oldLoginUrlPrefix = "application";
  if (fisAuth.includes("dlauth")) {
    oldLoginUrlPrefix = "dlapplication";
  }
  if (fisAuth.includes("mlauth")) {
    oldLoginUrlPrefix = "mlapplication";
  }

  const initState = {
    username: "",
    usernameTouched: false,
    validUsername: true,
  }

  const [state, setState] = useState({
    ...initState,
  });

  const validUsernameFromEmail = (username) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(username);
  }
  
  const handleChange = event => {
    const { name, value, type, checked } = event.target;
    const theValue = type === 'checkbox' ? checked : value;
    setState(state => ({
      ...state,
      [name]: theValue,
      validUsername: theValue.length >= 6,
    }));
  }

  const handleSubmit = event => {
    event.preventDefault();
    setState(state => ({
      ...state,
      usernameTouched: true,
      validUsername: validUsernameFromEmail(state.username) || state.username.length >= 6,
    }))
    if (state.username && state.username.length >= 6 && validUsernameFromEmail(state.username)) {
      const prefixLength = state.username.includes("_") ? state.username.split("_")[0].length : 0;
      // 5 is the prefix character length for the new app
      if (prefixLength <= 5 && prefixLength >= 3) {
        // console.log("old account")
        window.location.href = `https://${oldLoginUrlPrefix}.floridaincorporationservice.com/Login.aspx`;
      }
      else {
        signinRedirect({ login_hint: state.username })
      }
    }
    else if (state.username && state.username.length >= 6) {
      signinRedirect({ login_hint: state.username })
    }
  }
  
  const touchedUsername = event => {
    const { value, type, checked } = event.target;
    const theValue = type === 'checkbox' ? checked : value;
    setState(state => ({
      ...state,
      usernameTouched: true,
      validUsername: theValue.length >= 6,
    }))
  }

  return (
    <Fragment>
      <Helmet>
        <title>Username Check</title>
        <link rel="canonical" href="https://www.floridaincorporationservice.com" />
      </Helmet>
      <Container>
        <Row className="justify-content-center">
          <Col lg="6" md="8" sm="10">
            <h1 className="mt-4 mb-1 text-center">Welcome Back</h1>
            <p className="text-center">Please enter your username and click continue</p>
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label className="text-sm-right" for="username">Username</Label>
                    <Input onChange={handleChange} onBlur={touchedUsername} invalid={(state.username === "" || !state.validUsername) && state.usernameTouched} value={state.username} type="text" name="username" id="username" data-hj-masked />
                  </FormGroup>
                  <FormGroup className="justify-content-end mb-0">
                    <Button className="app-btn next-btn" color="success" size="lg" type="submit">
                      Continue <i className="material-icons">arrow_forward</i>
                    </Button>
                    {(!state.username && state.usernameTouched) ? 
                      <span className="username-error ml-sm-2 text-danger">Please enter your username</span>
                    : (!state.validUsername && state.usernameTouched) ? 
                      <span className="username-error ml-sm-2 text-danger">Please check username format</span>
                    : null }
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default UsernamcCheck;
