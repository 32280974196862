import React, { Fragment } from "react";
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import { formatDate } from 'react-day-picker/moment';

const EINApplication = (props) => {
  const officers = props.data.officers;
  // const directors = props.data.directors;
  const numStockShares = props.data.numStockShares;
  const addEIN = props.data.registrationVars.addEIN;
  const einDirectorId = props.data.einDirectorId
  const numHouseholdEmployees = props.data.numHouseholdEmployees;
  const numAgriculturalEmployees = props.data.numAgriculturalEmployees;
  const numOtherEmployees = props.data.numOtherEmployees;
  const firstDayOfBusiness = props.data.firstDayOfBusiness;
  const firstWagesDate = props.data.firstWagesDate;
  const managerManagedEinPerson = props.data.managerManagedEinPerson;
  const ownerFirstName = props.data.directors?.find(director => director.id === einDirectorId)?.person.firstName || managerManagedEinPerson?.firstName || "";
  const ownerLastName = props.data.directors?.find(director => director.id === einDirectorId)?.person.lastName || managerManagedEinPerson?.lasstName || "";
  const ownerBusinessPhone = props.data.directors?.find(director => director.id === einDirectorId)?.person.businessPhone || managerManagedEinPerson?.businessPhone || "";
  const ownerSSN = props.data.directors?.find(director => director.id === einDirectorId)?.person.ssn || managerManagedEinPerson?.ssn || "";
  const irsFirstName = props.data.irsContactPerson?.firstName;
  const irsLastName = props.data.irsContactPerson?.lastName;
  const irsBusinessPhone = props.data.irsContactPerson?.businessPhone;
  const presidentSSN = officers?.find(officer => officer.title === "President")?.person.ssn;

  const editLink = `/business-options/${props.accountType}/${props.queryString}`;
  const editEINLink = `/ein-application/${props.accountType}/${props.queryString}`;
  const editEINContactLink = `/ein-contact/${props.accountType}/${props.queryString}`;

  const handleEdit = event => {
    event.preventDefault();
    props.history.push(editLink);
    window.scrollTo(0, 0) // making sure that user view is top of the page
  }
  const handleEditEin = event => {
    event.preventDefault();
    props.history.push(editEINLink);
    window.scrollTo(0, 0) // making sure that user view is top of the page
  }
  const handleEditEinContact = event => {
    event.preventDefault();
    props.history.push(editEINContactLink);
    window.scrollTo(0, 0) // making sure that user view is top of the page
  }

  const showEdit = !(props.data.orderInfoId || props.data.isComplete);

  if (officers || numStockShares || addEIN || numHouseholdEmployees || numAgriculturalEmployees || numOtherEmployees || firstDayOfBusiness || firstWagesDate || irsFirstName || irsLastName || irsBusinessPhone) {
    return (
      <Fragment>
        <Card className="card-summary">
          <CardHeader tag="h5" className="mt-0">EIN Application {showEdit && <a href="//:0" onClick={handleEdit} className="edit ml-auto">Edit</a>}</CardHeader>
          <ListGroup flush>
            <ListGroupItem>
              <span className="info-title">File an EIN Application?</span>
              <span className="info-data">{addEIN ? "Yes" : "No"}</span>
            </ListGroupItem>
          </ListGroup>
        </Card>
        { addEIN ? 
          <Card className="card-summary">
            <CardHeader tag="h5" className="mt-0">EIN Application Information {showEdit && <a href="//:0" onClick={handleEditEin} className="edit ml-auto">Edit</a>}</CardHeader>
            <ListGroup flush>
              <ListGroupItem>
                <span className="info-title">Household Employees:</span>
                <span className="info-data">{numHouseholdEmployees ? numHouseholdEmployees : 0}</span>
              </ListGroupItem>
              <ListGroupItem>
                <span className="info-title">Agricultural Employees:</span>
                <span className="info-data">{numAgriculturalEmployees ? numAgriculturalEmployees : 0}</span>
              </ListGroupItem>
              <ListGroupItem>
                <span className="info-title">Other Employees:</span>
                <span className="info-data">{numOtherEmployees ? numOtherEmployees : 0}</span>
              </ListGroupItem>
              <ListGroupItem>
                <span className="info-title">First Day in Business:</span>
                <span className="info-data">{formatDate(firstDayOfBusiness) !== "Invalid date" ? formatDate(firstDayOfBusiness) :  "Not Provided"}</span>
              </ListGroupItem>
              <ListGroupItem>
                <span className="info-title">First Day of Wages:</span>
                <span className="info-data">{formatDate(firstWagesDate) !== "Invalid date" ? formatDate(firstWagesDate) : "Not Provided"}</span>
              </ListGroupItem>
              { props.accountType !== "llc" ? 
                <ListGroupItem>
                  <span className="info-title">President's SSN:</span>
                  <span className="info-data" data-private>{presidentSSN}</span>
                </ListGroupItem>
              : null }
            </ListGroup>
          </Card>
        : null }
        { addEIN && props.accountType === "llc" && (irsFirstName && (einDirectorId || managerManagedEinPerson)) ? 
          <Card className="card-summary">
            <CardHeader tag="h5" className="mt-0">EIN Contact Info {showEdit && <a href="//:0" onClick={handleEditEinContact} className="edit ml-auto">Edit</a>}</CardHeader>
            <ListGroup flush>
              <ListGroupItem>
                <span className="info-title">Owner on EIN:</span>
                <span className="info-data">{ownerFirstName} {ownerLastName}</span>
              </ListGroupItem>
              <ListGroupItem>
                <span className="info-title">Owner's Phone:</span>
                <span className="info-data">{ownerBusinessPhone}</span>
              </ListGroupItem>
              <ListGroupItem>
                <span className="info-title">Managing Member's SSN:</span>
                <span className="info-data" data-private>{ownerSSN}</span>
              </ListGroupItem>
              <ListGroupItem>
                <span className="info-title">IRS Contact Name:</span>
                <span className="info-data">{irsFirstName} {irsLastName}</span>
              </ListGroupItem>
              <ListGroupItem>
                <span className="info-title">IRS Contact Phone:</span>
                <span className="info-data">{irsBusinessPhone}</span>
              </ListGroupItem>
            </ListGroup>
          </Card>
        : null }
      </Fragment>
    );
  }
  else {
    return null;
  }
  
}

export default withRouter(EINApplication);

