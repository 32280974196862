import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, FormGroup, Label, Input, CustomInput, FormText } from "reactstrap";
import queryString from "query-string";
import { Helmet } from "react-helmet";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import API from "../services/apiService";
import jwt_decode from "jwt-decode";
import { generatePids } from "../utils/productMapping";
import Sidebar from "../components/Sidebar";
import StateDropdown from "../components/StateDropdown";
import CountyDropdown from "../components/CountyDropdown";
import BusinessActivityDropdown from "../components/BusinessActivityDropdown";
import SuffixDropdown from "../components/SuffixDropdown";
import ProgressStatus from "../components/ProgressStatus";
import FormNav from "../components/FormNav";
import FormValidationAlert from "../components/FormValidationAlert";
import ProcessingStatus from "../components/ProcessingStatus";
import FormErrorModal from "../components/FormErrorModal";
import ApplicationCompleteAlert from "../components/ApplicationCompleteAlert";
import { formErrorHandler, appErrorHandler } from "../utils/errorHandlerUtil";
import InputMask from "react-input-mask";
import { storeApplication } from "../store/actions/formActions";
import { accountInfoState } from "../utils/storeState";
import { storeUser } from "../store/actions/authActions";

function BusinessInformation(props) {
  const form = useSelector((state) => state.form);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const pageTitle = "Florida Incorporation Service Registration | Business Information",
    registrationStep = 1,
    accountType = props.match.params.accountType?.toLowerCase(),
    orderDetails = JSON.parse(sessionStorage.getItem("orderDetails")) || [],
    host = queryString.parse(props.location.search)?.host || "",
    formType = accountType === "dba" ? "dba" : "corp",
    serviceType = accountType.toLowerCase() === "corp" ? 0 : accountType.toLowerCase() === "llc" ? 2 : 3;

  // checks if current url is valid for the stored order data
  const { pid, lgr, pkg } = queryString.parse(props.location.search);
  if (!pid || !lgr || !host || (accountType !== "dba" ? !pkg : false)) {
    appErrorHandler(
      "Custom Error",
      `Missing query string ${props.history.location.pathname}${props.history.location.search}`,
      dispatch
    );
    // props.history.replace(`/error/${accountType}/${props.location.search}`);
  }
  if (
    orderDetails.length &&
    (Number(orderDetails[0].id) !== Number(pid) || orderDetails[0].lgr !== lgr?.toLowerCase())
  ) {
    sessionStorage.removeItem("orderDetails");
    sessionStorage.removeItem("productsData");
    sessionStorage.removeItem("fisFormState");
    // props.history.replace(`/account-info/${accountType}/${props.location.search}`);
    // props.history.push(`/account-info/${accountType}/${props.location.search}`);
  }

  const initState = {
    formState: {
      companyName1: form[formType].nameCandidates[0]?.name || "",
      companyName2: form[formType].nameCandidates[1]?.name || "",
      // companyName3: form[formType].nameCandidates[1].name || "",
      companySuffix1:
        form[formType].nameCandidates[0]?.suffix || `${props.match.params.accountType === "llc" ? ", LLC" : ", Inc."}`,
      companySuffix2:
        form[formType].nameCandidates[1]?.suffix || `${props.match.params.accountType === "llc" ? ", LLC" : ", Inc."}`,
      // companysuffix3: form[formType].nameCandidates[1].suff1x || `${props.match.params.accountType === "llc" ? ", LLC" : ", Inc"}`,
      businessActivity: form[formType].businessActivity || "",
      businessActivityOther: form[formType].businessActivityOther || "",
      businessPurposeSummary: form[formType].companyPurpose || "",
      street1: form[formType].principalOffice?.street1 || "",
      street2: form[formType].principalOffice?.street2 || "",
      city: form[formType].principalOffice?.city || "",
      county: form[formType].principalOffice?.county || "",
      postalCode: form[formType].principalOffice?.postalCode || "",
      state: form[formType].principalOffice?.stateProvince || "FL",
      country: form[formType].principalOffice?.country || "US",
      phone: form[formType].principalOffice?.phone || "",
      sameMailingAddress: "principal",
      mailingStreet1: form[formType].mailingAddress?.street1 || "",
      mailingStreet2: form[formType].mailingAddress?.street2 || "",
      mailingCity: form[formType].mailingAddress?.city || "",
      mailingPostalCode: form[formType].mailingAddress?.postalCode || "",
      mailingState: form[formType].mailingAddress?.stateProvince || "FL",
      mailingCountry: form[formType].mailingAddress?.country || "US",
      pointOfContact: form[formType].pointOfContact || {},
      fisPackage: form[formType].registrationVars?.fisPackage || "",
      lgr: form[formType].registrationVars?.lgr || queryString.parse(props.location.search).lgr?.toLowerCase() || "",
      optionalProducts: form[formType].optionalProducts || [], // array can contain one or more of the following: SubSElection, BylawsAndMinutes, CertificateOfStatus, CorporateEmbosser, CorpSealsAndCerts, Form8832Filing, Form8832Filing, LLCSealsAndCerts, OperationalAgreement
      optionalServices: form[formType].optionalServices || [],
    },
    // isProcessing: true,
  };

  const checkedProducts = Object.keys(initState.formState).filter((key) => initState.formState[key]); // generate product keys for generatePids()
  const generatedPids = generatePids([
    ...checkedProducts,
    ...initState.formState.optionalProducts,
    ...initState.formState.optionalServices,
  ]);
  const uniquePids = new Set([Number(pid), ...generatedPids]); // removing duplicate PIDs
  // const productKeys = generateKeys([...uniquePids]); // object of product keys with true value

  const [state, setState] = useState({
    ...initState,
    orderDetailPids: [...uniquePids],
  });

  // old app's behavior is to create a new account for every new registration coming from a frontend link
  // using ref=self means page visit is coming from the app itself
  const refQuery = queryString.parse(props.location.search)?.ref === "self";
  const newApplication =
    !auth.user ||
    !refQuery ||
    (auth.user && form[formType].account?.id === 0) ||
    (refQuery && !auth.user && form[formType].account?.id === 0);

  useEffect(() => {
    if (newApplication) {
      sessionStorage.removeItem("orderDetails");
      sessionStorage.removeItem("productsData");
      sessionStorage.removeItem("fisFormState");
      const applicationState = accountInfoState(null, null, {});
      dispatch(storeApplication(applicationState)); // sets redux store with form info
      setState((state) => ({
        ...state,
        orderDetailPids: [Number(pid)],
      }));
    }
  }, [dispatch, newApplication, pid]);

  const handleFormSubmit = (values, actions) => {
    // console.log(values);
    setState((state) => ({
      ...state,
      formState: values,
      isProcessing: true,
    }));

    const goToNextPage = (apiData) => {
      sessionStorage.removeItem("fisFormState"); // deleting current formState from sessionStorage
      setState((state) => ({
        ...state,
        isProcessing: false,
      }));
      // creating object that matched the redux state object to prevent removing any key/values that's not updated
      const applicationState = accountInfoState(accountType, form, apiData);
      dispatch(storeApplication(applicationState)); // sets redux store with form info
      actions.resetForm(true);
      const nextUrl = `/contact-info/${accountType}/${props.location.search}${!refQuery ? "&ref=self" : ""}`;
      // need to replace history to add &ref=self in the url, meaning user have been authenticated and is not coming from a frontend link
      const refUrl = refQuery
        ? props.history.location.pathname + props.history.location.search
        : props.history.location.pathname + props.history.location.search + "&ref=self";
      props.history.replace(refUrl); // replace history to add &ref=self in the url
      props.history.push(nextUrl); // push user to next page after form submission or api call
      window.scrollTo(0, 0); // making sure that user view is top of the page
    };

    let formValues = values;
    if (values.sameMailingAddress === "principal") {
      formValues = {
        ...values,
        mailingStreet1: values.street1,
        mailingStreet2: values.street2,
        mailingCity: values.city,
        mailingPostalCode: values.postalCode,
        mailingState: values.state,
      };
    } else if (values.sameMailingAddress === "contact") {
      formValues = {
        ...values,
        mailingStreet1: form[formType].pointOfContact.addresses[0]?.street1,
        mailingStreet2: form[formType].pointOfContact.addresses[0]?.street2,
        mailingCity: form[formType].pointOfContact.addresses[0]?.city,
        mailingPostalCode: form[formType].pointOfContact.addresses[0]?.postalCode,
        mailingState: form[formType].pointOfContact.addresses[0]?.stateProvince,
      };
    }

    const companyInfo = {
      nameCandidates: [
        {
          name: formValues.companyName1,
          suffix: formValues.companySuffix1,
          choice: 1,
        },
        {
          name: formValues.companyName2,
          suffix: formValues.companySuffix2,
          choice: 2,
        },
      ],
      businessActivity: formValues.businessActivity,
      businessActivityOther: formValues.businessActivityOther,
      companyPurpose: formValues.businessPurposeSummary,
      mailingAddress: {
        street1: formValues.mailingStreet1,
        street2: formValues.mailingStreet2,
        city: formValues.mailingCity,
        postalCode: formValues.mailingPostalCode,
        stateProvince: formValues.mailingState,
        country: formValues.mailingCountry,
      },
    };
    const principalOffice = {
      street1: formValues.street1,
      street2: formValues.street2,
      city: formValues.city,
      postalCode: formValues.postalCode,
      county: formValues.county,
      // countyId: formValues.county,
      stateProvince: formValues.state,
      country: formValues.country,
      phone: formValues.phone,
    };

    const setAccessToken = async (accountInfo) => {
      // console.log(accessData.data)
      const decodedToken = jwt_decode(accountInfo.accessToken.accessToken);
      const user = {
        access_token: accountInfo.accessToken.accessToken,
        token_type: accountInfo.accessToken.tokenType,
        refresh_token: accountInfo.accessToken.refreshToken,
        expires_in: decodedToken.exp,
        profile: {
          // sub: decodedToken.sub,
          peferred_username: accountInfo.account.userName,
        },
      };
      // also need to save in sessionStorage
      const { Identity } = JSON.parse(sessionStorage.getItem("appsettings"));
      // saving user info in sessionStorage give oidc-client-js all it needs to rehydrate its redux store
      sessionStorage.setItem(`oidc.user:${Identity.Authority}:${Identity.ClientId}`, JSON.stringify(user));
      dispatch(storeUser(user)); // sets redux store with form info, also sets bearer token header
    };

    const createUpdateAccountCompany = () => {
      if (newApplication) {
        return API.createAnonymousAccount();
      } else {
        // console.log("patchCompany")
        return API.patchCompany(form[formType].account?.id, form[formType].id, companyInfo);
      }
    };

    createUpdateAccountCompany()
      .then(async (accountCompanyData) => {
        // console.log("Created/Updated Account:", accountData.data);
        const accountId = accountCompanyData.data.account?.id || form[formType].account?.id || null;
        const companyInfo = {
          accountId: accountId,
          serviceType: serviceType,
        };
        if (newApplication || (!newApplication && form[formType].id === 0)) {
          await setAccessToken(accountCompanyData.data);
          // console.log("await patchAccount")
          return API.createCompany(accountId, companyInfo);
        } else {
          return accountCompanyData;
        }
      })
      .then(async (companyData) => {
        // console.log("Updated Company:", companyData.data);
        // console.log(JSON.stringify(companyData, 0, 2))
        const accountId = companyData.data.account?.id || form[formType].account?.id || null;
        const companyId = companyData.data?.id || form[formType]?.id || null;
        await API.patchCompany(accountId, companyId, companyInfo);
        return API.setCompanyPrincipalOffice(accountId, companyId, principalOffice);
      })
      .then((companyData) => {
        // console.log("Updated Company:", companyData.data);
        goToNextPage(companyData.data);
      })
      .catch((err) => {
        // console.log(err)
        formErrorHandler(err, setApiErrorState);
      });
  };

  const setApiErrorState = (err) => {
    setState((state) => ({
      ...state,
      apiErrors: err,
    }));
  };

  const validationSchema = Yup.object().shape({
    companySuffix1: Yup.string(),
    companySuffix2: Yup.string(),
    companyName1: Yup.string()
      .trim()
      .min(2, "Prefered Name is too Short!")
      .max(255, "Prefered Name is too Long!")
      .required("Prefered Name is required"),
    // companyName2: Yup.string().min(2, 'Alternate Name is too Short!').max(255, 'Alternate Name is too Long!').required('Alternate Name is required'),
    companyName2: Yup.string()
      .trim()
      .min(2, "Alternate Name is too Short!")
      .max(255, "Alternate Name is too Long!")
      .required("Alternate Name is required")
      .test("match", "Alternate Name should not match the Prefered Name", function (companyName2) {
        return companyName2 + this.parent.companySuffix2 !== this.parent.companyName1 + this.parent.companySuffix1;
      }),
    businessActivity: Yup.string()
      .min(2, "Principal Activity is too Short!")
      .max(255, "Principal Activity is too Long!")
      .required("Principal Activity is required"),
    businessActivityOther: Yup.string().when("businessActivity", {
      is: (businessActivity) => (businessActivity === "Other" ? true : false),
      then: Yup.string()
        .min(2, "Business Activity needs to be specific")
        .max(255, "Business Activity needs to be specific")
        .required("Business Activity needs to be specific"),
    }),
    businessPurposeSummary: Yup.string()
      .min(2, "Company purpose is too Short!")
      .max(255, "Company purpose is too Long!")
      .required("Company purpose is required"),
    street1: Yup.string()
      .min(2, "Address is too Short!")
      .max(255, "Address is too Long!")
      .required("Address is required"),
    street2: Yup.string().min(2, "Suite/Apt # is too Short!").max(255, "Suite/Apt # is too Long!"),
    city: Yup.string().min(2, "City is too Short!").max(255, "City is too Long!").required("City is required"),
    state: Yup.string().min(2, "State is too Short!").max(255, "State is too Long!").required("State is required"),
    county: Yup.string()
      .min(1, "County is too Short!")
      .max(255, "Position is too Long!")
      .required("County is required"),
    postalCode: Yup.string()
      .matches(/^\d{5}(?:[-\s]\d{4})?$/, {
        message: "Zip Code must match this format: XXXXX or XXXXX-XXXX",
        excludeEmptyString: true,
      })
      .required("Zip Code is required"),
    phone: Yup.string()
      .matches(/^[1-9]\d{2}-\d{3}-\d{4}$/, {
        message: "Phone must match this format: XXX-XXX-XXXX",
        excludeEmptyString: true,
      })
      .required("Phone number is required"),
    sameMailingAddress: Yup.string(),
    mailingStreet1: Yup.string().when("sameMailingAddress", {
      is: (sameMailingAddress) => sameMailingAddress === "other",
      then: Yup.string()
        .min(2, "Mailing address is too Short!")
        .max(255, "Mailing address is too Long!")
        .required("Mailing address is required"),
    }),
    mailingStreet2: Yup.string().when("sameMailingAddress", {
      is: (sameMailingAddress) => sameMailingAddress === "other",
      then: Yup.string().min(2, "Mailing suite/Apt # is too Short!").max(255, "Mailing suite/Apt # is too Long!"),
    }),
    mailingCity: Yup.string().when("sameMailingAddress", {
      is: (sameMailingAddress) => sameMailingAddress === "other",
      then: Yup.string()
        .min(2, "Mailing city is too Short!")
        .max(255, "Mailing city is too Long!")
        .required("Mailing city is required"),
    }),
    mailingState: Yup.string().when("sameMailingAddress", {
      is: (sameMailingAddress) => sameMailingAddress === "other",
      then: Yup.string().required("Mailing state is required"),
    }),
    mailingPostalCode: Yup.string().when("sameMailingAddress", {
      is: (sameMailingAddress) => sameMailingAddress === "other",
      then: Yup.string()
        .matches(/^\d{5}(?:[-\s]\d{4})?$/, {
          message: "Mailing zip code must match this format: XXXXX or XXXXX-XXXX",
          excludeEmptyString: true,
        })
        .required("Mailing zip code is required"),
    }),
  });

  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel='canonical' href='https://www.floridaincorporationservice.com' />
      </Helmet>

      <ProgressStatus registrationStep={registrationStep} />

      <Container>
        <Row className='justify-content-md-center'>
          {form[formType].isComplete || form[formType].orderInfoId ? <ApplicationCompleteAlert /> : null}
          <Col lg='8' md='10' sm='12'>
            <Formik
              enableReinitialize={true}
              initialValues={state.formState}
              initialTouched={{ companySuffix1: true, companySuffix2: true }}
              validationSchema={validationSchema}
              // onChange={values => this.handleChange(values)}
              onSubmit={(values, actions) => handleFormSubmit(values, actions)}
            >
              {({ isValid, errors, touched, values, handleChange, submitCount, isSubmitting }) => (
                <Form>
                  <h2>Filing Information</h2>
                  <p>
                    Please enter the business name you wish to register. Make sure the business name you are filing is
                    spelled correctly.
                  </p>
                  <FormGroup>
                    <Label for='companyName1'>Preferred Name</Label>
                    <Row className='form-row'>
                      <Col sm={8}>
                        <Input
                          tag={Field}
                          invalid={errors.companyName1 && touched.companyName1}
                          onChange={handleChange}
                          value={values.companyName1}
                          type='text'
                          name='companyName1'
                          id='companyName1'
                          data-hj-masked
                        />
                      </Col>
                      <Col sm={4} md={4} lg={4} className='suffixes'>
                        <SuffixDropdown
                          onChange={handleChange}
                          value={values.companySuffix1}
                          accountType={accountType}
                          type='select'
                          name='companySuffix1'
                          id='companySuffix1'
                          data-hj-masked
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Label for='companyName2'>Alternate Name</Label>
                    <Row className='form-row'>
                      <Col sm={8}>
                        <Input
                          tag={Field}
                          invalid={errors.companyName2 && touched.companyName2}
                          onChange={handleChange}
                          value={values.companyName2}
                          type='text'
                          name='companyName2'
                          id='companyName2'
                          data-hj-masked
                        />
                      </Col>
                      <Col sm={4} md={4} lg={4} className='suffixes'>
                        <SuffixDropdown
                          onChange={handleChange}
                          value={values.companySuffix2}
                          accountType={accountType}
                          type='select'
                          name='companySuffix2'
                          id='companySuffix2'
                          data-hj-masked
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <h2>Business Activity</h2>
                  <p>
                    You must select one box that describes the principal activity of your business. For example, if you
                    will operate kiosks selling keychains in a number of locations, select "retail."
                  </p>
                  <FormGroup className='form-row'>
                    <Label sm={3} className='text-sm-right' for='companyName'>
                      Principal Activity
                    </Label>
                    <Col sm={8}>
                      <BusinessActivityDropdown
                        invalid={errors.businessActivity && touched.businessActivity}
                        onChange={handleChange}
                        value={values.businessActivity}
                        type='select'
                        name='businessActivity'
                        id='businessActivity'
                        data-hj-masked
                      />
                    </Col>
                  </FormGroup>
                  {values.businessActivity === "Other" ? (
                    <FormGroup className='form-row'>
                      <Label sm={3} className='text-sm-right' for='businessActivityOther'>
                        <strong>Other?</strong> Be Specific
                      </Label>
                      <Col sm={8}>
                        <Input
                          tag={Field}
                          invalid={errors.businessActivityOther && touched.businessActivityOther}
                          onChange={handleChange}
                          value={values.businessActivityOther}
                          type='text'
                          name='businessActivityOther'
                          id='businessActivityOther'
                          maxLength='25'
                          data-hj-masked
                        />
                        <FormText color='muted'>25 characters or less.</FormText>
                      </Col>
                    </FormGroup>
                  ) : null}
                  <FormGroup className='form-row'>
                    <Label sm={3} className='text-sm-right' for='businessPurposeSummary'>
                      <strong>Briefly</strong> summarize the company's purpose
                    </Label>
                    <Col sm={8}>
                      <Input
                        tag={Field}
                        invalid={errors.businessPurposeSummary && touched.businessPurposeSummary}
                        onChange={handleChange}
                        value={values.businessPurposeSummary}
                        type='textarea'
                        component='textarea'
                        name='businessPurposeSummary'
                        id='businessPurposeSummary'
                        maxLength='50'
                        data-hj-masked
                      />
                      <FormText color='muted'>50 characters or less.</FormText>
                    </Col>
                  </FormGroup>

                  <h2>Principal Place of Business</h2>
                  <p>
                    The Division of Corporations requires that this be an occupied physical Florida address. If you have
                    not decided yet where the principal office will be, you may specify another Florida address, such as
                    a home address. This information can easily be changed later.
                  </p>
                  <FormGroup className='form-row'>
                    <Label sm={3} className='text-sm-right' for='street1'>
                      Address
                    </Label>
                    <Col sm={8}>
                      <Input
                        tag={Field}
                        invalid={errors.street1 && touched.street1}
                        onChange={handleChange}
                        value={values.street1}
                        type='text'
                        name='street1'
                        id='street1'
                        data-hj-masked
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className='form-row'>
                    <Label sm={3} className='text-sm-right' for='street2'>
                      Suite. Apt. #, etc.
                    </Label>
                    <Col sm={8}>
                      <Input
                        tag={Field}
                        invalid={errors.street2 && touched.street2}
                        onChange={handleChange}
                        value={values.street2}
                        type='text'
                        name='street2'
                        id='street2'
                        data-hj-masked
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className='form-row'>
                    <Label sm={3} className='text-sm-right' for='city'>
                      City
                    </Label>
                    <Col sm={8}>
                      <Input
                        tag={Field}
                        invalid={errors.city && touched.city}
                        onChange={handleChange}
                        value={values.city}
                        type='text'
                        name='city'
                        id='city'
                        data-hj-masked
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className='form-row'>
                    <Label sm={3} className='text-sm-right' for='county'>
                      County
                    </Label>
                    <Col xs={9} sm={5} lg={5}>
                      <CountyDropdown
                        invalid={errors.county && touched.county}
                        onChange={handleChange}
                        value={values.county}
                        name='county'
                        id='county'
                        data-hj-masked
                      />
                    </Col>
                    <Col xs={3} sm={4} lg={3}>
                      <div className='mt-2'>
                        <strong>, {values.state}</strong>
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup className='form-row'>
                    <Label sm={3} className='text-sm-right' for='postalCode'>
                      Zip Code
                    </Label>
                    <Col sm={8}>
                      <Input
                        tag={Field}
                        invalid={errors.postalCode && touched.postalCode}
                        onChange={handleChange}
                        value={values.postalCode}
                        type='text'
                        name='postalCode'
                        id='postalCode'
                        data-hj-masked
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className='form-row'>
                    <Label sm={3} className='text-sm-right' for='phone'>
                      Phone
                    </Label>
                    <Col sm={8}>
                      {/* <Input tag={Field} invalid={errors.phone && touched.phone} onChange={handleChange} value={values.phone} type="tel" name="phone" id="phone" data-hj-masked /> */}
                      <InputMask
                        className={errors.phone && touched.phone ? "form-control is-invalid" : "form-control"}
                        onChange={handleChange}
                        value={values.phone}
                        type='tel'
                        name='phone'
                        id='phone'
                        data-hj-masked
                        mask='999-999-9999'
                        maskChar={"_"}
                        placeholder='___-___-____'
                      />
                    </Col>
                  </FormGroup>
                  <h2>Business Mailing Address</h2>
                  <p>
                    This is the address the Division of Corporations will use for correspondence with you. You may use a
                    PO Box for this address, and it does not have to be a Florida address.
                  </p>
                  <FormGroup className='form-row'>
                    <Col sm={9} className='ml-md-auto'>
                      {/* <CustomInput invalid={errors.sameMailingAddress && touched.sameMailingAddress} onChange={handleChange} value={values.sameMailingAddress} checked={values.sameMailingAddress === true} type="checkbox" name="sameMailingAddress" id="sameMailingAddress" label="Same as Principal Address (specified above)" /> */}
                      <CustomInput
                        onChange={handleChange}
                        checked={values.sameMailingAddress === "principal"}
                        className='mb-2'
                        type='radio'
                        name='sameMailingAddress'
                        value='principal'
                        id='sameMailingAddress'
                        label='Same as Principal Place of Business Address (specified above)'
                        inline
                        data-hj-masked
                      />
                    </Col>
                    {/* <Col sm={9} className="ml-md-auto">
                      <CustomInput onChange={handleChange} checked={values.sameMailingAddress === "contact"} className="mb-2" type="radio" name="sameMailingAddress" value="contact" id="sameContactAddress" label="Same as Contact Person Mailing Address (from previous page)" inline data-hj-masked />
                    </Col> */}
                    <Col sm={9} className='ml-md-auto'>
                      <CustomInput
                        onChange={handleChange}
                        checked={values.sameMailingAddress === "other"}
                        className='mb-2'
                        type='radio'
                        name='sameMailingAddress'
                        value='other'
                        id='otherAddress'
                        label='Other (please specify below)'
                        inline
                        data-hj-masked
                      />
                    </Col>
                  </FormGroup>
                  {values.sameMailingAddress === "principal" ? (
                    <FormGroup className='form-row'>
                      {values.street1 && values.city && values.state && values.postalCode ? (
                        <Col sm={{ size: 8, offset: 3 }}>
                          <strong>
                            {values.street1} {values.street2}
                            <br />
                            {values.city}, {values.state} {values.postalCode}
                          </strong>
                        </Col>
                      ) : null}
                    </FormGroup>
                  ) : values.sameMailingAddress === "contact" ? (
                    <FormGroup className='form-row'>
                      <Col sm={{ size: 8, offset: 3 }}>
                        <strong>
                          {values.pointOfContact.addresses[0]?.street1} {values.pointOfContact.addresses[0]?.street2}
                          <br />
                          {values.pointOfContact.addresses[0]?.city},{" "}
                          {values.pointOfContact.addresses[0]?.stateProvince}{" "}
                          {values.pointOfContact.addresses[0]?.postalCode}
                        </strong>
                      </Col>
                    </FormGroup>
                  ) : (
                    <Fragment>
                      <FormGroup className='form-row'>
                        <Label sm={3} className='text-sm-right' for='mailingStreet1'>
                          Address
                        </Label>
                        <Col sm={8}>
                          <Input
                            tag={Field}
                            invalid={errors.mailingStreet1 && touched.mailingStreet1}
                            onChange={handleChange}
                            value={values.mailingStreet1}
                            type='text'
                            name='mailingStreet1'
                            id='mailingStreet1'
                            data-hj-masked
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className='form-row'>
                        <Label sm={3} className='text-sm-right' for='mailingStreet2'>
                          Suite. Apt. #, etc.
                        </Label>
                        <Col sm={8}>
                          <Input
                            tag={Field}
                            invalid={errors.mailingStreet2 && touched.mailingStreet2}
                            onChange={handleChange}
                            value={values.mailingStreet2}
                            type='text'
                            name='mailingStreet2'
                            id='mailingStreet2'
                            data-hj-masked
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className='form-row'>
                        <Label sm={3} className='text-sm-right' for='mailingCity'>
                          City
                        </Label>
                        <Col sm={8}>
                          <Input
                            tag={Field}
                            invalid={errors.mailingCity && touched.mailingCity}
                            onChange={handleChange}
                            value={values.mailingCity}
                            type='text'
                            name='mailingCity'
                            id='mailingCity'
                            data-hj-masked
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className='form-row'>
                        <Label sm={3} className='text-sm-right' for='mailingState'>
                          State
                        </Label>
                        <Col sm={8}>
                          <StateDropdown
                            invalid={errors.mailingState && touched.mailingState}
                            onChange={handleChange}
                            value={values.mailingState}
                            name='mailingState'
                            id='mailingState'
                            data-hj-masked
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className='form-row'>
                        <Label sm={3} className='text-sm-right' for='mailingPostalCode'>
                          Zip Code
                        </Label>
                        <Col sm={8}>
                          <Input
                            tag={Field}
                            invalid={errors.mailingPostalCode && touched.mailingPostalCode}
                            onChange={handleChange}
                            value={values.mailingPostalCode}
                            type='text'
                            name='mailingPostalCode'
                            id='mailingPostalCode'
                            data-hj-masked
                          />
                        </Col>
                      </FormGroup>
                    </Fragment>
                  )}

                  <FormValidationAlert
                    submitCount={submitCount}
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                    errors={errors}
                  />

                  <ProcessingStatus showProcessing={state.isProcessing && !state.apiErrors} />

                  <FormErrorModal errors={state.apiErrors} />

                  {!form[formType].isComplete || !form[formType].orderInfoId ? (
                    <FormNav action={{ prev: "" }} hidePrevButton={true} hideSaveButton={true} />
                  ) : null}
                </Form>
              )}
            </Formik>
          </Col>

          <Sidebar orderDetailPids={state.orderDetailPids} host={host} lgr={state.formState.lgr} />
        </Row>
      </Container>
    </Fragment>
  );
}

export default BusinessInformation;
