import React, { useState } from 'react';
import {
  Col,
  FormGroup,
  CustomInput,
  Modal, 
  ModalHeader, 
  ModalBody
} from 'reactstrap';
import { displayPriceDollar } from "../../../utils/displayPrice";

const CompanyEmbosser = (props) => {
  const pid = 582;
  const [modal, setModal] = useState(false);
  const toggle = (e) => {
    e.preventDefault();
    setModal(!modal);
  }
  const price = displayPriceDollar(pid);

  return (
    <FormGroup className="form-row action-area">
      <Col sm={12} className="ml-md-auto">
        <CustomInput onChange={props.onChange} checked={props.checked} type="checkbox" value={pid} name={props.name} id={props.id} label={<span>Company Embosser - ${price} <a href="//:0" onClick={toggle} className="upsell_desc_trigger">?</a></span>} inline data-hj-masked />
        <Modal centered isOpen={modal} toggle={toggle} size="lg">
          <ModalHeader toggle={toggle}>Company Embosser</ModalHeader>
          <ModalBody>
            <p className="mb-0">An embosser is a necessity if you would like to have a raised seal (such as for use on important legal documents, real estate documents, banking documents, etc.).</p>
          </ModalBody>
        </Modal>
      </Col>
    </FormGroup>
  );
}

export default CompanyEmbosser;