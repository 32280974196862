import React from "react";
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem
} from 'reactstrap';

const EntitiyClassification = (props) => {
  const entityClassificationElection = props.data.entityClassificationElection;
  const fileForm8832 = entityClassificationElection?.fileForm8832;
  const entityClassification = entityClassificationElection?.entityClassification;
  
  const editLink = `/entity-classification/${props.accountType}/${props.queryString}`;

  const handleEdit = event => {
    event.preventDefault();
    props.history.push(editLink);
    window.scrollTo(0, 0) // making sure that user view is top of the page
  }

  const showEdit = !(props.data.orderInfoId || props.data.isComplete);

  if (entityClassificationElection) {
    return (
      <Card className="card-summary">
        <CardHeader tag="h5" className="mt-0">Entity Classification Election (Form 8832) {showEdit && <a href="//:0" onClick={handleEdit} className="edit ml-auto">Edit</a>}</CardHeader>
        <ListGroup flush>
          <ListGroupItem>
            <span className="info-title">File Form 8832:</span>
            <span className="info-data">{fileForm8832 ? "Yes" : "No"}</span>
          </ListGroupItem>
          <ListGroupItem>
            <span className="info-title">Entity Classification:</span>
            <span className="info-data">{entityClassification}</span>
          </ListGroupItem>
        </ListGroup>
      </Card>
    );
  }
  else {
    return null;
  }

}

export default withRouter(EntitiyClassification);

